import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MultiSelect from 'react-select';

import { Select } from '../../../components/Select';
import Thumbnail from '../../../components/Thumbnail';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import EditAvatar from '../../../components/EditAvatar';

import listOfState from '../../../utils/listOfState';
import { maskToTelephone, maskToCpf } from '../../../utils/maskToInputs';

import { TeachersCreateLoading, TeachersRetrieveAllSelectors } from '../../../Redux/Teacher/selectors';
import { AuthRetrieveUserSelector, AuthTokenSelector } from '../../../Redux/Auth/selector';

import { createTeacherRequest } from '../../../Redux/Teacher/Create/actions';
import { updateTeacherRequest } from '../../../Redux/Teacher/Update/actions';
import { deleteTeacherRequest, deleteTeacherFailure } from '../../../Redux/Teacher/Delete/actions';

import { SubjectsRetrieveByUnitSelectors, SubjectsRetriveIsLoadingSelectors } from '../../../Redux/Subjects/selectors';

import {
  retrieveSubjectsByUnitRequest,
  retrieveSubjectsByUnitReset,
} from '../../../Redux/Subjects/RetrieveByUnit/actions';

import { ModalDispatch } from '../../../contexts/ModalContext';
import { BUTTON_CLASSES } from '../../../utils/enums';

import {
  UnitssRetrieveByCountryStateSelectors,
  UnitssRetrieveByCountryStateIsLoadingSelectors,
} from '../../../Redux/Units/selectors';
import {
  retrieveUnitsByCountryStatesRequest,
  retrieveUnitsByCountryStatesReset,
} from '../../../Redux/Units/RetrieveByCountryState/actions';

import { cpfIsValid } from '../../../utils/cpfValidate';

import undefinedUserBase64 from '../../../assets/images/undefinedUserBase64';

import { getProfilePicture } from '../../../client/users';
import { getUnitsById } from '../../../client/unitss';

function TeachersInfo({ idTeacher, useForEdit, closeModal }) {
  const user = useSelector(AuthRetrieveUserSelector);
  const dispatch = useDispatch();
  const modalDispatch = ModalDispatch();

  const [avatar, setAvatar] = useState(undefinedUserBase64);
  const [newAvatar, setNewAvatar] = useState();
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [workUnit, setWorkUnit] = useState();
  const [countryState, setCountryState] = useState('');

  const [cpf, setCpf] = useState('');
  const [telephone, setTelephone] = useState('');
  const [subject, setSubject] = useState([]);
  const [enabled, setEnabled] = useState(true);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [countryStateError, setCountryStateError] = useState(false);
  const [workUnitError, setWorkUnitError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [subjectsError, setSubjectsError] = useState(false);

  const textButton = useForEdit ? 'Atualizar' : 'Cadastrar';

  const subjectsRetrieveSelector = useSelector(SubjectsRetrieveByUnitSelectors);
  const unitssRetrieveIsLoading = useSelector(UnitssRetrieveByCountryStateIsLoadingSelectors);
  const subjectsRetrieveIsLoading = useSelector(SubjectsRetriveIsLoadingSelectors);

  const subjectsAll = subjectsRetrieveSelector.map(val => {
    const { id: value, name: label } = val;
    return { value, label };
  });

  useEffect(() => {
    setNameError(false);
  }, [name]);

  useEffect(() => {
    setEmailError(false);
  }, [email]);

  useEffect(() => {
    setCountryStateError(false);
  }, [countryState]);

  useEffect(() => {
    setWorkUnitError(false);
  }, [workUnit]);

  useEffect(() => {
    setCpfError(false);
  }, [cpf]);

  useEffect(() => {
    setPhoneError(false);
  }, [telephone]);

  useEffect(() => {
    setSubjectsError(false);
  }, [subject]);

  function getParamenters() {
    let Cpf = '';
    let Telephone = '';
    let DDD = '';
    if (cpf) {
      Cpf = cpf.replace(/\./g, '').replace(/-/g, '');
    }

    if (telephone) {
      if (telephone.length === 14 || telephone.length === 15) {
        const telBase = telephone
          .replace(/-/g, '')
          .replace(/\(/g, '')
          .replace(/\)/g, '')
          .replace(' ', '');
        DDD = telBase.substring(0, 2);
        Telephone = telBase.substring(2, 11);
      } else if (telephone.length === 11 || telephone.length === 10) {
        DDD = telephone.substring(0, 2);
        Telephone = telephone.substring(2, 11);
      }
    }

    const subjects = subject.map(val => val.value);

    return {
      data: {
        avatar: avatar && avatar !== undefinedUserBase64 ? avatar.replace(/^data:image\/png;base64,/, '') : null,
        name,
        email,
        siteId: parseInt(workUnit, 10),
        state: countryState,
        cpf: Cpf,
        ddd: DDD,
        telephone: Telephone,
        subjects,
        enabled,
      },
      authToken,
    };
  }

  function resetErrors() {
    setNameError(false);
    setEmailError(false);
    setCountryStateError(false);
    setWorkUnitError(false);
    setSubjectsError(false);
    setPhoneError(false);
    setCpfError(false);
  }

  function createTeacher(e) {
    e.preventDefault();
    resetErrors();
    if (areFieldsValid()) {
      resetErrors();
      dispatch(createTeacherRequest(getParamenters()));
      closeModal();
    }
  }

  function isNameValid() {
    if (!name || name === '') {
      setNameError('Preencha o nome.');
      return false;
    }
    return true;
  }

  function isEmailValid() {
    const emailRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
    if (!email || email === '' || !emailRegex.test(email)) {
      setEmailError('Insira um e-mail válido.');
      return false;
    }
    return true;
  }

  function isCountryStateValid() {
    if (!countryState || countryState === '') {
      setCountryStateError('Informe o estado.');
      return false;
    }
    return true;
  }

  function isWorkUnitValid() {
    if (!workUnit || workUnit === '') {
      setWorkUnitError('Informe a unidade.');
      return false;
    }
    return true;
  }

  function isPhoneValid() {
    if (
      telephone &&
      ((telephone.length > 0 && telephone.length < 14) ||
        (telephone.includes('(') && telephone.length > 0 && telephone.length < 11) ||
        (useForEdit && telephone.length > 0 && telephone.length < 10))
    ) {
      setPhoneError('Insira um telefone válido.');
      return false;
    }

    return true;
  }

  function isSubjectValid() {
    if (!subject || subject.length === 0) {
      setSubjectsError('Selecione ao menos uma oficina.');
      return false;
    }

    return true;
  }

  function isCpfValid() {
    if (
      cpf &&
      (!cpfIsValid(cpf) ||
        (!cpf.includes('.') && cpf.length > 0 && cpf.length < 11) ||
        (cpf.includes('.') && cpf.length > 0 && cpf.length < 14))
    ) {
      setCpfError('Insira um CPF válido.');
      return false;
    }
    return true;
  }

  function areFieldsValid() {
    if (
      isNameValid() &&
      isPhoneValid() &&
      isCpfValid() &&
      isEmailValid() &&
      isCountryStateValid() &&
      isWorkUnitValid() &&
      isSubjectValid()
    ) {
      return true;
    }

    return false;
  }

  function updateTeacher(e) {
    e.preventDefault();
    resetErrors();
    if (areFieldsValid()) {
      const paramenters = getParamenters();
      paramenters.id = idTeacher;
      dispatch(updateTeacherRequest(paramenters));
      closeModal();
    }
  }

  async function startComponent() {
    if (useForEdit) {
      const result = teachers.rows.find(val => val.userId === idTeacher);

      setCountryState(result.state);
      setName(result.name);
      setEmail(result.username);
      setWorkUnit(result.siteId);
      setCpf(result.cpf);

      const finalSubjects = (result.subjects || []).map(val => {
        const { id: value, name: label } = val;
        return { value, label };
      });
      setSubject(finalSubjects);
      setTelephone(maskToTelephone(result.ddd + result.telephone));

      setEnabled(Boolean(result.enabled));

      const profilePicture = await getProfilePicture(result.profilePicture, authToken);
      setAvatar('data:image/png;base64,' + profilePicture.data);
    }
  }

  const getUnitInfo = async () => {
    const response = await getUnitsById(authToken, user.sitesIds[0]);
    const unit = await response.data;
    setCountryState(unit.state);
  };

  useEffect(() => {
    if (user?.role === 'mentor') {
      setWorkUnit(user.sitesIds[0]);
      getUnitInfo();
    }
  }, [user]);

  function getUnitsByCountryState() {
    dispatch(retrieveUnitsByCountryStatesReset());
    dispatch(retrieveUnitsByCountryStatesRequest(authToken, countryState));
  }
  const limit = 1000;
  async function getSubjectsByUnit() {
    dispatch(retrieveSubjectsByUnitRequest(parseInt(workUnit, 10), authToken, limit));
  }

  const finalDispatch = useForEdit ? updateTeacher : createTeacher;

  useEffect(() => {
    if (!useForEdit) {
      dispatch(retrieveUnitsByCountryStatesReset());
      dispatch(retrieveSubjectsByUnitReset());
    }
    startComponent();
  }, []);

  useEffect(() => {
    if (countryState) {
      dispatch(retrieveSubjectsByUnitReset());
      getUnitsByCountryState();
    }
  }, [countryState]);

  const handleSetValueOfSelectCountryState = selected => {
    setCountryState(selected);
    setSubject([]);
    setWorkUnit();
  };

  useEffect(() => {
    if (workUnit) {
      getSubjectsByUnit();
      dispatch(retrieveSubjectsByUnitReset());
    }
  }, [workUnit]);

  const handleSetValueOfSubjectsByUnitReset = selected => {
    setWorkUnit(selected);
    setSubject([]);
  };

  const teachers = useSelector(TeachersRetrieveAllSelectors);
  const teachersCreateIsLoading = useSelector(TeachersCreateLoading);
  const authToken = useSelector(AuthTokenSelector);
  const units = useSelector(UnitssRetrieveByCountryStateSelectors);

  const onSaveAvatarImage = () => {
    setAvatar(newAvatar);
    setNewAvatar();
    setIsAvatarModalOpen(false);
  };

  const onRemoveAvatarImage = () => {
    setNewAvatar(null);
  };

  const onAvatarImageChange = image => {
    setNewAvatar(image);
  };

  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  const onCancelAvatarEdit = () => {
    setIsAvatarModalOpen(false);
    setNewAvatar(avatar !== undefinedUserBase64 ? avatar : null);
  };

  return (
    <>
      {useForEdit && <h2 style={{ color: 'black', padding: '0 0 20px 4px', fontWeight: 'bold' }}>{name}</h2>}

      <div>
        {!isAvatarModalOpen && (
          <Thumbnail alt="foto do professor" height="104px" image={avatar} clickFn={openAvatarModal} />
        )}

        {isAvatarModalOpen && (
          <EditAvatar
            avatar={newAvatar}
            onChange={onAvatarImageChange}
            onSaveAvatar={onSaveAvatarImage}
            onRemoveAvatar={onRemoveAvatarImage}
            onCancel={onCancelAvatarEdit}
          />
        )}
      </div>

      <div className="mt-4 row">
        <form onSubmit={finalDispatch} className="col-12">
          <div className="row">
            <div className="col-3 mr-4">
              <label htmlFor="name">
                Nome <span style={{ color: 'red' }}>*</span>
              </label>
              <Input id="name" inputValue={name} setInputValue={setName} maxLength={40} />
              {nameError && (
                <div>
                  <p style={{ fontSize: 13, marginTop: 4, color: 'red' }}> {nameError} </p>
                </div>
              )}
            </div>

            <div className="col-3 mr-4">
              <label htmlFor="telephone">DDD + Telefone</label>
              <Input
                id="telephone"
                inputValue={maskToTelephone(telephone)}
                typeInput=""
                setInputValue={value => setTelephone(maskToTelephone(value))}
                placeholder="( ) _ _ _ _ _ _ _ _ _"
                maxLength={15}
              />
              {phoneError && (
                <div>
                  <p style={{ fontSize: 13, marginTop: 4, color: 'red' }}> {phoneError} </p>
                </div>
              )}
            </div>

            <div className="col-3 mr-4">
              <label htmlFor="cpf">CPF</label>
              <Input
                id="cpf"
                placeholder="_ _ _ . _ _ _ . _ _ _ - _ _ "
                inputValue={maskToCpf(cpf)}
                typeInput="text"
                setInputValue={setCpf}
                maxLength={14}
              />
              {cpfError && (
                <div>
                  <p style={{ fontSize: 13, marginTop: 4, color: 'red' }}> {cpfError} </p>
                </div>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 mr-4">
              <label htmlFor="email">
                Email <span style={{ color: 'red' }}>*</span>
              </label>
              <Input id="email" readOnly={useForEdit} inputValue={email} typeInput="text" setInputValue={setEmail} />
              {emailError && (
                <div>
                  <p style={{ fontSize: 13, marginTop: 4, color: 'red' }}> {emailError} </p>
                </div>
              )}
            </div>
            <div className="col-3 mr-4">
              <label htmlFor="state">
                Estado <span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <Select
                valueOfSelect={countryState}
                setValueOfSelect={handleSetValueOfSelectCountryState}
                deactivate={user.role === 'mentor'}
              >
                <option key="" value="" disabled hidden>
                  Escolha um Estado
                </option>
                {listOfState.map(val => (
                  <option key={val.initials} value={val.initials}>
                    {val.name}
                  </option>
                ))}
              </Select>
              {countryStateError && (
                <div>
                  <p style={{ fontSize: 13, marginTop: 4, color: 'red' }}> {countryStateError} </p>
                </div>
              )}
            </div>

            <div className="col-3">
              <label htmlFor="workUnit">
                Unidade em que trabalha <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                valueOfSelect={user.role === 'mentor' ? user.sitesIds[0] : workUnit}
                setValueOfSelect={handleSetValueOfSubjectsByUnitReset}
                deactivate={(units.rows.length <= 0 && user.role !== 'mentor') || user.role === 'mentor'}
              >
                <option key="" value="" disabled hidden>
                  {units.rows.length > 0
                    ? 'Escolha uma Unidade'
                    : unitssRetrieveIsLoading
                    ? 'Carregando...'
                    : 'Nenhuma unidade encontrada'}
                </option>
                {units.rows.length > 0 && user.role === 'mentor'
                  ? units.rows
                      .map(item => {
                        const { id, name: unitName } = item;
                        if (item.id !== user.sitesIds[0]) return null;
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <option key={id} value={id}>
                            {unitName}
                          </option>
                        );
                      })
                      .filter(item => item !== null)
                  : units.rows.map(item => {
                      const { id, name: unitName } = item;
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={id} value={id}>
                          {unitName}
                        </option>
                      );
                    })}
              </Select>
              {workUnitError && (
                <div>
                  <p style={{ fontSize: 13, marginTop: 4, color: 'red' }}> {workUnitError} </p>
                </div>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3">
              <label htmlFor="classes">
                Oficina que ensina <span style={{ color: 'red' }}>*</span>
              </label>
              <MultiSelect
                placeholder={subjectsRetrieveIsLoading ? 'Carregando...' : workUnit !== undefined ? 'Selecione...' : ''}
                isDisabled={subjectsRetrieveIsLoading || workUnit === undefined}
                value={subject}
                onChange={setSubject}
                isMulti
                options={subjectsAll}
                noOptionsMessage={() => 'Nenhuma oficina encontrada'}
              />
              {subjectsError && (
                <div>
                  <p style={{ fontSize: 13, marginTop: 4, color: 'red' }}> {subjectsError} </p>
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2 mb-1">
            <Button classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`} type="button" handleClick={closeModal}>
              Cancelar
            </Button>
   
            <Button
              type="submit"
              disabled={teachersCreateIsLoading}
              classes={teachersCreateIsLoading ? `${BUTTON_CLASSES.AWAIT} col-2` : `${BUTTON_CLASSES.NO_AWAIT} col-2`}
            >
              {textButton}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

TeachersInfo.propTypes = {
  idTeacher: PropTypes.number,
  useForEdit: PropTypes.bool,
  closeModal: PropTypes.func,
};

TeachersInfo.defaultProps = {
  idTeacher: 0,
  useForEdit: false,
  closeModal: () => {},
};

export default TeachersInfo;
