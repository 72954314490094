import React, { useState, useEffect, useRef } from 'react';
import PropTypes, { arrayOf, shape, oneOfType } from 'prop-types';
import Select from 'react-select';
import icLoading from '../../assets/images/ic_loading.svg';

import { colourStyles, container, loadingStyle } from './styles';

export default function SelectNew({
  options,
  value,
  setSelectedValue,
  isDisabled = false,
  isLoading = false,
  getOptions,
  isAsync,
}) {
  const [search, setSearch] = useState('');
  const previousSearchText = usePrevious(search);

  const isOptionInSearch = (optionLabel, searchText) => {
    const normalizedOptionLabel = optionLabel
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const normalizedSearchText = searchText
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return normalizedOptionLabel.includes(normalizedSearchText);
  };

  const customFilter = (option, searchText) => isOptionInSearch(option.label, searchText);

  let timeout = null;
  const customAsyncFilter = async (option, searchText) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      setSearch(searchText);
      if ((!searchText || searchText.length === 0) && searchText !== previousSearchText) {
        await getOptions();
        return true;
      }

      if (searchText && searchText !== previousSearchText) {
        await getOptions(searchText);
      }

      return true;
    }, 700);

    return true;
  };

  return (
    <div style={container}>
      <Select
        options={options}
        value={value}
        onChange={selected => setSelectedValue(selected)}
        styles={colourStyles}
        onMenuScrollToBottom={() => (isAsync ? getOptions(search) : null)}
        filterOption={isAsync ? customAsyncFilter : customFilter}
        isDisabled={isDisabled || isLoading}
        noOptionsMessage={() => (isLoading ? 'Carregando...' : 'Não encontrado')}
      />
      {isLoading && (
        <img src={icLoading} alt="icone circular, movimentando-se em circulos." height="20px" style={loadingStyle} />
      )}
    </div>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

SelectNew.propTypes = {
  options: arrayOf(
    shape({
      value: oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
      label: PropTypes.string.isRequired,
    })
  ),
  value: shape({
    value: oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
  }),
  setSelectedValue: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  getOptions: PropTypes.func,
  isAsync: PropTypes.bool,
};

SelectNew.defaultProps = {
  options: [],
  value: {},
  isDisabled: false,
  isLoading: false,
  getOptions: () => {},
  isAsync: false,
};
