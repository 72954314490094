import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/students';

function getStudents(offset = 0, authToken, search, limit, signal) {
  const finalRoute = search ? `${route}?${search}` : route;
  const params = {
    limit,
    offset,
  };
  return Api.get(finalRoute, {
    params,
    headers: commonHeaders(authToken),
    signal,
  });
}

function deleteStudent(id, authToken) {
  return Api.delete(`${route}/${id}`, {
    headers: commonHeaders(authToken),
  });
}

function updateStudent(id, data, authToken) {
  return Api.patch(`${route}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function resetStudentPasswordByMentor({ id, password, mentorPassword, authToken }) {
  return Api.post(`mentors/student/${id}/change-password`,
    { password, mentorPassword },
    { headers: commonHeaders(authToken) });
}

export { getStudents, deleteStudent, updateStudent, resetStudentPasswordByMentor };
