import { createSelector } from 'reselect';

const DashboardStateSelector = appState => appState.crudDashboardReducers;

const DashboardRetrieveAllStateSelector = createSelector(
  DashboardStateSelector,
  crudDashboardReducers => crudDashboardReducers.retrieveAllDashboardReducer
);

export const DashboardRetrieveAllIsLoadingSelector = createSelector(
  DashboardRetrieveAllStateSelector,
  retrieveAllDashboardReducer => retrieveAllDashboardReducer.isLoading
);

export const DashboardRetrieveAllDashboardSelector = createSelector(
  DashboardRetrieveAllStateSelector,
  retrieveAllDashboardReducer => retrieveAllDashboardReducer.dashboard
);
