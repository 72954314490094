import { Types } from '../types';

const initialState = {
  isLoading: false,
  hasError: false,
  deleteSuccess: false,
  errorMsg: '',
};

export function deleteStudentReducer(state = initialState, action) {
  switch (action.type) {
    case Types.DELETE_STUDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteSuccess: true,
      };
    case Types.DELETE_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.DELETE_STUDENT_RESET_STATE:
      return initialState;
    default:
      return initialState;
  }
}
