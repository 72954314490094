import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_CLASSES } from '../../utils/enums';
import './Button.scss';

function Button({ children, handleClick, type, classes, dataTest, disabled }) {
  return (
      // eslint-disable-next-line react/button-has-type
      <button
        type="button"
        data-test={dataTest}
        onClick={handleClick}
        type={type}
        className={`button px-3 text-nowrap button${classes}`}
        disabled={disabled}
      >
        {children}
      </button>
  );
}
Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  handleClick: PropTypes.func,
  type: PropTypes.string,
  classes: PropTypes.oneOf(['--Await ', '--noAwait', '--grey', '--AwaitWhite ', '--noAwaitWhite']),
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  handleClick: () => {},
  classes: BUTTON_CLASSES.NO_AWAIT,
  disabled: false,
};

export default Button;
