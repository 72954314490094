import React, { useEffect, useRef, useState } from 'react'

import {
  NewCommentContainer,
  NewCommentTitle,
  NewCommentInput,
  Buttom,
  ButtomContainer,
  ButtomCancel,
  ButtomComment,
  ButtomDisable,
} from './styles'

export const NewComment = (props) => {

  let ref = useRef();

  const [newComment, setnewComment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ref.focus();
  }, [])

  const createComment = async () => {
    setLoading(true);
    props.handleNewComment(newComment, props.commentId || undefined);
    setnewComment('');
    setLoading(false);
  }

  return (
    <>
      <div style={NewCommentContainer} >
        <div style={NewCommentTitle}>
          {props.cancelAnswer ? 'Responder comentário:' : 'Comentar na aula:'}
        </div>
        <textarea
          style={NewCommentInput}
          rows={3}
          value={newComment}
          onChange={(e) => setnewComment(e.target.value)}
          ref={(input) => ref = input}
        />
      </div>
      <div style={ButtomContainer}>
        <button
          style={loading || newComment === '' ? {...Buttom, ...ButtomCancel, ...ButtomDisable} : {...Buttom, ...ButtomCancel}}
          onClick={() => props.cancelAnswer ? props.cancelAnswer() : setnewComment('')}
          disabled={loading}
        >
          Cancelar
        </button>
        <button
          style={loading || newComment === '' ? {...Buttom, ...ButtomComment, ...ButtomDisable} : {...Buttom, ...ButtomComment}}
          onClick={() => createComment()}
          disabled={newComment === '' || loading}
        >
          Comentar
        </button>
      </div>
    </>
  )
}
