import { Types } from '../types';

const initialState = {
  data: null,
  isLoading: false,
  hasError: false,
  errorMsg: '',
  success: false
};

export function updateWorkshopReducer(state = initialState, action) {
  const { data } = action.payload || state;
  switch (action.type) {
    case Types.UPDATE_WORKSHOP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Types.UPDATE_WORKSHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
      };
    case Types.UPDATE_WORKSHOP_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.UPDATE_WORKSHOP_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
