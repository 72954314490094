import { call, put } from 'redux-saga/effects';

import { getCitiesByState } from '../../client/cities';
import {
  retriveCitiesOfStateReducerSuccess,
  retriveCitiesOfStateReducerFailure,
} from '../../Redux/Cities/Retrieve/actions';

export function* retriveCitiesOfStateReducerSaga(action) {
  try {
    const { initalsOfState, authToken } = action.payload;
    const response = yield call(getCitiesByState, initalsOfState, authToken);
    yield put(retriveCitiesOfStateReducerSuccess(response));
  } catch (error) {
    yield put(retriveCitiesOfStateReducerFailure(error.message));
  }
}
