import React from 'react';
import PropTypes, { array } from 'prop-types';
import icSearch from '../../assets/images/ic_search.svg';

import './SearchAndFilter.scss';

function SearchAndFilter({
  haveFilter = false,
  filterOptions,
  selectedFilter,
  selectedSearchValue,
  setSearch,
  setFilter,
  inputPlaceholder,
  onSubmit,
}) {
  return (
    <form onSubmit={onSubmit} className="searchForm d-flex align-items-center flex-wrap mr-4">
      <div className="d-flex align-items-center mb-1">
        <input
          type="text"
          name="query"
          placeholder={inputPlaceholder}
          value={selectedSearchValue}
          onChange={event => setSearch(event.target.value)}
          className="searchBar__inputSearch"
          title="Pressionar enter para fazer a pesquisa"
          autoComplete="off"
        />
        <button type="submit" className="searchBar__buttonSearch">
          <img src={icSearch} alt="lupa" />
        </button>
      </div>

      {haveFilter && (
        <div className="filter mb-1">
          <span className="text-nowrap">Filtrar por:{'  '}</span>
          <select className="searchBar__selectType" value={selectedFilter} onChange={e => setFilter(e.target.value)}>
            {filterOptions.map(option => (
              <option value={option.value} key={option.value.toString()}>{option.text}</option>
            ))}
          </select>
        </div>
      )}
    </form>
  );
}

SearchAndFilter.propTypes = {
  haveFilter: PropTypes.bool.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  })),
  selectedFilter: PropTypes.number,
  selectedSearchValue: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  setFilter: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

SearchAndFilter.defaultProps = {
  haveFilter: false,
  filterOptions: [],
  selectedFilter: 0,
  setFilter: () => {},
  inputPlaceholder: '',
};

export default SearchAndFilter;
