import { createSelector } from 'reselect';

const WorkshopsSelectors = state => state.crudWorkshopsReducers;

export const WorkshopsRetrieveAllSelectors = createSelector(
  WorkshopsSelectors,
  retrieveWorkshopsRowsTotalOffsetReducer => retrieveWorkshopsRowsTotalOffsetReducer.retrieveWorkshopsRowsTotalOffsetReducer
);

export const WorkshopSearchSelectors = createSelector(
  WorkshopsSelectors,
  workshopSearch => workshopSearch.retrieveWorkshopsRowsTotalOffsetReducer.search
);

export const WorkshopCreateSelectors = createSelector(
  WorkshopsSelectors,
  createWorkshopsReducers => createWorkshopsReducers.createWorkshopReducer
);

export const WorkshopsCreateHasErrorSelectors = createSelector(
  WorkshopCreateSelectors,
  createWorkshopsReducers => createWorkshopsReducers.hasError
);

export const WorkshopsCreateErrorMessage = createSelector(
  WorkshopCreateSelectors,
  createWorkshopsReducers => createWorkshopsReducers.errorMsg
);

export const WorkshopSuccessSelectors = createSelector(
  WorkshopCreateSelectors,
  createWorkshopsReducers => createWorkshopsReducers.success
);

export const WorkshopsCreateLoading = createSelector(
  WorkshopCreateSelectors,
  createWorkshopsReducers => createWorkshopsReducers.isLoading
);

export const WorkshopUpdateSelectors = createSelector(
  WorkshopsSelectors,
  updateWorkshops => updateWorkshops.updateWorkshopReducer
);

export const WorkshopUpdateLoading = createSelector(WorkshopUpdateSelectors, updateWorkshops => updateWorkshops.isLoading);

export const WorkshopDeleteSelectors = createSelector(WorkshopsSelectors, deleteWorkshop => deleteWorkshop.deleteWorkshopReducer);

export const WorkshopDeleteLoading = createSelector(WorkshopDeleteSelectors, deleteWorkshop => deleteWorkshop.isLoading);
