import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Table } from '../../components/Table';
import Button from '../../components/Button';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { ModalToRegisterMacrothemes } from './ModalToRegisterMacrothemes';
import { ModalDispatch } from '../../contexts/ModalContext';
import { LoadingDispatch } from '../../contexts/LodingContext';

import { AuthTokenSelector } from '../../Redux/Auth/selector';
import { createMacrothemesResetState } from '../../Redux/Macrothemes/Create/actions';
import {
  defineMacrothemesSearchParams,
  retrieveMacrothemessRowsTotalOffsetRequest,
} from '../../Redux/Macrothemes/Retrieve/actions';
import { updateMacrothemesReset } from '../../Redux/Macrothemes/Update/actions';
import { deleteMacrothemesRequest, deleteMacrothemesReset } from '../../Redux/Macrothemes/Delete/actions';
import {
  MacrothemessRetrieveAllSelectors,
  MacrothemesCreateSelectors,
  MacrothemessCreateLoading,
  MacrothemesUpdateLoading,
  MacrothemesUpdateSelectors,
  MacrothemesDeleteLoading,
  MacrothemesDeleteSelectors,
  MacrothemessSearchSelectors,
} from '../../Redux/Macrothemes/selectors';

import { ModalToDeleteMacrothemes } from './ModalToDeleteMacrothemes';
import { showNotify } from '../../utils/showNotify';

function Macrothemess() {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const createUserLoading = useSelector(MacrothemessCreateLoading);
  const deleteUserLoading = useSelector(MacrothemesDeleteLoading);
  const [valuePagination, setValuePagination] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const modalDispatch = ModalDispatch();
  const loadingDispatch = LoadingDispatch();
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [dataToEdit, setDataToEdit] = useState();
  const [searchText, setSearchText] = useState('');

  const macrothemesSearch = useSelector(MacrothemessSearchSelectors);
  const createMacrothemes = useSelector(MacrothemesCreateSelectors);
  const updateMacrothemes = useSelector(MacrothemesUpdateSelectors);
  const deleteMacrothemes = useSelector(MacrothemesDeleteSelectors);
  const dataOfTable = useSelector(MacrothemessRetrieveAllSelectors);
  const macrothemesUpdateLoading = useSelector(MacrothemesUpdateLoading);

  const abortMacrothemesRetrieveAllRequestController = new AbortController();  

  const Titles = ['Data', 'Modificado em', 'Nome', 'Faixa etária inicial', 'Faixa etária final'];

  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'name' },
    { key: 'from_age_formatted' },
    { key: 'to_age_formatted' },
  ];

  const limit = itemsPerPage;
  const search = macrothemesSearch;

  const dispatchDataForTable = offset => {
    dispatch(retrieveMacrothemessRowsTotalOffsetRequest(offset, authToken, search, limit, abortMacrothemesRetrieveAllRequestController.signal));
  };

  useEffect(() => {
    setComponentLoaded(true);
    return () => {
      dispatch(defineMacrothemesSearchParams({ search: '' }));
      abortMacrothemesRetrieveAllRequestController.abort();
    };
  }, []);

  useEffect(() => {
    if (dataToEdit)
      modalDispatch({ type: 'open', payload: <ModalToRegisterMacrothemes useForEdit idMacrothemes={dataToEdit.id} /> });
  }, [dataToEdit]);

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  createUserLoading || macrothemesUpdateLoading || deleteUserLoading
    ? loadingDispatch({ type: 'open' })
    : loadingDispatch({ type: '' });

  function openModalToRegisterMacrothemes() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterMacrothemes /> });
  }

  function handleSearch(event) {
    event.preventDefault();
    let search = searchText ? `query=${searchText}` : '';
    dispatch(defineMacrothemesSearchParams({ search }));
  }

  function createMacrothemesNotify() {
    let contentNotify;
    let typeNotify;
    if (createMacrothemes.hasError) {
      typeNotify = 'error';

      if (createMacrothemes.errorMsg.includes('Network Error')) {
        contentNotify = 'Ocorreu um erro de internet ao cadastrar o tema. Tente novamente mais tarde!';
      } else if (createMacrothemes.errorMsg.includes('Duplicate entry')) {
        contentNotify = 'Ocorreu um erro. Já existe um tema com esse título!';
      } else {
        contentNotify = 'Ocorreu um erro ao cadastrar o tema. Tente novamente!';
      }
    } else if (createMacrothemes.success) {
      typeNotify = 'success';
      contentNotify = 'Tema cadastrado';
      dispatchDataForTable(valuePagination);
    }
    dispatch(createMacrothemesResetState());
    showNotify(typeNotify, contentNotify);
  }

  function updateMacrothemesNotify() {
    let contentNotify;
    let typeNotify;
    if (updateMacrothemes.hasError) {
      typeNotify = 'error';

      if (updateMacrothemes.errorMsg = 'Network Error') {
        contentNotify = 'Ocorreu um erro de internet ao atualizar o tema. Tente novamente mais tarde!';
      } else {
        contentNotify = 'Ocorreu um erro ao atualizar o tema. Tente novamente!';
      }
    } else if (updateMacrothemes.success) {
      typeNotify = 'success';
      contentNotify = `Tema atualizado`;

      dispatchDataForTable(valuePagination);
    }
    dispatch(updateMacrothemesReset());
    showNotify(typeNotify, contentNotify);
  }

  function deleteMacrothemesNotify() {
    let contentNotify;
    let typeNotify;
    if (deleteMacrothemes.hasError) {
      typeNotify = 'error';

      if (deleteMacrothemes.errorMsg = 'Network Error') {
        contentNotify = 'Ocorreu um erro de internet ao excluir o tema. Tente novamente mais tarde!';
      } else {
        contentNotify = 'Ocorreu um erro ao excluir o tema. Tente novamente!';
      }
    } else if (deleteMacrothemes.success) {
      typeNotify = 'success';
      contentNotify = 'Tema excluído';

      dispatchDataForTable(valuePagination);
    }
    dispatch(deleteMacrothemesReset());
    showNotify(typeNotify, contentNotify);
  }

  const getItemById = id => dataOfTable.rows.find(item => item.id === id);

  function deleteMacrothemesById(id) {
    dispatch(deleteMacrothemesRequest({ id, authToken }));
    modalDispatch({ type: '' });
  }

  async function _setDataToEdit(item) {
    if (dataToEdit?.id === item.id)
      modalDispatch({ type: 'open', payload: <ModalToRegisterMacrothemes useForEdit idMacrothemes={item.id} /> });
    else setDataToEdit(item);
  }

  async function openModalToDelete(id) {
    const { id: idMacrotheme, name } = getItemById(id);
    const payload = (
      <ModalToDeleteMacrothemes
        id={id}
        nameMacrothemes={name}
        actionIfYes={() => deleteMacrothemesById(idMacrotheme)}
      />
    );
    modalDispatch({ type: 'open', payload });
  }

  return (
    <>
      {(createMacrothemes.success || createMacrothemes.hasError) && createMacrothemesNotify()}
      {(updateMacrothemes.success || updateMacrothemes.hasError) && updateMacrothemesNotify()}
      {(deleteMacrothemes.success || deleteMacrothemes.hasError) && deleteMacrothemesNotify()}

      <div className="row mb-4">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Outros temas</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center mb-2">
          <SearchAndFilter   
            haveFilter={false}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            inputPlaceholder="Pesquisar por nome..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        <div className="col-auto ml-auto mb-1">
          <Button inputFilled handleClick={openModalToRegisterMacrothemes}>
          + Adicionar tema
          </Button>
        </div>
      </div>

      <Table
        titles={Titles}
        dataOfTable={{
          ...dataOfTable,
          rows: dataOfTable?.rows.filter(theme => theme.name !== 'Reforço Escolar' && theme.name !== 'Oficinas'),
        }}
        keysOfObjectsData={listOfKeys}
        dataKey="id"
        notColapse
        functionForPagination={setValuePagination}
        notColapseFunction={openModalToDelete}
        itemsPerPage={itemsPerPage}
        editButton={item => _setDataToEdit(item)}
        shouldResetPagination={shouldResetPagination}
      />
    </>
  );
}

export default withRouter(Macrothemess);
