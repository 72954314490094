import { Types } from '../types';

export function createEventRequest({ data, authToken }) {
  return {
    type: Types.CREATE_EVENT_REQUEST,
    payload: { data, authToken },
  };
}

export function createEventSuccess() {
  return {
    type: Types.CREATE_EVENT_SUCCESS,
  };
}

export function createEventFailure(errorMsg) {
  return {
    type: Types.CREATE_EVENT_FAILURE,
    payload: errorMsg,
  };
}

export function createEventResetState() {
  return {
    type: Types.CREATE_EVENT_RESET_STATE,
  };
}
