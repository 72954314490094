import { NotifyDispatch } from '../contexts/NotifyContext';

export function showNotify(typeNotify = '', contentNotify = '') {
  const notifyDispatch = NotifyDispatch();
  notifyDispatch({
    type: 'open',
    payload: {
      contentNotify,
      typeNotify,
    },
  });
}
