import { Types } from '../types';

export function updateMacrothemesRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_MACROTHEME_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateMacrothemesSucess() {
  return {
    type: Types.UPDATE_MACROTHEME_SUCCESS,
  };
}

export function updateMacrothemesFailure(errorMessage) {
  return {
    type: Types.UPDATE_MACROTHEME_FAILURE,
    payload: errorMessage,
  };
}

export function updateMacrothemesReset() {
  return {
    type: Types.UPDATE_MACROTHEME_RESET_STATE,
  };
}
