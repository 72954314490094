import { call, put } from 'redux-saga/effects';

import { changePasswordRequest } from '../../client/changePassword';
import { changePasswordSuccess, changePasswordFailure } from '../../Redux/ChangePassword/actions';

export function* changePasswordSaga({ payload }) {
  try {
    const { authToken, username, currentPassword, newPassword } = payload;

    yield call(changePasswordRequest, { username, currentPassword, newPassword }, authToken);
    yield put(changePasswordSuccess());
  } catch (error) {
    error.response && error.response.data ?
    yield put(changePasswordFailure(error.response.data )) : 
    yield put(changePasswordFailure(error.message));
  }
}

function setErrorMessage(errResponse) {
  let errorMsg = '';
  const { status, error } = errResponse;

  console.log('changePassword errResponse', errResponse);

  if(status === 401 || error === "Invalid Request") {
    errorMsg = 'Erro! O link de redefinição da senha pode estar incorreto!';
  } else { 
    errorMsg = 'Falha ao conectar. Por favor, verifique sua conexão com a Internet e tente novamente.';
 }

  return errorMsg;
}
