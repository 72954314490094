export function validateVideoUrl(url) {
  const uriSplitted = url.split('/');
  if (uriSplitted[2] === 'vimeo.com' && uriSplitted[3].match(/^[0-9]+$/) != null && !uriSplitted[4]) {
    return true;
  } else if (uriSplitted[2] === 'www.youtube.com' && (uriSplitted[3] && uriSplitted[3].indexOf('watch') !== -1)) {
    return true;
  } else if (uriSplitted[2] === 'youtu.be') {
    return true;
  }
  return false;
}
