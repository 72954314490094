import React from 'react';
import PropTypes from 'prop-types';
import './Select.scss';

function Select({ children, deactivate, valueOfSelect, setValueOfSelect, required }) {
  const handleChange = e => setValueOfSelect(e.target.value);
  return (
    <select
      className="selectStyle"
      value={valueOfSelect}
      onChange={handleChange}
      disabled={deactivate}
      required={required}
    >
      {children}
    </select>
  );
}

Select.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  deactivate: PropTypes.bool,
  setValueOfSelect: PropTypes.func.isRequired,
  valueOfSelect: PropTypes.string,
  required: PropTypes.bool,
};

Select.defaultProps = {
  deactivate: false,
  valueOfSelect: '',
  required: false,
};

export default Select;
