import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from '../../../components/Select';
import Thumbnail from '../../../components/Thumbnail';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import EditAvatar from '../../../components/EditAvatar';

import listOfState from '../../../utils/listOfState';

import { AdminsRetrieveAllSelectors } from '../../../Redux/Admin/selectors';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';

import { createAdminRequest } from '../../../Redux/Admin/Create/actions';
import { updateAdminRequest } from '../../../Redux/Admin/Update/actions';

import undefinedUserBase64 from '../../../assets/images/undefinedUserBase64';
import { getProfilePicture } from '../../../client/users';
import { BUTTON_CLASSES } from '../../../utils/enums';

function AdminsInfo({ idAdmin, useForEdit, closeModal }) {
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [cellphone, setCellphone] = useState();
  const [email, setEmail] = useState();
  const [countryState, setCountryState] = useState();
  const [city, setCity] = useState();

  const [avatar, setAvatar] = useState(undefinedUserBase64);
  const [newAvatar, setNewAvatar] = useState();
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);

  const textButton = useForEdit ? 'Atualizar' : 'Cadastrar';

  const authToken = useSelector(AuthTokenSelector);
  const admins = useSelector(AdminsRetrieveAllSelectors);

  function createAdmin(e) {
    e.preventDefault();
    const parameters = {
      data: {
        name,
        email,
        avatar: avatar && avatar !== undefinedUserBase64 ? avatar.replace(/^data:image\/png;base64,/, '') : null,
        password: '12345678',
      },
      authToken,
    };
    dispatch(createAdminRequest(parameters));
    closeModal();
  }

  function updateAdmin(e) {
    e.preventDefault();
    const parameters = {
      id: idAdmin,
      data: {
        name,
        avatar: avatar && avatar !== undefinedUserBase64 ? avatar.replace(/^data:image\/png;base64,/, '') : null,
      },
      authToken,
    };
    dispatch(updateAdminRequest(parameters));
    closeModal();
  }

  const finalDispatch = useForEdit ? updateAdmin : createAdmin;

  async function startComponent() {
    if (useForEdit) {
      const { name: Name, username, profilePicture } = admins.rows.find(val => val.userId === idAdmin);
      setName(Name);
      setEmail(username);

      const profilePicturePhoto = await getProfilePicture(profilePicture, authToken);
      setAvatar('data:image/png;base64,' + profilePicturePhoto.data);
    }
  }

  useEffect(() => {
    startComponent();
  }, []);


  const onSaveAvatarImage = () => {
    setAvatar(newAvatar);
    setNewAvatar();
    setIsAvatarModalOpen(false);
  };

  const onRemoveAvatarImage = () => {
    setNewAvatar(null);
  };

  const onAvatarImageChange = image => {
    setNewAvatar(image);
  };

  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  const onCancelAvatarEdit = () => {
    setIsAvatarModalOpen(false);
    setNewAvatar(avatar !== undefinedUserBase64 ? avatar : null);
  };

  return (
    <>
      <div>
        {!isAvatarModalOpen && (
          <Thumbnail alt="foto do administrador" height="104px" image={avatar} clickFn={openAvatarModal} />
        )}

        {isAvatarModalOpen && (
          <EditAvatar
            avatar={newAvatar}
            onChange={onAvatarImageChange}
            onSaveAvatar={onSaveAvatarImage}
            onRemoveAvatar={onRemoveAvatarImage}
            onCancel={onCancelAvatarEdit}
          />
        )}
      </div>

      <div className="mt-4 row">
        <form onSubmit={finalDispatch} className="col-12">
          <div className="row">
            <div className="col-4 mr-4">
              <label htmlFor="name">Nome</label>
              <Input id="name" inputValue={name} setInputValue={setName} required maxLength={40} />
            </div>

            <div className="col-4 d-none">
              <label htmlFor="cellphone">DDD + Telefone</label>
              <Input
                id="cellphone"
                inputValue={cellphone}
                typeInput="number"
                setInputValue={setCellphone}
                maxLength={15}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-4 mr-4">
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                readOnly={useForEdit}
                inputValue={email}
                typeInput="email"
                setInputValue={setEmail}
                required
              />
            </div>

            <div className="col-4 d-none">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="state">Estado</label>
                  <br />
                  <Select valueOfSelect={countryState} setValueOfSelect={setCountryState}>
                    {listOfState.map(val => (
                      <option key={val.initials} value={val.initials}>
                        {val.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="col-6">
                  <label htmlFor="city">Cidade</label>
                  <br />
                  <Select valueOfSelect={city} setValueOfSelect={setCity}>
                    {listOfState.map(val => (
                      <option key={val.initials} value={val.initials}>
                        {val.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2 mb-1">
            <Button classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`} type="button" handleClick={closeModal}>
              Cancelar
            </Button>

            <Button inputFilled type="submit" classes={`${BUTTON_CLASSES.NO_AWAIT} col-2`}>
              {textButton}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

AdminsInfo.propTypes = {
  idAdmin: PropTypes.number,
  useForEdit: PropTypes.bool,
  closeModal: PropTypes.func,
};

AdminsInfo.defaultProps = {
  idAdmin: 0,
  useForEdit: false,
  closeModal: () => {},
};

export default AdminsInfo;
