import { createSelector } from 'reselect';

const StudentsSelectors = state => state.CrudStudentsReducers;

export const StudentsRetrieveAllSelectors = createSelector(
  StudentsSelectors,
  retrieveStudentsRowsTotalOffsetReducer =>
    retrieveStudentsRowsTotalOffsetReducer.retrieveStudentsRowsTotalOffsetReducer
);

export const StudentsSearchSelectors = createSelector(
  StudentsSelectors,
  studentsSearch => studentsSearch.retrieveStudentsRowsTotalOffsetReducer.search
);


export const StudentUpdateSelectors = createSelector(
  StudentsSelectors,
  updateStudent => updateStudent.updateStudentReducer
);

export const StudentUpdateLoading = createSelector(
  StudentsSelectors,
  updateStudents => updateStudents.updateStudentReducer.isLoading
);

export const ClassDeleteSelectors = createSelector(
  StudentsSelectors,
  retrieveStudentsRowsTotalOffsetReducer => retrieveStudentsRowsTotalOffsetReducer.deleteStudentReducer
);

export const ResetPasswordSelectors = createSelector(
  StudentsSelectors,
  resetPasswordReducer => resetPasswordReducer
);
