import React from 'react';
import { Select } from '../../components/Select';
import { ITEMS_PER_PAGE_ARR } from '../../utils/enums';

import './SelectItemsPerPage.scss';

export default function SelectItemsPerPage({itemsPerPage, setItemsPerPage}) {
  return (
    <>
      <div className="col-md-auto pl-0 itemsPerPageSelectTitle">
        <span className="text-nowrap">Itens por página:</span>
        <Select valueOfSelect={itemsPerPage} setValueOfSelect={setItemsPerPage}>
          {ITEMS_PER_PAGE_ARR.map(val => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
}
