export const Types = {
  DEFINE_MENTORS_SEARCH_PARAMS: '[mentors]DEFINE_MENTORS_SEARCH_PARAMS',
  CREATE_MENTOR_REQUEST: '[mentors]CREATE_MENTOR_REQUEST',
  CREATE_MENTOR_SUCCESS: '[mentors]CREATE_MENTOR_SUCCESS',
  CREATE_MENTOR_FAILURE: '[mentors]CREATE_MENTOR_FAILURE',
  CREATE_MENTOR_RESET_STATE: '[mentors]CREATE_MENTOR_RESET_STATE', // TODO - need refactory.
  RETRIEVE_ALL_MENTORS_REQUEST: '[mentors]RETRIEVE_ALL_MENTORS_REQUEST',
  RETRIEVE_ALL_MENTORS_SUCCESS: '[mentors]RETRIEVE_ALL_MENTORS_SUCCESS',
  RETRIEVE_ALL_MENTORS_FAILURE: '[mentors]RETRIEVE_ALL_MENTORS_FAILURE',
  UPDATE_MENTOR_REQUEST: '[mentors]UPDATE_MENTOR_REQUEST',
  UPDATE_MENTOR_SUCCESS: '[mentors]UPDATE_MENTOR_SUCCESS',
  UPDATE_MENTOR_FAILURE: '[mentors]UPDATE_MENTOR_FAILURE',
  UPDATE_MENTOR_RESET_STATE: '[mentors]UPDATE_MENTOR_RESET_STATE',
  DELETE_MENTOR_REQUEST: '[mentors]DELETE_MENTOR_REQUEST',
  DELETE_MENTOR_SUCCESS: '[mentors]DELETE_MENTOR_SUCCESS',
  DELETE_MENTOR_FAILURE: '[mentors]DELETE_MENTOR_FAILURE',
  DELETE_MENTOR_RESET_STATE: '[mentors]DELETE_MENTOR_RESET_STATE',
};
