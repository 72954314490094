import { DashboardTypes } from '../types';

function FormatValue(backendTitle, value) {
  if ((backendTitle === 'new_students' || backendTitle === 'new_teachers') && value > 0) {
    return `+${value}`;
  }
  return value;
}

const initialState = {
  dashboard: [],
  isLoading: false,
  hasError: false,
  errorMsg: '',
  signal: null,
};

export function retrieveAllDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case DashboardTypes.RETRIEVE_ALL_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
        signal: action.payload.signal,
      };
    case DashboardTypes.RETRIEVE_ALL_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboard: action.payload,
      };
    case DashboardTypes.RETRIEVE_ALL_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
}
