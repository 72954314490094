import { Types } from '../types';

export function updateUnitsRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_UNITS_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateUnitsSucess({ data }) {
  return {
    type: Types.UPDATE_UNITS_SUCCESS,
    payload: { data },
  };
}

export function updateUnitsFailure(errorMessage) {
  return {
    type: Types.UPDATE_UNITS_FAILURE,
    payload: errorMessage,
  };
}

export function updateUnitsReset() {
  return {
    type: Types.UPDATE_UNITS_RESET_STATE,
  };
}
