import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Api } from '../../services/api';

import { AuthTokenSelector } from '../../Redux/Auth/selector';

import logoGf from '../../assets/images/falcon.svg';
import icComment from '../../assets/images/icComment.png';
import icTrash from '../../assets/images/icTrash.png';

import { Split } from '../Split';
import { SmallSplit } from '../SmallSplit';
import { NewComment } from '../NewComment';

import { Avatar, Container, CommentContainer, Header, Icon, Tools, Tool, Name, DateStyle, Message, TeacherAnswer } from './styles';

export const Comment = (props) => {

  const authToken = useSelector(AuthTokenSelector);
  const [answer, setAnswer] = useState(false);
  const [avatar, setAvatar] = useState();

  const handleDelete = () => {
    props.handleDelete(props.item.id);
  }

  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer '.concat(authToken),
    },
  };

  const getAvatar = async () => {
    if (props.item.profilePicture) {
      try {
        const response = await Api.get(`user/profilepicture/${props.item.profilePicture}`, requestConfig);
        setAvatar(`data:image/png;base64,${response.data}`);
      } catch (error) {
        console.log(error.response);
      }
    }
	};

  useEffect(() => {
    getAvatar();
  }, [])

  return (
    <>
    <div style={Container}>

      <img style={Avatar} src={avatar || logoGf}/>
      <div style={CommentContainer}>
        <div style={Header}>
          <div style={Name}>{props.item.name}</div>
          <div style={DateStyle}>{convertTimeOfComment(props.item.createdAt)}</div>
        </div>
        <div style={Message}>{props.item.message}</div>
      </div>
      <div style={Tools}>
         {props.answer && <img style={Icon} src={icComment} onClick={() => setAnswer(true)} />}
         {props.delete && <img style={Icon} src={icTrash} onClick={() => handleDelete()} /> }
      </div>
    {/* {props.item.alias === 'teacher' && <Split/>} */}
    </div>
    {answer &&
      <NewComment
        handleNewComment={(message, commentId) => {
          props.handleNewComment(message, commentId)
        }}
        cancelAnswer={() =>  setAnswer(false)}
        commentId={props.item.id}
      />
    }
    {props.item.comments?.map((item) =>
      <>
        {item.alias === 'teacher' &&
          <div style={TeacherAnswer}>
            Resposta do professor
            <SmallSplit/>
          </div>
        }
        <Comment
          item={item}
          delete={props.delete}
          answer={props.answer}
          handleDelete={(commentId) => props.handleDelete(commentId)}
          handleNewComment={(message, commentId) => props.handleNewComment(message, commentId)}
        />
      </>
    )}
    </>
  )
}

function convertTimeOfComment(time) {
	let date = new Date(time);
	let now = new Date();
	let since = now - date;

	since = since/1000; // seconds
	if (since < 1) return 'há 1 segundo atrás'
	else {
		since = since / 60; // minutes
		if (since < 1) return `há ${(since*60).toFixed()} segundos atrás`
		else {
			since = since / 60; // hours
			if (since < 1) return `há ${(since*60).toFixed()} minutos atrás`
			else {
				since = since / 24; // days
				if (since < 1) return `há ${(since*24).toFixed()} horas atrás`
				else {
					since = since / 30; // months
					if (since < 1) return `há ${(since*30).toFixed()} dias atrás`
					else {
						since = since / 12; // years
						if (since < 1) return `há ${(since*12).toFixed()} meses atrás`
						else {
							return `há ${since.toFixed()} anos atrás`
						}
					}
				}
			}
		}
	}
}
