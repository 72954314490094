import { combineReducers } from 'redux';

import { updateEventReducer } from './Update/reducer';
import { createEventReducer } from './Create/reducer';
import { retrieveEventsRowsTotalOffsetReducer } from './Retrieve/reducer';
import { retrieveEventContentReducer } from './RetrieveEventContent/reducer';
import { deleteEventReducer } from './Delete/reducers';

const reducers = combineReducers({
  retrieveEventsRowsTotalOffsetReducer,
  retrieveEventContentReducer,
  createEventReducer,
  updateEventReducer,
  deleteEventReducer,
});

export { reducers as CrudEventsReducers };
