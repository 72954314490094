import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import icAttention from '../../../assets/images/ic_attetion.svg';
import { DeleteModalStyles as Styles } from '../../../components/Modal';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

import { ModalDispatch } from '../../../contexts/ModalContext';
import { BUTTON_CLASSES } from '../../../utils/enums';
import { deleteWorkshopFailure } from '../../../Redux/Workshop/Delete/actions';
import { getWorkshopReferences } from '../../../client/workshops';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';
import icLoader from '../../../assets/images/ic_loading.svg';

function ModalToDeleteWorkshop({ id, nameWorkshop, actionIfYes }) {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const [classQty, setClassQty] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const modalDispatch = ModalDispatch();

  useEffect(() => {
    const getInfo = async () => {
      const {
        data: { classesSubjects },
      } = await getWorkshopReferences(authToken, id);
  
      setClassQty(classesSubjects);
      setIsLoading(false);
    };

    getInfo()
      .then(() => {})
      .catch(err => {
        setIsLoading(false);
        err.response && err.response.data
          ? dispatch(deleteWorkshopFailure(err.response.data))
          : dispatch(deleteWorkshopFailure(err.message));

        modalDispatch({ type: '' })
      });
  }, []);

  return (
    <Modal size="md" alignHorizontal="center" centralizeVertical>
      {isLoading ? (
        <img
          src={icLoader}
          alt="circulos rotacionando em 360 graus infinitamente"
          height="60px"
          className="d-flex justify-content-center w-100"
        />
      ) : (
        <div style={Styles.Container}>
          <div style={Styles.Header}>
            <img style={Styles.Image} src={icAttention} alt="attention" />
            Cuidado!
          </div>
          {classQty > 0 ? (
            <>
              <div style={Styles.Text}>
                {'Tem certeza que deseja excluir a Oficina '} <span style={Styles.TextBold}>{nameWorkshop}</span>{' '}
                {'e as seguintes informações relacionadas?'}
              </div>
              <div style={Styles.List}>
                <div style={Styles.Item}>
                  {classQty} {classQty > 1 ? 'Aulas' : 'Aula'}
                </div>
              </div>
            </>
          ) : (
            <div>
              <div style={Styles.Text}>
                {'Tem certeza que deseja excluir a Oficina '} <span style={Styles.TextBold}>{nameWorkshop}</span>
                {'?'}
              </div>
            </div>
          )}

          <div className="mt-5 mb-4 d-flex justify-content-center">
            <div className="col-md-4">
              <Button
                inputFilled
                type="button"
                handleClick={() => modalDispatch({ type: '' })}
                classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
              >
                Não
              </Button>
            </div>
            <div className="col-md-4">
              <Button inputFilled type="button" handleClick={actionIfYes}>
                Sim
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

ModalToDeleteWorkshop.propTypes = {
  nameWorkshop: PropTypes.string.isRequired,
  classQty: PropTypes.number,
  actionIfYes: PropTypes.func.isRequired,
};

ModalToDeleteWorkshop.defaultValues = {
  nameWorkshop: '',
  classQty: 0,
  actionIfYes: () => {},
};

export default ModalToDeleteWorkshop;
