import { combineReducers } from 'redux';

import { retrieveStudentsRowsTotalOffsetReducer } from './Retrieve/reducer';
import { deleteStudentReducer } from './Delete/reducers';
import { updateStudentReducer } from './Update/reducer';
import { resetPasswordReducer } from './ResetPassword/reducer';

const reducers = combineReducers({
  retrieveStudentsRowsTotalOffsetReducer,
  deleteStudentReducer,
  updateStudentReducer,
  resetPasswordReducer
});

export { reducers as CrudStudentsReducers };
