import { Types } from '../types';

const initialState = {
  data: [],
  isLoading: false,
  hasError: false,
  errorMsg: '',
};

export function retrieveSubjectsByUnitReducers(state = initialState, action) {
  const { data } = action.payload || state;
  switch (action.type) {
    case Types.RETRIEVE_SUBJECTS_BY_UNIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.RETRIEVE_SUBJECTS_BY_UNIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data,
      };
    case Types.RETRIEVE_SUBJECTS_BY_UNIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.RETRIEVE_SUBJECTS_BY_UNIT_RESET:
      return initialState;
    default:
      return state;
  }
}
