import { Types } from '../types';

const initialState = {
  limit: 0,
  offset: 0,
  total: 0,
  rows: [],
  search: '',
  isLoading: false,
  hasError: false,
  errorMsg: '',
  signal: null
};

export function retrieveEventsRowsTotalOffsetReducer(state = initialState, action) {
  const { rows, total, offset, search, limit, signal } = action.payload || state;
  switch (action.type) {
    case Types.DEFINE_EVENTS_SEARCH_PARAMS:
      return {
        ...state,
        search: search ?? state.search,
      };
    case Types.RETRIEVE_ALL_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        signal
      };
    case Types.RETRIEVE_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        rows,
        total,
        offset,
        limit,
        isLoading: false,
      };
    case Types.RETRIEVE_ALL_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
}
