import { createSelector } from 'reselect';

const ChangePasswordSelectorState = state => state.changePasswordReducer;

export const ChangePasswordHasErrorErrorMsgSelector = 
  createSelector(ChangePasswordSelectorState, changePasswordReducer => ({
    hasError: changePasswordReducer.hasError,
    errorMsg: changePasswordReducer.errorMsg,
}));


export const ChangePasswordSelectors = createSelector(
  ChangePasswordSelectorState,
  changePassword => changePassword
);
