import React from 'react';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { CreateClass } from '../CreateClass';

// eslint-disable-next-line react/prop-types
function ModalToRegisterClass({ initialData }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl" alignHorizontal="center">
      <CreateClass closeModal={closeModal} initialData={initialData} />
    </Modal>
  );
}

export default ModalToRegisterClass;
