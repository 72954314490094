import { Types } from '../types';

export function retrieveSchoolsRequest(countryState, city, authToken) {
  return {
    type: Types.RETRIEVE_SCHOOLS_BY_STATE_N_CITY_REQUEST,
    payload: { countryState, city, authToken },
  };
}

export function retrieveSchoolsSuccess({ data }) {
  return {
    type: Types.RETRIEVE_SCHOOLS_BY_STATE_N_CITY_SUCCESS,
    payload: { data },
  };
}

export function retrieveSchoolsFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_SCHOOLS_BY_STATE_N_CITY_FAILURE,
    payload: errorMsg,
  };
}
