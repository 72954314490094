import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function CardItem({ title, value }) {
  return (
    <div className="cardContainer">
      <div className="content">
        <span className="content__title mt-1 mb-2">{title}</span>
        <span className="content__value mt-4">{value}</span>
      </div>
    </div>
  );
}

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default CardItem;
