import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Modal.scss';

import { ModalDispatch, ModalState } from '../../contexts/ModalContext';
import icClose from '../../assets/images/ic_close.svg';

function Modal({ children, size, alignHorizontal, centralizeVertical, clearDataOnCloseModal}) {
  const [typeSize, setSize] = useState('');
  const [typeAlign, setAlign] = useState('');

  const modalDispatch = ModalDispatch();
  const { modalIsOpen } = ModalState();
  const history = useHistory();

  function defineSize() {
    switch (size) {
      case 'xl':
        setSize('containerModal--xl');
        break;
      case 'lg':
        setSize('containerModal--lg');
        break;
      case 'md':
        setSize('containerModal--md');
        break;
      case 'sm':
        setSize('containerModal--sm');
        break;
      case 'fit':
        setSize('containerModal--fit');
        break;
      default:
        throw new Error('size undefined');
    }
  }

  function defineAlignHorizontal() {
    switch (alignHorizontal) {
      case 'right':
        setAlign('containerModal--horizontalRight');
        break;
      case 'center':
        setAlign('containerModal--horizontalCenter');
        break;
      default:
        break;
    }
  }

  function adjustModal() {
    defineSize();
    defineAlignHorizontal();
  }

  function closedModal(e) {
    e.preventDefault();
    modalDispatch({ type: 'close' });
  }

  useEffect(() => adjustModal(), []);

  history.listen(() => {
    if (modalIsOpen) {
      modalDispatch({ type: '' });
    }
  });

  return (
    <div className={`viewModal ${centralizeVertical && 'viewModal--centralizeVertical'}`}>
      <div className="background" onClick={clearDataOnCloseModal ? clearDataOnCloseModal : closedModal} />
      <div className={`containerModal ${typeSize} ${typeAlign}`}>
        <div className="card containerModal__content">
          <div className="containerModal__content__header">
            <a
              className="containerModal__content__header__closeButton"
              title="Botão para fechar modal"
              onClick={clearDataOnCloseModal ? clearDataOnCloseModal : closedModal}
            >
              <img src={icClose} alt="Botão com icone de x" />
            </a>
          </div>
          <div className="containerModal__content__body">{children}</div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  size: PropTypes.string.isRequired,
  alignHorizontal: PropTypes.string,
  centralizeVertical: PropTypes.bool,
};

Modal.defaultProps = {
  alignHorizontal: '',
  centralizeVertical: false,
};

export default Modal;
