export const Types = {
  DEFINE_TEACHERS_SEARCH_PARAMS: '[teachers]DEFINE_TEACHERS_SEARCH_PARAMS',
  CREATE_TEACHER_REQUEST: '[teachers]CREATE_TEACHER_REQUEST',
  CREATE_TEACHER_SUCCESS: '[teachers]CREATE_TEACHER_SUCCESS',
  CREATE_TEACHER_FAILURE: '[teachers]CREATE_TEACHER_FAILURE',
  CREATE_TEACHER_RESET_STATE: '[teachers]CREATE_TEACHER_RESET_STATE',
  RETRIEVE_ALL_TEACHERS_REQUEST: '[teachers]RETRIEVE_ALL_TEACHERS_REQUEST',
  RETRIEVE_ALL_TEACHERS_SUCCESS: '[teachers]RETRIEVE_ALL_TEACHERS_SUCCESS',
  RETRIEVE_ALL_TEACHERS_FAILURE: '[teachers]RETRIEVE_ALL_TEACHERS_FAILURE',
  UPDATE_TEACHER_REQUEST: '[teachers]UPDATE_TEACHER_REQUEST',
  UPDATE_TEACHER_SUCCESS: '[teachers]UPDATE_TEACHER_SUCCESS',
  UPDATE_TEACHER_FAILURE: '[teachers]UPDATE_TEACHER_FAILURE',
  UPDATE_TEACHER_RESET_STATE: '[teachers]UPDATE_TEACHER_RESET_STATE',
  DELETE_TEACHER_REQUEST: '[teachers]DELETE_TEACHER_REQUEST',
  DELETE_TEACHER_SUCCESS: '[teachers]DELETE_TEACHER_SUCCESS',
  DELETE_TEACHER_FAILURE: '[teachers]DELETE_TEACHER_FAILURE',
  DELETE_TEACHER_RESET_STATE: '[teachers]DELETE_TEACHER_RESET_STATE',
};
