import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './PaginationTable.scss';

import icNavigationDoubleArrowLeft from '../../../assets/images/ic_navigation_double_arrow_left.svg';
import icNavigationSingleArrowLeft from '../../../assets/images/ic_navigation_single_arrow_left.svg';
import icNavigationSingleArrowRight from '../../../assets/images/ic_navigation_single_arrow_right.svg';
import icNavigationDoubleArrowRight from '../../../assets/images/ic_navigation_double_arrow_right.svg';

import { ButtonOFPagination } from './ButtonOFPagination';

function PaginationTable({ currentPage, totalPages, totalInPage, totalRegisters, functionForPagination, isLoading, shouldReset }) {
  const [controllValueOfPagination, setControllValueOfPagination] = useState(0);

  const [disabledBackToFirstPage, setDisabledBackToFirstPage] = useState(true);
  const [disabledBackPage, setDisabledBackPage] = useState(true);
  const [disabledNextPage, setDisabledNextPage] = useState(false);
  const [disabledNextToLastPage, setDisabledNextToLastPage] = useState(false);

  useEffect(() => {
    // if total of items per page in table changes, 
    // or if search query changes, 
    // we return to first page and set offset as zero
    if(shouldReset) {
      backToFirstPage();
    }
  }, [shouldReset]);

  useEffect(() => {
    // if total of items per page in table is greather than total of items, next buttons are disabled
    if (totalRegisters < totalInPage) {
      setDisabledButtonsNext(true, true);
    } else setDisabledButtonsNext(false, false);
  }, [totalRegisters, totalInPage]);

  useEffect(() => {
    functionForPagination(controllValueOfPagination);
  }, [controllValueOfPagination]);

  function setDisabledButtonsBack(backOne, backAll) {
    setDisabledBackPage(backOne);
    setDisabledBackToFirstPage(backAll);
  }

  function setDisabledButtonsNext(nextOne, nextAll) {
    setDisabledNextPage(nextOne);
    setDisabledNextToLastPage(nextAll);
  }

  function backToFirstPage() {
    setControllValueOfPagination(0);
    setDisabledButtonsBack(true, true);
    setDisabledButtonsNext(false, false);
  }

  function backPage() {
    if (controllValueOfPagination > 0) {
      setControllValueOfPagination(controllValueOfPagination - totalInPage);
    }
    if (currentPage <= 2) {
      setDisabledButtonsBack(true, true);
    }
    setDisabledButtonsNext(false, false);
  }

  function nextPage() {
    if (currentPage < totalPages) {
      setControllValueOfPagination(controllValueOfPagination + totalInPage);
      setDisabledButtonsBack(false, false);
    }
    if (currentPage + 1 === totalPages || currentPage === totalPages) {
      setDisabledButtonsNext(true, true);
    }
  }

  function nextToLastPage() {
    setControllValueOfPagination((totalPages - 1) * totalInPage);
    setDisabledButtonsBack(false, false);
    setDisabledButtonsNext(true, true);
  }

  return (
    <div hidden={isLoading}>
      <div className="text-center mt-2">{`${currentPage} de ${totalPages}`}</div>
      <div className="mb-4 mt-3 d-flex justify-content-center">
        <ButtonOFPagination
          icon={icNavigationDoubleArrowLeft}
          alt="dois símbolos de maior que, indicação para retornar até a primeira página da tabela"
          classNameCss="CommonStyleOfPagination"
          handleClick={backToFirstPage}
          disabled={disabledBackToFirstPage}
        />

        <ButtonOFPagination
          icon={icNavigationSingleArrowLeft}
          alt="símbolo de maior que, indicação para retornar uma página da tabela"
          classNameCss="CommonStyleOfPagination"
          handleClick={backPage}
          disabled={disabledBackPage}
        />

        <div className="CommonStyleOfPagination mr-1 currentPage" title="Página Atual">
          {currentPage}
        </div>

        <ButtonOFPagination
          icon={icNavigationSingleArrowRight}
          alt="símbolo de menor que, indicação para retornar uma página da tabela"
          classNameCss="CommonStyleOfPagination"
          handleClick={nextPage}
          disabled={disabledNextPage}
        />

        <ButtonOFPagination
          icon={icNavigationDoubleArrowRight}
          alt="dois símbolos de menor que, indicação para Avançar até a última página da tabela"
          classNameCss="CommonStyleOfPagination"
          handleClick={nextToLastPage}
          disabled={disabledNextToLastPage}
        />
      </div>
    </div>
  );
}

PaginationTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalInPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  functionForPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PaginationTable;
