import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Table } from '../../components/Table';
import Button from '../../components/Button';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { ModalToRegisterMentor } from './ModalToRegisterMentor';
import { ModalDispatch } from '../../contexts/ModalContext';
import { LoadingDispatch } from '../../contexts/LodingContext';

import { AuthTokenSelector } from '../../Redux/Auth/selector';
import { createMentorResetState } from '../../Redux/Mentor/Create/actions';
import { defineMentorsSearchParams, retrieveMentorsRowsTotalOffsetRequest } from '../../Redux/Mentor/Retrieve/actions';
import { updateMentorReset } from '../../Redux/Mentor/Update/actions';
import { deleteMentorRequest, deleteMentorReset } from '../../Redux/Mentor/Delete/actions';

import {
  MentorsRetrieveAllSelectors,
  MentorsCreateHasErrorSelectors,
  MentorsCreateGetUserSelectors,
  MentorsCreateLoading,
  MentorsCreateErrorMessage,
  MentorUpdateLoading,
  MentorUpdateSelectors,
  MentorDeleteLoading,
  MentorDeleteSelectors,
  MentorsSearchSelectors,
} from '../../Redux/Mentor/selectors';

import { ModalToDeleteMentor } from './ModalToDeleteMentor';

import { showNotify } from '../../utils/showNotify';

function Mentors() {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const createUserLoading = useSelector(MentorsCreateLoading);
  const deleteUserLoading = useSelector(MentorDeleteLoading);
  const [valuePagination, setValuePagination] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const modalDispatch = ModalDispatch();
  const loadingDispatch = LoadingDispatch();
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [dataToEdit, setDataToEdit] = useState();
  const [searchText, setSearchText] = useState('');

  const mentorsSearch = useSelector(MentorsSearchSelectors);
  const user = useSelector(MentorsCreateGetUserSelectors);
  const createUserError = useSelector(MentorsCreateHasErrorSelectors);
  const updateMentor = useSelector(MentorUpdateSelectors);
  const deleteMentor = useSelector(MentorDeleteSelectors);
  const errorMsg = useSelector(MentorsCreateErrorMessage);

  const abortMentorsRetrieveAllRequestController = new AbortController();

  const Titles = ['Data', 'Modificado em', 'Nome', 'Email'];

  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'name' },
    { key: 'username' },
  ];

  const limit = itemsPerPage;
  const search = mentorsSearch;

  useEffect(() => {
    if (dataToEdit)
      modalDispatch({ type: 'open', payload: <ModalToRegisterMentor useForEdit idMentor={dataToEdit.userId} /> });
  }, [dataToEdit]);

  const dispatchDataForTable = offset => {
    dispatch(retrieveMentorsRowsTotalOffsetRequest(offset, authToken, search, limit, abortMentorsRetrieveAllRequestController.signal));
  };

  useEffect(() => {
    setComponentLoaded(true);
    return () => {
      dispatch(defineMentorsSearchParams({ search: '' }));
      abortMentorsRetrieveAllRequestController.abort();
    }
  }, []);

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  const dataOfTable = useSelector(MentorsRetrieveAllSelectors);
  const mentorUpdateLoading = useSelector(MentorUpdateLoading);

  createUserLoading || mentorUpdateLoading || deleteUserLoading
    ? loadingDispatch({ type: 'open' })
    : loadingDispatch({ type: '' });

  function handleSearch(event) {
    event.preventDefault();
    let search = searchText ? `keyword=${searchText}` : '';
    dispatch(defineMentorsSearchParams({ search }));
  }

  function openModalToRegisterMentor() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterMentor /> });
  }

  const getItemById = userId => dataOfTable.rows.find(item => item.userId === userId);

  function deleteMentorById(id) {
    dispatch(deleteMentorRequest({ id, authToken }));
    modalDispatch({ type: '' });
  }

  async function _setDataToEdit(item) {
    if (dataToEdit?.userId === item.userId) {
      modalDispatch({ type: 'open', payload: <ModalToRegisterMentor useForEdit idMentor={item.userId} /> });
    } else setDataToEdit(item);
  }

  async function openModalToDelete(userId) {
    const { userId: idMentor, name } = getItemById(userId);
    const payload = <ModalToDeleteMentor nameMentor={name} actionIfYes={() => deleteMentorById(idMentor)} />;
    modalDispatch({ type: 'open', payload });
  }

  function createMentorNotify() {
    let contentNotify;
    let typeNotify;
    if (createUserError) {
      typeNotify = 'error';
      contentNotify = `Ocorreu um erro ao cadastrar o(a) líder. `;

      if (errorMsg) {
        if (errorMsg.includes('username_UNIQUE')) contentNotify += ' E-mail duplicado.';
        else contentNotify += errorMsg;
      }
    } else if (user) {
      typeNotify = 'success';
      contentNotify = `Líder cadastrado(a) | Senha: 12345678`;
      dispatch(createMentorResetState());
      dispatchDataForTable(valuePagination);
    }
    showNotify(typeNotify, contentNotify);
  }

  function updateMentorNotify() {
    let contentNotify;
    let typeNotify;
    if (updateMentor.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao atualizar cadastro do líder. Tente novamente :)';
    } else if (updateMentor.data) {
      typeNotify = 'success';
      contentNotify = `Líder atualizado(a)`;
      dispatch(updateMentorReset());
      dispatchDataForTable(valuePagination);
    }
    showNotify(typeNotify, contentNotify);
  }

  function deleteMentorNotify() {
    let contentNotify;
    let typeNotify;
    if (deleteMentor.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao excluir cadastro do líder. Tente novamente :)';
    } else if (deleteMentor.deleteSuccess) {
      typeNotify = 'success';
      contentNotify = 'Líder excluído(a)';
      dispatch(deleteMentorReset());
      dispatchDataForTable(valuePagination);
    }
    showNotify(typeNotify, contentNotify);
  }

  return (
    <>
      {(user || createUserError) && createMentorNotify()}
      {(updateMentor.data || updateMentor.hasError) && updateMentorNotify()}
      {(deleteMentor.deleteSuccess || deleteMentor.hasError) && deleteMentorNotify()}

      <div className="row mb-2">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Líderes</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center mb-2">
          <SearchAndFilter
            haveFilter={false}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            inputPlaceholder="Pesquisar por nome..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        <div className="col-auto">
          <Button inputFilled handleClick={openModalToRegisterMentor}>
            + Adicionar líder
          </Button>
        </div>
      </div>

      <Table
        titles={Titles}
        dataOfTable={dataOfTable}
        keysOfObjectsData={listOfKeys}
        dataKey="userId"
        notColapse
        notColapseFunction={openModalToDelete}
        editButton={item => _setDataToEdit(item)}
        functionForPagination={setValuePagination}
        itemsPerPage={itemsPerPage}
        shouldResetPagination={shouldResetPagination}
      />
    </>
  );
}

export default withRouter(Mentors);
