import React from 'react';
import './Loading.scss';

import icLoading from '../../assets/images/ic_loading.svg';

const Loading = () => (
  <div className="w-100 containerSpin">
    <div className="spin">
      <img src={icLoading} alt="icone circular, movimentando-se em circulos." height="100px" />
    </div>
  </div>
);

export default Loading;
