import { Types } from '../types';

export function updateEventRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_EVENT_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateEventSuccess() {
  return {
    type: Types.UPDATE_EVENT_SUCCESS,
  };
}

export function updateEventFailure(errorMsg) {
  return {
    type: Types.UPDATE_EVENT_FAILURE,
    payload: errorMsg,
  };
}

export function updateEventResetState() {
  return {
    type: Types.UPDATE_EVENT_RESET_STATE,
  };
}
