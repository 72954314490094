export const Types = {
  DEFINE_CLASSES_SEARCH_PARAMS: '[classes]DEFINE_CLASSES_SEARCH_PARAMS',

  RETRIEVE_ALL_CLASSES_REQUEST: '[classes]RETRIEVE_ALL_CLASSES_REQUEST',
  RETRIEVE_ALL_CLASSES_SUCCESS: '[classes]RETRIEVE_ALL_CLASSES_SUCCESS',
  RETRIEVE_ALL_CLASSES_FAILURE: '[classes]RETRIEVE_ALL_CLASSES_FAILURE',

  RETRIEVE_CLASS_CONTENT_REQUEST: '[classes]RETRIEVE_CLASS_CONTENT_REQUEST',
  RETRIEVE_CLASS_CONTENT_SUCCESS: '[classes]RETRIEVE_CLASS_CONTENT_SUCCESS',
  RETRIEVE_CLASS_CONTENT_FAILURE: '[classes]RETRIEVE_CLASS_CONTENT_FAILURE',
  RETRIEVE_CLASS_CONTENT_RESET_STATE: '[classes]RETRIEVE_CLASS_CONTENT_RESET_STATE',

  CREATE_CLASS_REQUEST: '[classes]CREATE_CLASS_REQUEST',
  CREATE_CLASS_SUCCESS: '[classes]CREATE_CLASS_SUCCESS',
  CREATE_CLASS_FAILURE: '[classes]CREATE_CLASS_FAILURE',
  CREATE_CLASS_RESET_STATE: '[classes]CREATE_CLASS_RESET_STATE',

  UPDATE_CLASS_REQUEST: '[classes]UPDATE_CLASS_REQUEST',
  UPDATE_CLASS_SUCCESS: '[classes]UPDATE_CLASS_SUCCESS',
  UPDATE_CLASS_FAILURE: '[classes]UPDATE_CLASS_FAILURE',
  UPDATE_CLASS_RESET_STATE: '[classes]UPDATE_CLASS_RESET_STATE',

  DELETE_CLASSE_REQUEST: '[classes]DELETE_CLASSE_REQUEST',
  DELETE_CLASSE_SUCCESS: '[classes]DELETE_CLASSE_SUCCESS',
  DELETE_CLASSE_FAILURE: '[classes]DELETE_CLASSE_FAILURE',
  DELETE_CLASSE_RESET_STATE: '[classes]DELETE_CLASSE_RESET_STATE',
};
