import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from 'react-select';
import listOfState from '../../../utils/listOfState';
import { RetrieveCitiesSelectors, RetrieveCitiesIsLoading } from '../../../Redux/Cities/selectors';
import { retriveCitiesOfStateReducerRequest } from '../../../Redux/Cities/Retrieve/actions';

import { UnitssRetrieveAllSelectors } from '../../../Redux/Units/selectors';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';

import { createUnitsRequest } from '../../../Redux/Units/Create/actions';
import { updateUnitsRequest } from '../../../Redux/Units/Update/actions';
import { deleteUnitsRequest } from '../../../Redux/Units/Delete/actions';

import { ModalDispatch } from '../../../contexts/ModalContext';
import { BUTTON_CLASSES } from '../../../utils/enums';
function UnitssInfo({ idUnit, useForEdit, closeModal }) {
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();

  const [invalidFieldsError, setInvalidFieldsError] = useState('');

  const textButton = useForEdit ? 'Atualizar' : 'Cadastrar';

  const authToken = useSelector(AuthTokenSelector);
  const unitss = useSelector(UnitssRetrieveAllSelectors);

  const cities = useSelector(RetrieveCitiesSelectors);
  const citiesIsLoading = useSelector(RetrieveCitiesIsLoading);

  const validateFields = () => {
    if (!name) {
      setInvalidFieldsError('Erro! Informe o nome.');
      return false;
    }
    if (!state) {
      setInvalidFieldsError('Erro! Informe o estado.');
      return false;
    }
    if (!city) {
      setInvalidFieldsError('Erro! Informe a cidade.');
      return false;
    }

    setInvalidFieldsError('');
    return true;
  };

  function createUnits(e) {
    e.preventDefault();

    const areFieldsValid = validateFields();
    if (!areFieldsValid) return;

    const parameters = {
      data: { name, city: city.value, state: state.value },
      authToken,
    };
    dispatch(createUnitsRequest(parameters));

    closeModal();
  }

  function updateUnits(e) {
    e.preventDefault();

    const areFieldsValid = validateFields();
    if (!areFieldsValid) return;

    const parameters = {
      id: idUnit,
      data: { name, city: city.value, state: state.value },
      authToken,
    };
    dispatch(updateUnitsRequest(parameters));

    closeModal();
  }

  const finalDispatch = useForEdit ? updateUnits : createUnits;

  function startComponent() {
    if (useForEdit) {
      const { name: Name, state: State, city: City } = unitss.rows.find(val => val.id === idUnit);

      setName(Name);
      setState({ label: State, value: State });
      setCity({ label: City, value: City });
    }
  }

  function getCities() {
    if (state?.value) {
      dispatch(retriveCitiesOfStateReducerRequest(state.value, authToken));
    }
  }

  useEffect(() => {
    getCities();
  }, [state]);

  useEffect(() => {
    setInvalidFieldsError('');
  }, [name, state, city]);

  useEffect(() => {
    startComponent();
  }, []);

  return (
    <>
      <div className="mt-6 row" style={{ height: '29em' }}>
        <form onSubmit={finalDispatch} className="col-12">
          <div className="row">
            <div className="col-4 mr-4">
              <label htmlFor="name">Nome</label>
              <Input id="name" inputValue={name} setInputValue={setName} required maxLength={40} />
            </div>
          </div>
          <div className="col-4 mr-4" id="box-select">
            <div className="row">
              <div className="col-6">
                <label htmlFor="state">Estado</label>
                <br />
                <Select
                  options={listOfState.map(item => ({ label: item.name, value: item.initials }))}
                  value={state}
                  placeholder="Selecione..."
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                  onChange={value => {
                    setCity(null);
                    setState(value);
                  }}
                />
              </div>

              <div className="col-6">
                <label htmlFor="city">Cidade</label>
                <br />
                <Select
                  options={cities.map(item => ({ label: item, value: item }))}
                  value={city}
                  placeholder="Selecione..."
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                  isDisabled={citiesIsLoading}
                  onChange={value => setCity(value)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-1 mb-1">
            <span
              style={{
                color: 'red',
                margin: 'auto auto auto 0px',
              }}
            >
              {invalidFieldsError}
            </span>

            <Button classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`} type="button" handleClick={closeModal}>
              Cancelar
            </Button>

            <Button inputFilled type="submit" classes={`${BUTTON_CLASSES.NO_AWAIT} col-2`}>
              {textButton}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

UnitssInfo.propTypes = {
  idUnits: PropTypes.number,
  useForEdit: PropTypes.bool,
  closeModal: PropTypes.func,
};

UnitssInfo.defaultProps = {
  idUnits: 0,
  useForEdit: false,
  closeModal: () => {},
};

export default UnitssInfo;
