import { Types } from '../types';

export function defineEventsSearchParams({ search }) {
  return {
    type: Types.DEFINE_EVENTS_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveEventsRowsTotalOffsetRequest(offset = 0, authToken, search, limit, signal) {
  return {
    type: Types.RETRIEVE_ALL_EVENTS_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveEventsRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_EVENTS_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveEventsRowsTotalOffsetFailure(errorMessage) {
  return {
    type: Types.RETRIEVE_ALL_EVENTS_FAILURE,
    payload: errorMessage,
  };
}
