import { call, put } from 'redux-saga/effects';

import { getSubjects, getSubjectsByUnit } from '../../client/subjects';
import { retrieveAllSubjectsSuccess, retrieveAllSubjectsFailure } from '../../Redux/Subjects/Retrieve/actions';
import {
  retrieveSubjectsByUnitSuccess,
  retrieveSubjectsByUnitFailure,
} from '../../Redux/Subjects/RetrieveByUnit/actions';

export function* retrieveAllSubjects(action) {
  try {
    const { authToken } = action.payload;
    const request = yield call(getSubjects, authToken);
    yield put(retrieveAllSubjectsSuccess(request));
  } catch (err) {
    yield put(retrieveAllSubjectsFailure(err.message));
  }
}

export function* retrieveSubjectsByUnit(action) {
  try {
    const { siteId, authToken, offset, limit } = action.payload;
    const request = yield call(getSubjectsByUnit, siteId, authToken, offset, limit);
    yield put(retrieveSubjectsByUnitSuccess(request));
  } catch (err) {
    yield put(retrieveSubjectsByUnitFailure(err.message));
  }
}
