import { call, put } from 'redux-saga/effects';

import { getMentors, createMentors, updateMentor, deleteMentor } from '../../client/mentors';
import {
  retrieveMentorsRowsTotalOffsetSuccess,
  retrieveMentorsRowsTotalOffsetFailure,
} from '../../Redux/Mentor/Retrieve/actions';
import { createMentorSuccess, createMentorFailure } from '../../Redux/Mentor/Create/actions';
import { updateMentorSucess, updateMentorFailure } from '../../Redux/Mentor/Update/actions';
import { deleteMentorSuccess, deleteMentorFailure } from '../../Redux/Mentor/Delete/actions';

export function* retrieveMentorsRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getMentors, offParam, authToken, search, limit, signal);
    const { rows, total, offset } = response.data;
    yield put(retrieveMentorsRowsTotalOffsetSuccess({ rows, total, offset }));
  } catch (err) {
    yield put(retrieveMentorsRowsTotalOffsetFailure(err.message));
  }
}

export function* createMentorSaga(action) {
  try {
    const { data, authToken } = action.payload;
    const response = yield call(createMentors, data, authToken);
    yield put(createMentorSuccess({ user: response.data }));
  } catch (err) {
    yield put(createMentorFailure(err.response.data));
  }
}

export function* updateMentorSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    const response = yield call(updateMentor, id, data, authToken);
    yield put(updateMentorSucess(response));
  } catch (error) {
    yield put(updateMentorFailure(error.message));
  }
}

export function* deleteMentorSaga(action) {
  try {
    const { id, authToken } = action.payload;
    yield call(deleteMentor, id, authToken);
    yield put(deleteMentorSuccess());
  } catch (error) {
    yield put(deleteMentorFailure(error.message));
  }
}
