import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

import { DeleteModalStyles as Styles } from '../../../components/Modal';
import { ModalDispatch } from '../../../contexts/ModalContext';
import { getUnitRelations } from '../../../client/unitss';
import icAttetion from '../../../assets/images/ic_attetion.svg';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';
import { deleteUnitsFailure } from '../../../Redux/Units/Delete/actions';
import icLoader from '../../../assets/images/ic_loading.svg';

import { BUTTON_CLASSES } from '../../../utils/enums';

function ModalToDeleteUnits({ id, nameUnits, actionIfYes }) {
  const modalDispatch = ModalDispatch();
  const [countRelations, setCountRelations] = useState(0);

  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const [relations, setRelations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getInfo = async () => {
      const {
        data: { references },
      } = await getUnitRelations(authToken, id);

      setRelations(references);
      const totalRelations = references.classes + references.students + references.teachers + references.mentorsSites;
      setCountRelations(totalRelations);
      setIsLoading(false);
    };

    getInfo()
      .then(() => {})
      .catch(err => {
        setIsLoading(false);
        err.response && err.response.data
          ? dispatch(deleteUnitsFailure(err.response.data))
          : dispatch(deleteUnitsFailure(err.message));

        modalDispatch({ type: '' });
      });
  }, []);

  return (
    <Modal size="md" alignHorizontal="center" centralizeVertical>
      {isLoading ? (
        <img
          src={icLoader}
          alt="circulos rotacionando em 360 graus infinitamente"
          height="60px"
          className="d-flex justify-content-center w-100"
        />
      ) : (
        <div style={Styles.Container}>
          <div style={Styles.Header}>
            <img style={Styles.Image} src={icAttetion} alt="attention" />
            Cuidado!
          </div>
          {countRelations > 0 ? (
            <div>
              <div style={Styles.Text}>
                {'Tem certeza que deseja excluir a unidade '} <span style={Styles.TextBold}>{nameUnits}</span>{' '}
                {'e as seguintes informações relacionadas?'}
              </div>
              <div style={Styles.List}>
                {relations.classes > 0 && (
                  <div style={Styles.Item}>
                    {relations.classes} {relations.classes > 1 ? 'Aulas' : 'Aula'}
                  </div>
                )}
                {relations.students > 0 && (
                  <div style={Styles.Item}>
                    {relations.students} {relations.students > 1 ? 'Alunos' : 'Aluno'}
                  </div>
                )}
                {relations.teachers > 0 && (
                  <div style={Styles.Item}>
                    {relations.teachers} {relations.teachers > 1 ? 'Professores' : 'Professor'}
                  </div>
                )}
                {relations.mentorsSites > 0 && (
                  <div style={Styles.Item}>
                    {relations.mentorsSites} {relations.mentorsSites > 1 ? 'Líderes' : 'Líder'}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div style={Styles.Text}>
                {'Tem certeza que deseja excluir a unidade '} <span style={Styles.TextBold}>{nameUnits}</span> {'?'}
              </div>
            </div>
          )}
          <div className="mt-5 mb-4 d-flex justify-content-center">
            <div className="col-md-6">
              <Button
                inputFilled
                type="button"
                handleClick={() => modalDispatch({ type: '' })}
                classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
              >
                Não
              </Button>
            </div>
            <div className="col-md-6">
              <Button inputFilled type="button" handleClick={actionIfYes}>
                Sim
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

ModalToDeleteUnits.propTypes = {
  nameUnits: PropTypes.string.isRequired,
  actionIfYes: PropTypes.string.isRequired,
};

export default ModalToDeleteUnits;
