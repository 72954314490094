import React from 'react';
import proptypes from 'prop-types';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { TeachersInfo } from '../NewTeachersInfo';

function ModalToRegisterTeacher({ idTeacher, useForEdit }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl">
      <TeachersInfo closeModal={closeModal} useForEdit={useForEdit} idTeacher={idTeacher} />
    </Modal>
  );
}

ModalToRegisterTeacher.propTypes = {
  idTeacher: proptypes.number,
  useForEdit: proptypes.bool,
};

ModalToRegisterTeacher.defaultProps = {
  idTeacher: 0,
  useForEdit: false,
};

export default ModalToRegisterTeacher;
