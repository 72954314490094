export const Container = {
  backgroundColor: 'white',
  borderLeftColor: 'gray',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 10,
}

export const Section = {
  height: '100%',
  maxHeight: 800,
  width: '40%',
  maxWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 50,
  paddingLeft: 50,
}

export const HeaderClass = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 40,
}

export const BackButtom = {
  width: 20,
  height: 20,
  marginRight: 12,
  marginTop: 8,
}

export const ClassTitle = {
  color: '#000000',
  fontSize: 24,
  fontFamily: 'Rubik-Medium',
}

export const SubTitle = {
  color: '#484848',
  fontSize: 14,
  marginBottom: 30,
  fontFamily: 'Rubik-Medium',
}

export const Split = {
  height: 1,
  width: '60%',
  backgroundColor: '#EBEBEB',
  marginTop: 30,
  marginBottom: 30
}

export const Loading = {
  display: 'flex',
  width: '100%',
  marginTop:  100,
  marginBottom:  100,
  justifyContent: 'center',
  alignItems: 'center'}
