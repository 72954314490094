import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Api } from '../../../services/api';
import Button from '../../../components/Button';
import { showNotify } from '../../../utils/showNotify';
import { createClassRequest } from '../../../Redux/Classes/Create/actions';
import { updateClassRequest } from '../../../Redux/Classes/Update/actions';
import { ClassesCreateSelector, ClassesUpdateSelector } from '../../../Redux/Classes/selectors';
import { AuthRetrieveUserSelector, AuthTokenSelector } from '../../../Redux/Auth/selector';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import { Select } from '../../../components/Select';
import SelectNew from "../../../components/SelectNew";
import { getUrlMetadata, uploadImage } from '../../../client/utils';
import { getTeachers } from "../../../client/teachers";
import { getClassTypeSubjects } from '../../../client/macrothemess';
import { baseStyle, activeStyle, acceptStyle, rejectStyle, actionButtonsContainer } from './styles';
import { getClassInfo } from '../../../client/classes';
import { validateVideoUrl } from '../../../utils/validateVideoUrl';
import { BUTTON_CLASSES } from '../../../utils/enums';
import icLoadingWhite from '../../../assets/images/ic_loading_white.svg';

const limit = 'limit=9999';
const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&\/\/=]*)/gim;
const MAX_DESCRIPTION_LENGTH = 60;
const MAX_UPLOAD_SIZE = 3145728;
function ClassesInfo({ closeModal, initialData }) {
  const dispatch = useDispatch();
  const user = useSelector(AuthRetrieveUserSelector);
  const { isLoading: createClassesIsLoading } = useSelector(ClassesCreateSelector);
  const { isLoading: updateClassesIsLoading } = useSelector(ClassesUpdateSelector);
  // classtype
  const [classType, setClassType] = useState();

  // grades and workshops
  const [siteData, setSiteData] = useState([]);
  const [gradesData, setGradesData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [gradeId, setGradeId] = useState(null);
  const [siteId, setSiteId] = useState(null);

  const [url, setUrl] = useState('');
  const [urlOnStep2, setUrlOnStep2] = useState('');

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [subjectIds, setSubjectIds] = useState('');
  const [newThumbnail, setNewThumbnail] = useState();

  const [thumbnailDragNDropIsOpen, setThumbnailDragNDropIsOpen] = useState(false);

  const [step, setStep] = useState(0);

  const [invalidFieldsError, setInvalidFieldsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [teacher, setTeacher] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [teachersIsLoading, setTeachersIsLoading] = useState(true);
  const [teachersOffset, setTeacherOffset] = useState(0);
  const [teachersSearch, setTeachersSearch] = useState('');
  const [teachersTotal, setTeachersTotal] = useState(0);
  const previousTeachersSearch = usePrevious(teachersSearch);

  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    resetValues();
    return resetValues()
  }, []);

  const _getClassInfo = async () => {
    const { classTypeId, siteId } = await getClassInfo(initialData.id, authToken);
    if (classTypeId)
      setClassType(
        classTypeId === 1
          ? 'reforco' :
          classTypeId === 2
            ? 'oficina'
            : 'outros'
      );
    if (siteId) setSiteId(siteId);
  }

  const getAllTeachers = async (search='') => {
    let reqOffset = teachersOffset;
    let reqTotal = teachersTotal;

    if (search !== previousTeachersSearch) {  
      setTeachers([]);
      setTeachersSearch(search);
      setTeachersTotal(0);
      reqOffset = 0;
      reqTotal = 0;
    }

    if (search === previousTeachersSearch && teachersOffset > reqTotal) return;
    setTeachersIsLoading(true);

    const searchQuery = search && search.length > 0 ? `query=${search}` : '';

    const response = await getTeachers(reqOffset, authToken, searchQuery, 10);
    const data = await response.data.rows;
    setTeachersTotal(response.data.total);
    const mappedData = data.map((item) => {
      return { value: item.id, label: item.name };
    });

    if (search !== previousTeachersSearch) {
      setTeachers(mappedData);
    } else {
      setTeachers([...teachers, ...mappedData]);
    }

    setTeachersIsLoading(false);
    setTeacherOffset(reqOffset + 10);
  }

  useEffect(() => {
    if (user.role === 'admin') {
      getAllTeachers();
    }
  }, [user]);

  useEffect(() => {
    if (initialData) {
      if (user.role !== 'admin') setStep(1);
      setTitle(initialData.title);
      setDescription(initialData.contents[0].description);
      setThumbnail(initialData.contents[0].thumbnail);
      setUrl(initialData.contents[0].url);
      setSubjectIds(initialData.subjects[0].id);
      setTeacher({ value: initialData.teacher.userId, label: initialData.teacher.name });
    }
  }, [initialData]);

  const authToken = useSelector(AuthTokenSelector);

  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer '.concat(authToken),
    },
  };

  useEffect(() => {
    if (step === 0) {
      if (classType === 'reforco') {
        try {
          Api.get(`/grades?${limit}`, requestConfig).then(response => setGradesData(response.data.rows || []));
        } catch (err) {
          showNotify('error', 'Ocorreu um erro ao listar as grades');
        }
      }

      if (classType === 'oficina') {
        try {
          Api.get(`/sites?${limit}`, requestConfig).then(response => setSiteData(response.data.rows || []));
        } catch (err) {
          showNotify('error', 'Ocorreu um erro ao listar as unidades');
        }
      }

      if (classType === 'outros') {
        try {
          Api.get(`/classtypes?${limit}`, requestConfig).then(response => {
            const classTypesFound = response.data.rows;
            setSubjects(
              classTypesFound.filter(item => item.name !== 'Reforço Escolar' && item.name !== 'Oficinas') || []
            );
          });
        } catch (err) {
          showNotify('error', 'Ocorreu um erro ao listar as disciplinas');
        }
      }
    }
  }, [classType, step]);

  useEffect(() => {
    if (siteId && siteId !== 'selecione') {
      try {
        Api.get(`/subjects?${limit}&siteIds=${siteId}`, requestConfig).then(response =>
          setSubjects(response.data.rows || [])
        );
      } catch (err) {
        showNotify('error', 'Ocorreu um erro ao listar as disciplinas');
      }
    }
  }, [siteId]);

  useEffect(() => {
    if (gradeId && gradeId !== 'selecione') {
      try {
        Api.get(`/subjects?${limit}&gradeId=${gradeId}`, requestConfig).then(response =>
          setSubjects(response.data.rows || [])
        );
      } catch (err) {
        showNotify('error', 'Ocorreu um erro ao listar as disciplinas');
      }
    }
  }, [gradeId]);

  useEffect(() => {
    if (classType === 'outros') {
      setGradeId('selecione');
    }
  }, [classType]);

  const toggleThumbnailDropzone = () => {
    setThumbnailDragNDropIsOpen(!thumbnailDragNDropIsOpen);
  };

  const validateFields = () => {
    const teacherFieldError =
      user.role === 'admin' && Object.keys(teacher).length === 0;

    if (step === 0) {
      if (!initialData) {
        if (!classType || classType === 'selecione') return 'Selecione o tipo de aula';
        if (classType === 'reforco' && (gradeId === 'undefined' || gradeId === 'selecione')) return 'Selecione a série';
        if (classType === 'oficina' && (siteId === 'undefined' || siteId === 'selecione')) return 'Selecione a unidade';
        if (!subjectIds || subjectIds.length <= 0 || subjectIds[0] === 'selecione' || subjectIds === 'selecione')
          return 'Selecione a matéria/tema';
        if (teacherFieldError) return 'Selecione o professor';
        if (!url || !url.match(urlRegex) || !validateVideoUrl(url)) return 'Link Inválido';
      } else {
        if (teacherFieldError) return 'Selecione o professor';
      }
    }

    if (step === 1) {
      if (!title) return 'Preencha o campo de título';
      if (!description) return 'Preencha o campo de descrição';
      if (!thumbnail && !newThumbnail) return 'Adicione uma thumbnail';
    }

    return '';
  };

  useEffect(() => {
    if (!initialData) {
      if (classType === 'reforco' || classType === 'outros') {
        setSiteId(null);
        setSubjectIds();
      }
      if (classType === 'oficina' || classType === 'outros') {
        setGradeId(null);
        setSubjectIds();
      }
    }
  }, [classType]);

  useEffect(() => {
    if (!initialData) setSubjectIds([]);
  }, [gradeId, siteId]);

  const resetValues = () => {
    setTeacher({});
    setGradeId();
    setSiteId();
    setUrl();
    setGradesData([]);
    setSubjectIds();
    setUrl();
    setTitle();
    setThumbnail();
    setDescription();
    setStep(0);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    let thumbnailUrl = thumbnail;
    if (newThumbnail) {
      const imageToString = newThumbnail.toString();
      const image = imageToString.replace(/^data:image\/.*;base64,/, '');
      const mimeType = imageToString.substring('data:image/'.length, imageToString.indexOf(';base64'));

      const { data } = await uploadImage({ image, container: 'imagens-publicas', mimeType, authToken });
      thumbnailUrl = data.createdImageUrl;
    }

    let data = {
      title,
      contents: [
        {
          description,
          thumbnail: thumbnailUrl,
          type: 'youtube',
          url,
          isMain: 1,
        },
      ],
      subjectIds: [Number(subjectIds)],
      tagsId: [],
      classes: [],
    };

    if (!initialData) {
      data = {
        ...data,
        gradeId: gradeId ? Number(gradeId) : null,
        tagsId: [],
        classes: [],
      };
    }

    if (!initialData && classType === 'outros') {
      try {
        const { data: { rows: subject } } = await getClassTypeSubjects(subjectIds, authToken);
        data = { ...data, subjectIds: [Number(subject[0].id)]};
      } catch (err) {
        setSubmitError('Erro ao obter informações sobre o tema. Tente novamente!');
        return false;
      }
    }

    if (user.role === 'admin') {
      data = {
        ...data,
        teacherId: teacher.value,
      }
    }

    try {
      if (initialData) {
        dispatch(updateClassRequest({ id: initialData.id, data, authToken }));
      } else {
        dispatch(createClassRequest({ data, authToken }));
      }
    } catch (err) {}
    setSubmitError('');
  };

  const handleStepChange = async event => {
    event.preventDefault();
    const invalidFields = validateFields();

    if (invalidFields) {
      setInvalidFieldsError(invalidFields);
      return false;
    } else {
      setInvalidFieldsError('');
    }

    if (step === 1) {
      handleSubmit(event);
    } else if (step === 0) {
      if (!initialData) {
        setIsLoading(true);

        if (!urlOnStep2 || url !== urlOnStep2) {
          setUrlOnStep2(url);
          setNewThumbnail();

          try {
            const urlWithoutParams = url.split("&")[0];
            const { data } = await getUrlMetadata(urlWithoutParams);

            if (data['og:type'] !== 'video.other') {
              setInvalidFieldsError('O link não pertence a nenhum vídeo! Verifique o link e tente novamente!');
              setUrlOnStep2('');
              setIsLoading(false);
              return;
            }

            if (data) {
              setTitle(data.title);

              const dataDescription = data.description.substring(0, MAX_DESCRIPTION_LENGTH);

              setDescription(dataDescription);
              setThumbnail(data.image);

              setStep(step + 1);
            } else {
              setInvalidFieldsError('Não foi possível analisar o link! Verifique o link e tente novamente!');
              setUrlOnStep2('');
            }
          } catch (err) {
            setInvalidFieldsError('Não foi possível analisar o link! Verifique o link e tente novamente!');
            setUrlOnStep2('');
          }
        }
        setIsLoading(false);
      } else {
        await _getClassInfo();
        setStep(step + 1);
      }
    }
  };

  const submitErrorNotify = () => {
    let contentNotify;
    let typeNotify;

    typeNotify = 'error';
    contentNotify = submitError;

    showNotify(typeNotify, contentNotify);
  }

  const handleNewThumbnail = file => {
    if (!file || (file && file.length === 0)) return;
    setNewThumbnail(acceptedFiles);
    toggleThumbnailDropzone();

    const reader = new FileReader();
    const url = reader.readAsDataURL(file[0]);

    reader.onloadend = function (e) {
      setNewThumbnail(reader.result);
    };
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => (acceptedFiles && acceptedFiles.length > 0 ? handleNewThumbnail(acceptedFiles) : {}),
    minSize: 0,
    maxSize: MAX_UPLOAD_SIZE,
  });

  const dropdownStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      {submitError && submitErrorNotify()}

      <h2 style={{ color: 'black', padding: '0 0 20px 4px', fontWeight: 'bold' }}>
        {initialData ? 'Editar Aula' : 'Adicionar Aula'}
      </h2>

      {step === 0 && (
        <>
          <div className={user.role === 'admin' ? "col-12 my-2 row align-items-start" : "my-2 row align-items-start"} style={{height: '20em'}}>
            {(user.role !== 'admin' || (user.role === 'admin' && !initialData)) &&
              <div className={user.role === 'admin' ? "col-8 my-2 row align-items-start" : "col-12 my-2 row align-items-start"}>
                <div className="col-4">
                  <label htmlFor="classType">Tipo de aula</label>
                  <Select valueOfSelect={classType} setValueOfSelect={setClassType}>
                    <option selected value="selecione">
                      Tipo da aula
                </option>
                    <option value="reforco">Reforço Escolar</option>
                    <option value="oficina">Oficina</option>
                    <option value="outros">Outros Temas</option>
                  </Select>
                </div>

                <div className="col-4">
                  <label htmlFor="gradeId">
                    {classType === 'oficina' ? 'Unidade' : classType === 'outros' ? 'Não aplicável' : 'Série'}
                  </label>
                  <Select
                    multiple
                    valueOfSelect={classType === 'reforco' ? gradeId : siteId}
                    setValueOfSelect={classType === 'reforco' ? setGradeId : setSiteId}
                    deactivate={
                      classType === 'reforco' ? gradesData.length <= 0 : classType === 'oficina' ? siteData.length <= 0 : true
                    }
                  >
                    <option selected value="selecione">
                      {classType === 'oficina' ? 'Unidade' : classType === 'outros' ? 'Não aplicável' : 'Série'}
                    </option>
                    {classType === 'reforco'
                      ? gradesData.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))
                      : siteData.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </Select>
                </div>

                <div className="col-4">
                  <label htmlFor="subjectIds">O que deseja ensinar</label>
                  <Select
                    multiple
                    valueOfSelect={subjectIds}
                    setValueOfSelect={setSubjectIds}
                    deactivate={
                      subjects.length <= 0 ||
                      !classType ||
                      classType === 'selecione' ||
                      (classType === 'reforco' && (!gradeId || gradeId === 'selecione')) ||
                      (classType === 'oficina' && (!siteId || siteId === 'selecione'))
                    }
                  >
                    <option selected value="selecione">
                      O que deseja ensinar
                </option>
                    {subjects.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className={user.role === 'admin' ? "col-8 mt-2" : "col-4 mt-2"}>
                  <label htmlFor="classType">Link</label>
                  <Input
                    id="url"
                    inputValue={url}
                    typeInput="url"
                    setInputValue={setUrl}
                    required
                    readOnly={!subjectIds || (subjectIds && subjectIds.length <= 0)}
                  />
                </div>

              </div>
            }
            {user.role === 'admin' &&
              <div className="col-4 mt-2" >
                <label htmlFor="classType">Professor</label>
                <SelectNew
                  value={teacher}
                  setSelectedValue={(id) => setTeacher(id)}
                  options={teachers}
                  isLoading={teachersIsLoading}
                  getOptions={(search) => getAllTeachers(search)}
                  isAsync={true}
                />
              </div>
            }
          </div>
        </>
      )}

      {step === 1 && (
        <>
          <div className="row">
            <div className="col-12">
              <label htmlFor="name">Título</label>
              <Input id="title" inputValue={title} setInputValue={setTitle} required />
            </div>
          </div>

          <div className="row">
            <div className="col-4 mt-2">
              <label htmlFor="thumbnail">Thumbnail</label>
              {!thumbnailDragNDropIsOpen && !newThumbnail && (
                <img src={thumbnail} style={{ maxHeight: '100%', maxWidth: '100%' }} />
              )}

              {!thumbnailDragNDropIsOpen && newThumbnail && (
                <img src={newThumbnail} style={{ maxHeight: '100%', maxWidth: '100%' }} />
              )}
              {thumbnailDragNDropIsOpen && (
                <div>
                  <div {...getRootProps({ style: dropdownStyle })}>
                    <input {...getInputProps()} />

                    <p style={{ margin: 'revert' }}>
                      {!isDragActive && 'Arraste a foto para aqui ou clique para selecionar. Tamanho máximo: 3 MB'}
                      {isDragActive && !isDragReject && 'Solte a imagem!'}
                      {isDragReject && 'Tipo de arquivo não permitido!'}
                      {fileRejections.length > 0 && fileRejections[0].errors && fileRejections[0].errors[0].code && (
                        <div className="text-danger mt-2">
                          {fileRejections[0].errors[0].code === 'file-too-large' && 'Erro! O arquivo é maior que 3 MB.'}
                          {fileRejections[0].errors[0].code === 'file-invalid-type' &&
                            'Erro! Tipo de arquivo não permitido.'}
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="col-8 mt-2">
              <label htmlFor="description">Descrição (max.: 60 caracteres)</label>
              <TextArea
                id="description"
                inputValue={description}
                resizable={false}
                setInputValue={setDescription}
                maxLength={60}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4 mt-2">
              {!thumbnailDragNDropIsOpen && (
                <Button classes="--noAwait mr-3 mb-2" type="button" handleClick={toggleThumbnailDropzone}>
                  Alterar thumbnail
                </Button>
              )}

              {thumbnailDragNDropIsOpen && (
                <div className="row" style={actionButtonsContainer}>
                  <Button classes="--grey col-6" type="button" handleClick={toggleThumbnailDropzone}>
                    Cancelar
                  </Button>
                  <Button classes="--noAwait col-6" type="button" handleClick={toggleThumbnailDropzone}>
                    Salvar
                  </Button>
                </div>
              )}

              <span>
                <strong>Professor: </strong>
                {user.role === 'admin' ? teacher.label : user.name}
                <br />
              </span>
              {classType === 'oficina' && (
                <span>
                  <strong>Unidade: </strong>
                  {siteData.find(item => item.id === Number(siteId))?.name}
                  <br />
                </span>
              )}
              <span>
                <strong>Tipo: </strong>
                {classType === 'reforco' && 'Reforço Escolar'}
                {classType === 'oficina' && 'Oficina'}
                {classType === 'outros' && 'Outros Temas'}
              </span>
            </div>
          </div>
        </>
      )}

      <div className="row justify-content-end mt-5 mr-0">
        {invalidFieldsError ? (
          <span style={{ color: 'red', marginLeft: '15px', marginRight: 'auto' }}>Erro: {invalidFieldsError}</span>
        ) : (
          ''
        )}

        {step === 0 && (
          <Button
            classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`}
            type="button"
            handleClick={() => {
              resetValues();
              closeModal();
            }}
          >
            Cancelar
          </Button>
        )}
        {step > 0 && initialData && user.role === 'admin' && (
          <Button
            classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`}
            type="button"
            handleClick={() => setStep(step - 1)}
          >
            Voltar
          </Button>
        )}
        <Button
          classes={isLoading || teachersIsLoading || createClassesIsLoading || updateClassesIsLoading ? '--Await col-2' : '--noAwait col-2'}
          type="button"
          handleClick={event => handleStepChange(event)}
          disabled={teachersIsLoading || createClassesIsLoading || updateClassesIsLoading}
        >
          {isLoading || teachersIsLoading || createClassesIsLoading || updateClassesIsLoading ? 
            <img src={icLoadingWhite} alt="icone circular, movimentando-se em circulos." height="20px" /> :
            step === 0 ? 'Continuar' : initialData ? 'Atualizar' : 'Adicionar'
          }
        </Button>
      </div>
    </>
  );
}

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

ClassesInfo.defaultProps = {
  initialData: null,
  closeModal: () => {},
};

ClassesInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.object,
  closeModal: PropTypes.func,
};

export default ClassesInfo;
