import { Types } from '../types';

const initialState = {
  success: false,
  isLoading: false,
  hasError: false,
  errorMsg: '',
};

export function resetPasswordReducer(state = initialState, action) {
  const { data } = action.payload || state;
  switch (action.type) {
    case Types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Types.RESET_PASSWORD_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        errorMsg: '',
        success: true,
      };
    case Types.RESET_PASSWORD_FAILURE:
      return {
        success: false,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.RESET_PASSWORD_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
