import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import './SideBar.scss';

import icDashboard from '../../assets/images/ic_dashboard.svg';
import icTeacher from '../../assets/images/ic_professor.svg';
import icStudent from '../../assets/images/ic_person.svg';
import icClass from '../../assets/images/ic_aulas.svg';
import icEvents from '../../assets/images/ic_conteudos.svg';
import icMentorsAndAdmins from '../../assets/images/ic_admin.svg';
import icMacrothemessAndAdmins from '../../assets/images/ic_macrotheme.svg';
import icUnits from '../../assets/images/ic_units.svg';
import icWorkshops from '../../assets/images/ic_workshop.svg';

import { AuthRetrieveUserSelector } from '../../Redux/Auth/selector';
import { ADMIN_ROUTES, MENTOR_ROUTES, TEACHER_ROUTES } from '../../utils/enums';

function SideBar() {
  const user = useSelector(AuthRetrieveUserSelector);
  const { pathname } = useLocation();

  const sideBarItems = [
    {
      icon: icDashboard,
      text: 'Dashboard',
      route: 'dashboard',
      isActive: true,
    },
    {
      icon: icTeacher,
      text: 'Professores',
      route: 'professores',
      isActive: true,
    },
    {
      icon: icStudent,
      text: 'Alunos',
      route: 'alunos',
      isActive: true,
    },
    {
      icon: icClass,
      text: 'Aulas',
      route: 'aulas',
      isActive: true,
    },
    {
      icon: icEvents,
      text: 'Eventos',
      route: 'eventos',
      isActive: true,
    },
    {
      icon: icMentorsAndAdmins,
      text: 'Líderes',
      route: 'mentores',
      isActive: true,
    },
    {
      icon: icMentorsAndAdmins,
      text: 'Admin',
      route: 'admins',
      isActive: true,
    },
    {
      icon: icUnits,
      text: 'Unidades',
      route: 'unidades',
      isActive: true,
    },
    {
      icon: icWorkshops,
      text: 'Oficinas',
      route: 'oficinas',
      isActive: true,
    },
    {
      icon: icMacrothemessAndAdmins,
      text: 'Outros temas',
      route: 'artigos',
      isActive: true,
    },
  ];

  function getUserSidebarItems() {
    const items = sideBarItems
      .map(item => {
        if (user.role === 'admin' && item.route in ADMIN_ROUTES) return item;
        if (user.role === 'teacher' && item.route in TEACHER_ROUTES) return item;
        if (user.role === 'mentor' && item.route in MENTOR_ROUTES) return item;
        return null;
      })
      .filter(item => item !== null);

    return items.map(item => {
      const { icon, text, route, isActive } = item;
      const selected = checkRouteToMarkSideBar(route);
      const contentListItem = (
        <>
          <img src={icon} alt={text} className="mr-3" />
          {text}
        </>
      );
      return (
        <li
          key={text}
          className={`sideBar__list__item ${selected} ${!isActive ? 'sideBar__list__item--disabled' : ''} `}
        >
          {isActive ? (
            <Link to={route} title={text}>
              {contentListItem}
            </Link>
          ) : (
            <span>{contentListItem}</span>
          )}
        </li>
      );
    });
  }

  function checkRouteToMarkSideBar(route) {
    return pathname.includes(route) ? 'sideBar__list__item--selected' : '';
  }

  return (
    <div className="sideBar">
      <ul className="sideBar__list">{getUserSidebarItems()}</ul>
    </div>
  );
}

export default SideBar;
