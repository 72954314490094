import { Types } from '../types';

export function defineAdminSearchParams({ search }) {
  return {
    type: Types.DEFINE_ADMIN_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveAdminsRowsTotalOffsetRequest(offset = 0, authToken, search, limit, signal) {
  return {
    type: Types.RETRIEVE_ALL_ADMINS_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveAdminsRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_ADMINS_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveAdminsRowsTotalOffsetFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_ADMINS_FAILURE,
    payload: errorMsg,
  };
}
