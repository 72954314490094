import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FormLogin } from './FormLogin';
import { NotConnected } from './NotConnected';
import Loading from '../../components/Loading';
import AuthContainer from '../../components/AuthContainer';

import { signInRequest, signInResetState } from '../../Redux/Auth/actions';

import { AuthSignInSelector, AuthHasErrorErrorMsgSelector } from '../../Redux/Auth/selector';

function Login() {
  const dispatch = useDispatch();
  const isSignIn = useSelector(AuthSignInSelector);
  const { hasError, errorMsg, errorType } = useSelector(AuthHasErrorErrorMsgSelector);

  useEffect(() => {
    dispatch(signInResetState());
  }, []);

  function handleClick(username, password, rememberLogin) {
    dispatch(signInRequest(username, password, rememberLogin));
  }

  return (
    <>
      {isSignIn && <Loading />}
      <AuthContainer>
        {hasError && errorMsg && errorMsg.startsWith('Falha ao conectar.') ? (
          <NotConnected alertComponent={errorMsg} />
        ) : (
          <FormLogin onClick={handleClick} hasError={hasError} errorMsg={errorMsg} errorType={errorType} />
        )}
      </AuthContainer>
    </>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Login;
