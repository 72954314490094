import { ChangePasswordTypes } from './types';

export function changePasswordRequest({ username, currentPassword, newPassword}, authToken) {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_REQUEST,
    payload: { authToken, username, currentPassword, newPassword },
  };
}

export function changePasswordSuccess() {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_SUCCESS
  };
}

export function changePasswordFailure(errorMsg) {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_FAILURE,
    payload: errorMsg,
  };
}

export function changePasswordResetState() {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_RESET,
  };
}
