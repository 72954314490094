import { Types } from '../types';

export function deleteUnitsRequest({ id, authToken }) {
  return {
    type: Types.DELETE_UNITS_REQUEST,
    payload: { id, authToken },
  };
}

export function deleteUnitsSuccess() {
  return {
    type: Types.DELETE_UNITS_SUCCESS,
  };
}

export function deleteUnitsFailure(errorMessage) {
  return {
    type: Types.DELETE_UNITS_FAILURE,
    payload: errorMessage,
  };
}

export function deleteUnitsReset() {
  return {
    type: Types.DELETE_UNITS_RESET_STATE,
  };
}
