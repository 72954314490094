import { Types } from '../types';

export function updateWorkshopRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_WORKSHOP_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateWorkshopSucess(success) {
  return {
    type: Types.UPDATE_WORKSHOP_SUCCESS,
    payload: success,
  };
}

export function updateWorkshopFailure(errorMessage) {
  return {
    type: Types.UPDATE_WORKSHOP_FAILURE,
    payload: errorMessage,
  };
}

export function updateWorkshopReset() {
  return {
    type: Types.UPDATE_WORKSHOP_RESET_STATE,
  };
}
