import { call, put } from 'redux-saga/effects';

import { getWorkshops, createWorkshops, updateWorkshop, deleteWorkshop } from '../../client/workshops';
import {
  retrieveWorkshopsRowsTotalOffsetSuccess,
  retrieveWorkshopsRowsTotalOffsetFailure,
} from '../../Redux/Workshop/Retrieve/actions';
import { createWorkshopSuccess, createWorkshopFailure } from '../../Redux/Workshop/Create/actions';
import { updateWorkshopSucess, updateWorkshopFailure } from '../../Redux/Workshop/Update/actions';
import { deleteWorkshopSuccess, deleteWorkshopFailure } from '../../Redux/Workshop/Delete/actions';

export function* retrieveWorkshopsRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getWorkshops, offParam, authToken, search, limit, signal);
    const { rows, total, offset } = response.data;
    yield put(retrieveWorkshopsRowsTotalOffsetSuccess({ rows, total, offset }));
  } catch (err) {
    yield put(retrieveWorkshopsRowsTotalOffsetFailure(err.message));
  }
}

export function* createWorkshopSaga(action) {
  try {
    const { data, authToken } = action.payload;
    yield call(createWorkshops, data, authToken);
    yield put(createWorkshopSuccess());
  } catch (err) {
    err && err.response && err.response.data ?
      yield put(createWorkshopFailure(err.response.data)) :
      yield put(createWorkshopFailure(err.message))
  }
}

export function* updateWorkshopSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    yield call(updateWorkshop, id, data, authToken);
    yield put(updateWorkshopSucess(true));
  } catch (err) {
    err && err.response && err.response.data ?
      yield put(updateWorkshopFailure(err.response.data)) :
      yield put(updateWorkshopFailure(err.message))
  }
}

export function* deleteWorkshopSaga(action) {
  try {
    const { id, authToken } = action.payload;
    yield call(deleteWorkshop, id, authToken);
    yield put(deleteWorkshopSuccess());
  } catch (error) {
    yield put(deleteWorkshopFailure(error.message));
  }
}
