import { Types } from '../types';

export function createAdminRequest({ data, authToken }) {
  return {
    type: Types.CREATE_ADMIN_REQUEST,
    payload: { data, authToken },
  };
}

export function createAdminSuccess({ user }) {
  return {
    type: Types.CREATE_ADMIN_SUCCESS,
    payload: { user },
  };
}

export function createAdminFailure(errorMsg) {
  return {
    type: Types.CREATE_ADMIN_FAILURE,
    payload: errorMsg,
  };
}

export function createAdminResetState() {
  return {
    type: Types.CREATE_ADMIN_RESET_STATE,
  };
}
