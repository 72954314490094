import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/user';

function getProfilePicture(pictureName, authToken) {
  const finalRoute = `${route}/profilepicture/${pictureName}`;
  return Api.get(finalRoute, {
    headers: commonHeaders(authToken),
  });
};

export { getProfilePicture };