import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/mentors';

function getMentors(offset = 0, authToken, search, limit = 10, signal) {
  const finalRoute = search ? `${route}?${search}` : `${route}`;
  return Api.get(finalRoute, {
    params: {
      limit,
      offset,
    },
    headers: commonHeaders(authToken),
    signal,
  });
}

function createMentors(data, authToken) {
  return Api.post(`${route}`, data, {
    headers: commonHeaders(authToken),
  });
}

function updateMentor(id, data, authToken) {
  return Api.patch(`${route}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function deleteMentor(id, authToken) {
  return Api.delete(`${route}/${id}`, {
    headers: commonHeaders(authToken),
  });
}

export { getMentors, createMentors, updateMentor, deleteMentor };
