import React from 'react';
import PropTypes from 'prop-types';

import icLoader from '../../../assets/images/ic_loading.svg';

function LoadingDataTable({ totalColumns }) {
  return (
    <>
      <tr>
        <td colSpan={totalColumns}>
          <img
            src={icLoader}
            alt="circulos rotacionando em 360 graus infinitamente"
            height="60px"
            className="d-flex justify-content-center w-100"
          />
        </td>
      </tr>
    </>
  );
}

LoadingDataTable.propTypes = {
  totalColumns: PropTypes.number.isRequired,
};

export default LoadingDataTable;
