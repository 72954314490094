import { combineReducers } from 'redux';

import { createMacrothemesReducer } from './Create/reducer';
import { retrieveMacrothemessRowsTotalOffsetReducer } from './Retrieve/reducer';
import { updateMacrothemesReducer } from './Update/reducer';
import { deleteMacrothemesReducer } from './Delete/reducer';

const reducer = combineReducers({
  createMacrothemesReducer,
  retrieveMacrothemessRowsTotalOffsetReducer,
  updateMacrothemesReducer,
  deleteMacrothemesReducer,
});

export { reducer as crudMacrothemessReducers };
