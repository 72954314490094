import React from 'react';

import { BUTTON_CLASSES } from '../../utils/enums';
import Button from '../Button';
import Avatar from 'react-avatar-edit';
import './EditAvatar.scss';

function EditAvatar({avatar, onChange, onRemoveAvatar, onSaveAvatar, onCancel}) {
  return (
    <>
      <div className="main">
          <Avatar
            width={300}
            height={300}
            onCrop={onChange}
            onClose={onRemoveAvatar}
            src={avatar}
            label='Selecione uma imagem...'
          />
          <div 
            className="buttonsContainer" 
          >
            <Button 
              handleClick={onCancel}
              classes={BUTTON_CLASSES.GREY}
            >
              Cancelar
            </Button>
            <Button 
              handleClick={onSaveAvatar}
              classes={BUTTON_CLASSES.NO_AWAIT}
            >
              Salvar
            </Button>
          </div>
          
      </div>
      
    </>
  );
}

export default EditAvatar;
