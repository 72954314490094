import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/utils';

function getUrlMetadata(url, authToken) {
  const finalRoute = `${route}/url/metadata?url=${url}`;
  return Api.get(finalRoute, {
    headers: commonHeaders(authToken),
  });
};

function uploadImage({ image, container, mimeType, authToken }) {
  const finalRoute = `${route}/uploadImage`;
  return Api.post(finalRoute, { image, container, mimeType }, {
    headers: commonHeaders(authToken),
  });
}

export { getUrlMetadata, uploadImage };