import { Types } from '../types';

const initialState = {
  limit: 0,
  offset: 0,
  total: 0,
  rows: [],
  search: '',
  isLoading: false,
  hasError: false,
  errorMsg: '',
  signal: null
};

export function retrieveStudentsRowsTotalOffsetReducer(state = initialState, action) {
  const { rows, total, search, offset, signal } = action.payload || state;
  switch (action.type) {
    case Types.DEFINE_STUDENTS_SEARCH_PARAMS:
      return {
        ...state,
        search: search ?? state.search
      }
    case Types.RETRIEVE_ALL_STUDENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        signal
      };
    case Types.RETRIEVE_ALL_STUDENTS_SUCCESS:
      return {
        ...state,
        rows,
        total,
        offset,
        isLoading: false,
      };
    case Types.RETRIEVE_ALL_STUDENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
}
