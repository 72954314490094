import { ForgetPasswordTypes } from './types';

export function forgetPasswordRequest({ email }) {
  return {
    type: ForgetPasswordTypes.FORGET_PASSWORD_REQUEST,
    payload: { email },
  };
}

export function forgetPasswordSuccess() {
  return {
    type: ForgetPasswordTypes.FORGET_PASSWORD_SUCCESS
  };
}

export function forgetPasswordFailure(errorMsg) {
  return {
    type: ForgetPasswordTypes.FORGET_PASSWORD_FAILURE,
    payload: errorMsg,
  };
}

export function forgetPasswordResetState() {
  return {
    type: ForgetPasswordTypes.FORGET_PASSWORD_RESET,
  };
}
