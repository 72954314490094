import { combineReducers } from 'redux';

import { updateClassReducer } from './Update/reducer';
import { createClassReducer } from './Create/reducer';
import { retrieveClassesRowsTotalOffsetReducer } from './Retrieve/reducer';
import { retrieveClassContentReducer } from './RetrieveClassContent/reducer';
import { deleteClassReducer } from './Delete/reducers';

const reducers = combineReducers({
  retrieveClassesRowsTotalOffsetReducer,
  retrieveClassContentReducer,
  createClassReducer,
  updateClassReducer,
  deleteClassReducer,
});

export { reducers as CrudClassesReducers };
