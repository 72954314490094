import { createStore, combineReducers, compose, applyMiddleware } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../Saga/index';

import { authReducer } from './Auth';
import { forgetPasswordReducer } from './ForgetPassword';
import { changePasswordReducer } from './ChangePassword';
import { crudMentorsReducers } from './Mentor';
import { crudMacrothemessReducers } from './Macrothemes';
import { crudUnitssReducers } from './Units';
import { crudWorkshopsReducers } from './Workshop';
import { crudTeachersReducers } from './Teacher';
import { crudAdminsReducers } from './Admin';
import { crudCitiesReducer } from './Cities';
import { crudSubjects } from './Subjects';
import { crudDashboardReducers } from './Dashboard';
import { CrudClassesReducers } from './Classes';
import { CrudEventsReducers } from './Events';
import { crudSchoolsReducers } from './Schools';
import { CrudStudentsReducers } from './Students';

export const reducers = combineReducers({
  authReducer,
  forgetPasswordReducer,
  changePasswordReducer,
  crudMentorsReducers,
  crudMacrothemessReducers,
  crudUnitssReducers,
  crudWorkshopsReducers,
  crudAdminsReducers,
  crudTeachersReducers,
  crudCitiesReducer,
  crudSubjects,
  crudDashboardReducers,
  CrudClassesReducers,
  CrudEventsReducers,
  crudSchoolsReducers,
  CrudStudentsReducers,
});

const persistConfig = {
  storage,
  key: 'gerando_falcoes',
  whitelist: ['authReducer'],
};

export function createAppStore() {
  const sagaMiddleware = createSagaMiddleware();

  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  const persistedReducer = persistReducer(persistConfig, reducers);

  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
