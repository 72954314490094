import { createSelector } from 'reselect';

const MentorsSelectors = state => state.crudMentorsReducers;

export const MentorsRetrieveAllSelectors = createSelector(
  MentorsSelectors,
  retrieveMentorsRowsTotalOffsetReducer => retrieveMentorsRowsTotalOffsetReducer.retrieveMentorsRowsTotalOffsetReducer
);

export const MentorsSearchSelectors = createSelector(
  MentorsSelectors,
  mentorsSearch => mentorsSearch.retrieveMentorsRowsTotalOffsetReducer.search
);

export const MentorCreateSelectors = createSelector(
  MentorsSelectors,
  createMentorsReducers => createMentorsReducers.createMentorReducer
);

export const MentorsCreateHasErrorSelectors = createSelector(
  MentorCreateSelectors,
  createMentorsReducers => createMentorsReducers.hasError
);

export const MentorsCreateErrorMessage = createSelector(
  MentorCreateSelectors,
  createMentorsReducers => createMentorsReducers.errorMsg
);

export const MentorsCreateGetUserSelectors = createSelector(
  MentorCreateSelectors,
  createMentorsReducers => createMentorsReducers.user
);

export const MentorsCreateLoading = createSelector(
  MentorCreateSelectors,
  createMentorsReducers => createMentorsReducers.isLoading
);

export const MentorUpdateSelectors = createSelector(
  MentorsSelectors,
  updateMentors => updateMentors.updateMentorReducer
);

export const MentorUpdateLoading = createSelector(MentorUpdateSelectors, updateMentors => updateMentors.isLoading);

export const MentorDeleteSelectors = createSelector(MentorsSelectors, deleteMentor => deleteMentor.deleteMentorReducer);

export const MentorDeleteLoading = createSelector(MentorDeleteSelectors, deleteMentor => deleteMentor.isLoading);
