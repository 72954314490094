import { call, put } from 'redux-saga/effects';

import { getEvents, deleteEvent, getEventContentById, createEvent, updateEvent } from '../../client/events';

import {
  retrieveEventsRowsTotalOffsetSuccess,
  retrieveEventsRowsTotalOffsetFailure,
} from '../../Redux/Events/Retrieve/actions';

import {
  retrieveEventContentSuccess,
  retrieveEventContentfailure,
} from '../../Redux/Events/RetrieveEventContent/actions';

import { createEventSuccess, createEventFailure } from '../../Redux/Events/Create/actions';
import { deleteEventSuccess, deleteEventFailure } from '../../Redux/Events/Delete/actions';
import { updateEventSuccess, updateEventFailure } from '../../Redux/Events/Update/actions';

export function* createEventSaga(action) {
  try {
    const { data, authToken } = action.payload;
    yield call(createEvent, data, authToken);
    yield put(createEventSuccess());
  } catch (error) {
    error.response && error.response.data
      ? yield put(createEventFailure(error.response.data))
      : yield put(createEventFailure(error.message));
  }
}

export function* updateEventSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    yield call(updateEvent, id, data, authToken);
    yield put(updateEventSuccess());
  } catch (error) {
    error.response && error.response.data
      ? yield put(updateEventFailure(error.response.data))
      : yield put(updateEventFailure(error.message));
  }
}

export function* retrieveEventsRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getEvents, offParam, authToken, search, limit, signal);
    yield put(retrieveEventsRowsTotalOffsetSuccess(response.data));
  } catch (err) {
    yield put(retrieveEventsRowsTotalOffsetFailure(err.message));
  }
}

export function* retrieveEventContentSaga(action) {
  try {
    const { id, authToken } = action.payload;
    const response = yield call(getEventContentById, id, authToken);
    const { contents } = response.data.rows[0];
    yield put(retrieveEventContentSuccess(contents));
  } catch (err) {
    yield put(retrieveEventContentfailure(err.message));
  }
}

export function* deleteEventByidSaga(action) {
  try {
    const { id, authToken, moderatorPassword } = action.payload;
    yield call(deleteEvent, id, authToken, moderatorPassword);
    yield put(deleteEventSuccess());
  } catch (err) {
    err.response && err.response.data
    ? yield put(deleteEventFailure(err.response.data))
    : yield put(deleteEventFailure(err.message));
  }
}
