import { Types } from '../types';

export function createTeacherRequest({ data, authToken }) {
  return {
    type: Types.CREATE_TEACHER_REQUEST,
    payload: { data, authToken },
  };
}

export function createTeacherSuccess({ user }) {
  return {
    type: Types.CREATE_TEACHER_SUCCESS,
    payload: { user },
  };
}

export function createTeacherFailure(errorMsg) {
  return {
    type: Types.CREATE_TEACHER_FAILURE,
    payload: errorMsg,
  };
}

export function createTeacherResetState() {
  return {
    type: Types.CREATE_TEACHER_RESET_STATE,
  };
}
