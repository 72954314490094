import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const defaultValue = {
  isLoading: false,
};

const StateLoadingContext = createContext(defaultValue);
const DispatchLoadingContext = createContext();

function isLoading(state, action) {
  switch (action.type) {
    case 'open': {
      return {
        ...state.defaultValue,
        isLoading: true,
      };
    }
    default:
      return defaultValue;
  }
}

function LoadingProvider({ children }) {
  const [state, dispatch] = useReducer(isLoading, defaultValue);

  return (
    <StateLoadingContext.Provider value={state}>
      <DispatchLoadingContext.Provider value={dispatch}>{children}</DispatchLoadingContext.Provider>
    </StateLoadingContext.Provider>
  );
}

const LoadingState = () => useContext(StateLoadingContext);

const LoadingDispatch = () => useContext(DispatchLoadingContext);

LoadingProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { LoadingProvider, LoadingState, LoadingDispatch };
