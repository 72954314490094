import { createSelector } from 'reselect';

const ForgetPasswordSelectorState = state => state.forgetPasswordReducer;

export const ForgetPasswordHasErrorErrorMsgSelector = 
  createSelector(ForgetPasswordSelectorState, forgetPasswordReducer => ({
    hasError: forgetPasswordReducer.hasError,
    errorMsg: forgetPasswordReducer.errorMsg,
}));


export const ForgetPasswordSelectors = createSelector(
  ForgetPasswordSelectorState,
  forgetPassword => forgetPassword
);
