import { Types } from '../types';

export function createUnitsRequest({ data, authToken }) {
  return {
    type: Types.CREATE_UNITS_REQUEST,
    payload: { data, authToken },
  };
}

export function createUnitsSuccess() {
  return {
    type: Types.CREATE_UNITS_SUCCESS,
  };
}

export function createUnitsFailure(errorMsg) {
  return {
    type: Types.CREATE_UNITS_FAILURE,
    payload: errorMsg,
  };
}

export function createUnitsResetState() {
  return {
    type: Types.CREATE_UNITS_RESET_STATE,
  };
}
