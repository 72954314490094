export const colourStyles = {
  container: () => ({ width: '94%' }),
  control: styles => ({
    ...styles,
    backgroundColor: 'rgba(69, 88, 98, 0.1)',
    border: 'none',
    fontFamily: 'Rubik-Regular',
    fontSize: 14,
    lineHeight: 1.5,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected || isFocused ? '#00bdf7' : 'rgba(69, 88, 98, 0.1)',
      color: isSelected || isFocused ? '#fff' : '#000',
      fontFamily: 'Rubik-Regular',
      fontSize: 14,
      lineHeight: 1.5,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
    };
  },
  dropdownIndicator: styles => ({
    ...styles,
    color: 'black',
  }),
};

export const container = {
  display: 'flex',
};

export const loadingStyle = {
  margin: 'auto',
};
