import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/classes';
const getContentsRoute = `${route}/contents`;

function getClasses(offset = 0, authToken, search, limit, signal) {
  const finalRoute = search ? `${getContentsRoute}?${search}` : getContentsRoute;
  return Api.get(finalRoute, {
    params: {
      limit,
      offset,
    },
    headers: commonHeaders(authToken),
    signal,
  });
}

async function getClassInfo(classIds, authToken) {
  const response = await Api.get(`${route}/${classIds}`, {
    headers: commonHeaders(authToken),
  });

  const classTypeId = await response?.data?.subjects &&
    response?.data?.subjects?.length &&
    response.data.subjects[0].classTypeId;

  const siteId = await response?.data?.subjects &&
    response?.data?.subjects?.length &&
    response.data.subjects[0].siteId;

  return { classTypeId, siteId };
}

function getClassContentById(classid, authToken) {
  return Api.get(getContentsRoute, {
    params: { classIds: classid },
    headers: commonHeaders(authToken),
  });
}

function createClass(data, authToken) {
  return Api.post(route, data, {
    headers: commonHeaders(authToken),
  });
}

function updateClass(id, data, authToken) {
  return Api.patch(`${route}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function deleteClass(id, authToken) {
  return Api.delete(`${route}/${id}`, {
    headers: commonHeaders(authToken),
  });
}

export { getClasses, getClassInfo, getClassContentById, createClass, updateClass, deleteClass };