import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

import { ModalDispatch } from '../../../contexts/ModalContext';
import icAttetion from '../../../assets/images/ic_attetion.svg';
import { Styles } from './styles';
import { BUTTON_CLASSES } from '../../../utils/enums';
import { deleteMacrothemesFailure } from '../../../Redux/Macrothemes/Delete/actions';
import { getMacrothemessReferences } from '../../../client/macrothemess';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';
import icLoader from '../../../assets/images/ic_loading.svg';

function ModalToDeleteMacrothemes({ id, nameMacrothemes, actionIfYes }) {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const [classQty, setClassQty] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const modalDispatch = ModalDispatch();

  useEffect(() => {
    const getClassQty = async () => {
      const {
        data: {
          references: { classes },
        },
      } = await getMacrothemessReferences(authToken, id);
      setClassQty(classes);
      setIsLoading(false);
    };

    getClassQty()
      .then(() => {})
      .catch(err => {
        setIsLoading(false);
        err.response && err.response.data
          ? dispatch(deleteMacrothemesFailure(err.response.data))
          : dispatch(deleteMacrothemesFailure(err.message));

        modalDispatch({ type: '' })
      });
  }, []);

  return (
    <Modal size="md" alignHorizontal="center" centralizeVertical>
      {isLoading ? (
        <img
          src={icLoader}
          alt="circulos rotacionando em 360 graus infinitamente"
          height="60px"
          className="d-flex justify-content-center w-100"
        />
      ) : (
        <>
          <div style={Styles.Header}>
            <img style={Styles.Image} src={icAttetion} alt="attention" />
            Cuidado!
          </div>
          <div style={Styles.Container}>
            {classQty > 0 ? (
              <>
                <div style={Styles.Text}>
                  {'Tem certeza que deseja excluir o tema '} <span style={Styles.TextBold}>{nameMacrothemes}</span>{' '}
                  {'e as seguintes informações relacionadas?'}
                </div>

                <div style={Styles.List}>
                  <div style={Styles.Item}>
                    {classQty} {classQty > 1 ? 'Aulas' : 'Aula'}
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div style={Styles.Text}>
                  {'Tem certeza que deseja excluir o tema '} <span style={Styles.TextBold}>{nameMacrothemes}</span>{' '}
                  {'?'}
                </div>
              </div>
            )}
            <div className="mt-5 mb-4 d-flex justify-content-center">
              <div className="col-md-4">
                <Button
                  inputFilled
                  type="button"
                  handleClick={() => modalDispatch({ type: '' })}
                  classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
                >
                  Não
                </Button>
              </div>
              <div className="col-md-4">
                <Button inputFilled type="button" handleClick={actionIfYes}>
                  Sim
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

ModalToDeleteMacrothemes.propTypes = {
  id: PropTypes.number.isRequired,
  nameMacrothemes: PropTypes.string.isRequired,
  actionIfYes: PropTypes.func.isRequired,
};

export default ModalToDeleteMacrothemes;
