import { Types } from '../types';

export function updateAdminRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_ADMIN_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateAdminSucess({ data }) {
  return {
    type: Types.UPDATE_ADMIN_SUCCESS,
    payload: { data },
  };
}

export function updateAdminFailure(errorMessage) {
  return {
    type: Types.UPDATE_ADMIN_FAILURE,
    payload: errorMessage,
  };
}

export function updateAdminReset() {
  return {
    type: Types.UPDATE_ADMIN_RESET_STATE,
  };
}
