import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from '../../../components/Select';
import Thumbnail from '../../../components/Thumbnail';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import EditAvatar from '../../../components/EditAvatar';

import listOfState from '../../../utils/listOfState';
import { maskToTelephone } from '../../../utils/maskToInputs';

import { MentorsRetrieveAllSelectors } from '../../../Redux/Mentor/selectors';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';

import { createMentorRequest } from '../../../Redux/Mentor/Create/actions';
import { updateMentorRequest } from '../../../Redux/Mentor/Update/actions';

import { getProfilePicture } from '../../../client/users';
import undefinedUserBase64 from '../../../assets/images/undefinedUserBase64';
import { UnitssRetrieveAllSelectors } from '../../../Redux/Units/selectors';
import { retrieveUnitssRowsTotalOffsetRequest } from '../../../Redux/Units/Retrieve/actions';
import { BUTTON_CLASSES } from '../../../utils/enums';

function MentorsInfo({ idMentor, useForEdit, closeModal }) {
  const dispatch = useDispatch();

  const [name, setName] = useState();
  const [cellphone, setCellphone] = useState();
  const [email, setEmail] = useState();
  const [countryState, setCountryState] = useState();
  const [city, setCity] = useState();
  const [workUnit, setWorkUnit] = useState();

  const [avatar, setAvatar] = useState(undefinedUserBase64);
  const [newAvatar, setNewAvatar] = useState();
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);

  const textButton = useForEdit ? 'Atualizar' : 'Cadastrar';

  const authToken = useSelector(AuthTokenSelector);

  const units = useSelector(UnitssRetrieveAllSelectors);

  function getParameters() {
    const parameters = {
      data: {
        avatar: avatar && avatar !== undefinedUserBase64 ? avatar.replace(/^data:image\/png;base64,/, '') : null,
        name,
        email,
        sitesIds: [typeof workUnit === 'string' ? parseInt(workUnit) : workUnit],
      },
      authToken,
    };

    if (useForEdit) {
      parameters.id = idMentor;
    }
    return parameters;
  }

  function createMentor(e) {
    e.preventDefault();
    const parameters = getParameters();
    dispatch(createMentorRequest(parameters));
    closeModal();
  }

  function updateMentor(e) {
    e.preventDefault();
    const parameters = getParameters();
    dispatch(updateMentorRequest(parameters));
    closeModal();
  }

  const finalDispatch = useForEdit ? updateMentor : createMentor;

  async function startComponent() {
    if (useForEdit) {
      const mentor = mentors.rows.find(val => val.userId === idMentor);
      const { name: Name, username, profilePicture, sitesIds } = mentor;
      setName(Name);
      setEmail(username);
      setWorkUnit(sitesIds[0]);
      const picture = await getProfilePicture(profilePicture, authToken);
      setAvatar(`data:image/png;base64,${picture.data}`);
    }
  }

  const mentors = useSelector(MentorsRetrieveAllSelectors);

  useEffect(() => {
    dispatch(retrieveUnitssRowsTotalOffsetRequest(0, authToken, '', 10000));
    startComponent();
  }, []);

  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  const onSaveAvatarImage = () => {
    setAvatar(newAvatar);
    setNewAvatar();
    setIsAvatarModalOpen(false);
  };

  const onAvatarImageChange = image => {
    setNewAvatar(image);
  };

  const onCancelAvatarEdit = () => {
    setIsAvatarModalOpen(false);
    setNewAvatar(avatar !== undefinedUserBase64 ? avatar : null);
  };

  const onRemoveAvatarImage = () => {
    setNewAvatar(null);
  };

  return (
    <>
      {!isAvatarModalOpen && <Thumbnail alt="Foto do Líder" height="104px" image={avatar} clickFn={openAvatarModal} />}

      {isAvatarModalOpen && (
        <EditAvatar
          avatar={newAvatar}
          onChange={onAvatarImageChange}
          onSaveAvatar={onSaveAvatarImage}
          onRemoveAvatar={onRemoveAvatarImage}
          onCancel={onCancelAvatarEdit}
        />
      )}

      <div className="mt-4 row">
        <form onSubmit={finalDispatch} className="col-12">
          <div className="row">
            <div className="col-4 mr-4">
              <label htmlFor="name">Nome</label>
              <Input id="name" inputValue={name} setInputValue={setName} required maxLength={40} />
            </div>

            <div className="col-4 mr-4">
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                readOnly={useForEdit}
                inputValue={email}
                typeInput="email"
                setInputValue={setEmail}
                required
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3">
              <label htmlFor="workUnit">
                Unidade em que trabalha <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                valueOfSelect={workUnit}
                setValueOfSelect={setWorkUnit}
                deactivate={!units.rows.length > 0}
                required
              >
                <option key="" value="" disabled hidden>
                  Escolha uma Unidade
                </option>
                {units.rows.length > 0 &&
                  units.rows.map(item => {
                    const { id, name: unitName } = item;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <option key={id} value={id}>
                        {unitName}
                      </option>
                    );
                  })}
              </Select>
            </div>

            <div className="col-4 d-none">
              <label htmlFor="cellphone">DDDa + Telefone</label>
              <Input
                id="cellphone"
                inputValue={maskToTelephone(cellphone)}
                typeInput="number"
                setInputValue={setCellphone}
                maxLength={15}
              />
            </div>

            <div className="col-4 d-none">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="state">Estado</label>
                  <br />
                  <Select valueOfSelect={countryState} setValueOfSelect={setCountryState}>
                    {listOfState.map(val => (
                      <option key={val.initials} value={val.initials}>
                        {val.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="col-6">
                  <label htmlFor="city">Cidade</label>
                  <br />
                  <Select valueOfSelect={city} setValueOfSelect={setCity}>
                    {listOfState.map(val => (
                      <option key={val.initials} value={val.initials}>
                        {val.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-1 mb-1">
            <Button classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`} type="button" handleClick={closeModal}>
              Cancelar
            </Button>

            <Button inputFilled type="submit" classes={`${BUTTON_CLASSES.NO_AWAIT} col-2`}>
              {textButton}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

MentorsInfo.propTypes = {
  idMentor: PropTypes.number,
  useForEdit: PropTypes.bool,
  closeModal: PropTypes.func,
};

MentorsInfo.defaultProps = {
  idMentor: 0,
  useForEdit: false,
  closeModal: () => {},
};

export default MentorsInfo;
