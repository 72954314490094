import React from 'react';
import PropTypes from 'prop-types';
import './TextArea.scss';

function TextArea({
  id,
  icon,
  alt,
  title,
  placeholder,
  inputValue,
  setInputValue,
  readOnly,
  required,
  resizable,
  maxLength,
  invalidValue,
  dataTest,
}) {
  const style = !resizable ? { resize: 'none' } : {}; 

  return (
    <div className="d-flex text-area-container">
      {icon && (
        <div className="mr-1">
          <img className="iconInputLogin styleCommonInputAndIcon" src={icon} alt={alt} title={title} />
        </div>
      )}
      <div className="w-100">
        <textarea
          id={id}
          style={style}
          className={`inputStyle styleCommonInputAndIcon ${invalidValue && 'inputStyle--invalid'}`}
          placeholder={placeholder}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          readOnly={readOnly}
          required={required}
          maxLength={maxLength}
          autoComplete="off"
          data-test={dataTest}
        />
      </div>
    </div>
  );
}

TextArea.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputValue: PropTypes.any,
  setInputValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  resizable: PropTypes.bool,
  maxLength: PropTypes.number,
  invalidValue: PropTypes.bool,
  dataTest: PropTypes.string,
};

TextArea.defaultProps = {
  id: '',
  icon: '',
  alt: '',
  title: '',
  readOnly: false,
  required: false,
  inputValue: '',
  setInputValue: () => {},
  placeholder: '',
  maxLength: 100,
  invalidValue: false,
  resizable: false,
  dataTest: '',
};

export default TextArea;
