import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ViewDataUser.scss';

import Button from '../../../Button';

import icLogout from '../../../../assets/images/ic_logout.svg';

function ViewDataUser({ dataOfUserToList, logoutOnClick, changeToEdit }) {
  return (
    <div className="bodyViewDataUser">
      <div className="d-flex flex-column mt-6">
        {dataOfUserToList.map(val => {
          const { label, value } = val;
          return (
            <div
              key={`${value}-${label}`}
              className="d-flex justify-content-between border-bottom border-dark bodyEditSelfUser__dividerLines bodyEditSelfUser__fontSize"
            >
              <div className="d-flex justify-content-start bodyViewDataUser__greyText">{label}</div>
              <div className="d-flex justify-content-end">{value}</div>
            </div>
          );
        })}
      </div>

      <div className="d-flex justify-content-end mt-4 bodyViewDataUser__buttonChange" onClick={changeToEdit}>
        Atualizar Senha
      </div>

      <div className="mt-5 d-flex justify-content-end bodyViewDataUser__buttonOut">
        <Link style={{ textDecoration: 'none' }} to="/login" onClick={logoutOnClick}>
          <img className="iconLogout" src={icLogout} alt="closed" />
          Sair da Conta
        </Link>
      </div>
    </div>
  );
}

ViewDataUser.propTypes = {
  dataOfUserToList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  logoutOnClick: PropTypes.func.isRequired,
  changeToEdit: PropTypes.func.isRequired,
};

export default ViewDataUser;
