import { combineReducers } from 'redux';

import { retrieveAllSubjectsReducers } from './Retrieve/reducer';
import { retrieveSubjectsByUnitReducers } from './RetrieveByUnit/reducer';

const reducer = combineReducers({
  retrieveAllSubjectsReducers,
  retrieveSubjectsByUnitReducers,
});

export { reducer as crudSubjects };
