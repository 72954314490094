import { Types } from '../types';

export function defineWorkshopsSearchParams({ search }) {
  return {
    type: Types.DEFINE_WORKSHOPS_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveWorkshopsRowsTotalOffsetRequest(offset = 0, authToken, search, limit, signal) {
  return {
    type: Types.RETRIEVE_ALL_WORKSHOPS_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveWorkshopsRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_WORKSHOPS_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveWorkshopsRowsTotalOffsetFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_WORKSHOPS_FAILURE,
    payload: errorMsg,
  };
}
