import { Types } from '../types';

export function retrieveSubjectsByUnitRequest(siteId, authToken, limit, offset) {
  return {
    type: Types.RETRIEVE_SUBJECTS_BY_UNIT_REQUEST,
    payload: { siteId, authToken, limit, offset },
  };
}

export function retrieveSubjectsByUnitSuccess({ data }) {
  return {
    type: Types.RETRIEVE_SUBJECTS_BY_UNIT_SUCCESS,
    payload: { data },
  };
}

export function retrieveSubjectsByUnitFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_SUBJECTS_BY_UNIT_FAILURE,
    payload: errorMsg,
  };
}

export function retrieveSubjectsByUnitReset() {
  return {
    type: Types.RETRIEVE_SUBJECTS_BY_UNIT_RESET,
  };
}
