import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';
import qs from 'qs';

const route = '/events';

function getEvents(offset = 0, authToken, search, limit, signal) {
  const params = { 
    limit,
    offset
  };
  const searchText = search.substring(search.indexOf('=') + 1);
  if(searchText) params.query = searchText;

  return Api.get(route, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
    headers: commonHeaders(authToken),
    signal,
  });
}

async function getEventInfo(eventId, authToken) {
  const response = await Api.get(`${route}/${eventId}`, {
    headers: commonHeaders(authToken),
  });

  const classTypeId =
    (await response?.data?.subjects) && response?.data?.subjects?.length && response.data.subjects[0].classTypeId;

  const siteId =
    (await response?.data?.subjects) && response?.data?.subjects?.length && response.data.subjects[0].siteId;

  return { classTypeId, siteId };
}

function getEventContentById(classid, authToken) {
  return Api.get(route, {
    params: { classIds: classid },
    headers: commonHeaders(authToken),
  });
}

function createEvent(data, authToken) {
  return Api.post(route, data, {
    headers: commonHeaders(authToken),
  });
}

function updateEvent(id, data, authToken) {
  return Api.patch(`${route}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function deleteEvent(id, authToken, moderatorPassword) {
  if(moderatorPassword) {
    return Api.delete(`${route}/${id}`, {
      headers: { ...commonHeaders(authToken), password: moderatorPassword },
    });
  }

  return Api.delete(`${route}/${id}`, {
    headers: { ...commonHeaders(authToken) },
  });
}

export { getEvents, getEventInfo, getEventContentById, createEvent, updateEvent, deleteEvent };
