import { Types } from '../types';

export function defineTeachersSearchParams({ search }) {
  return {
    type: Types.DEFINE_TEACHERS_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveTeachersRowsTotalOffsetRequest(offset = 0, authToken, search, limit, signal) {
  return {
    type: Types.RETRIEVE_ALL_TEACHERS_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveTeachersRowsTotalOffsetSuccess({ rows, total, offset, limit }) {
  return {
    type: Types.RETRIEVE_ALL_TEACHERS_SUCCESS,
    payload: { rows, total, offset, limit },
  };
}

export function retrieveTeachersRowsTotalOffsetFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_TEACHERS_FAILURE,
    payload: errorMsg,
  };
}
