import { Types } from '../types';

export function retrieveAllSubjectsRequest(authToken) {
  return {
    type: Types.RETRIEVE_ALL_SUBJECTS_REQUEST,
    payload: { authToken },
  };
}

export function retrieveAllSubjectsSuccess({ data }) {
  return {
    type: Types.RETRIEVE_ALL_SUBJECTS_SUCCESS,
    payload: { data },
  };
}

export function retrieveAllSubjectsFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_SUBJECTS_FAILURE,
    payload: errorMsg,
  };
}
