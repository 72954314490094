import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { AuthTokenSelector } from '../../../Redux/Auth/selector';
import { MacrothemessRetrieveAllSelectors } from '../../../Redux/Macrothemes/selectors';
import { createMacrothemesRequest } from '../../../Redux/Macrothemes/Create/actions';
import { updateMacrothemesRequest } from '../../../Redux/Macrothemes/Update/actions';

import { BUTTON_CLASSES } from '../../../utils/enums';

function MacrothemessInfo({ idMacrothemes, useForEdit, closeModal }) {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({ name: '' })

  const handleChangeForm = nameField =>
    value => {
      const removeStringIfIsNumber = nameField === 'name' ? value : value.replace(/\D/, '')
      const updatedFormState = { ...formState, [nameField]: removeStringIfIsNumber }
      setFormState(updatedFormState)
    }

  const textButton = useForEdit ? 'Atualizar' : 'Adicionar';

  const authToken = useSelector(AuthTokenSelector);
  function createMacrothemes(e) {
    e.preventDefault();
    const parameters = {
      data: formState,
      authToken,
    };
    dispatch(createMacrothemesRequest(parameters));
    closeModal();
  }

  function updateMacrothemes(e) {
    e.preventDefault();
    const parameters = {
      id: idMacrothemes,
      data: formState,
      authToken,
    };
    dispatch(updateMacrothemesRequest(parameters));
    closeModal();
  }

  const finalDispatch = useForEdit ? updateMacrothemes : createMacrothemes;

  function startComponent() {
    if (useForEdit) {
      const { name } = macrothemess.rows.find(val => val.id === idMacrothemes);
      setFormState({ name })
    }
  }

  useEffect(() => {
    startComponent();
  }, []);
  const macrothemess = useSelector(MacrothemessRetrieveAllSelectors);

  return (
    <>
      <div className="mt-4 row">
        <form onSubmit={finalDispatch} className="col-12">
          <div className="row">
            <div className="col-4 mr-4">
              <label htmlFor="name">Escolha um título</label>
              <Input
                id="name"
                inputValue={formState.name}
                setInputValue={handleChangeForm('name')}
                required
                maxLength={40}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-1 mb-1">
            <Button classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`} type="button" handleClick={closeModal}>
              Cancelar
            </Button>

            <Button inputFilled type="submit" classes={`${BUTTON_CLASSES.NO_AWAIT} col-2`}>
              {textButton}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

MacrothemessInfo.propTypes = {
  idMacrothemes: PropTypes.number,
  useForEdit: PropTypes.bool,
  closeModal: PropTypes.func,
};

MacrothemessInfo.defaultProps = {
  idMacrothemes: 0,
  useForEdit: false,
  closeModal: () => {},
};

export default MacrothemessInfo;
