export const Types = {
  DEFINE_MACROTHEMES_SEARCH_PARAMS: '[macrothemess]DEFINE_MACROTHEMES_SEARCH_PARAMS',
  CREATE_MACROTHEME_REQUEST: '[macrothemess]CREATE_MACROTHEME_REQUEST',
  CREATE_MACROTHEME_SUCCESS: '[macrothemess]CREATE_MACROTHEME_SUCCESS',
  CREATE_MACROTHEME_FAILURE: '[macrothemess]CREATE_MACROTHEME_FAILURE',
  CREATE_MACROTHEME_RESET_STATE: '[macrothemess]CREATE_MACROTHEME_RESET_STATE', // TODO - need refactory.
  RETRIEVE_ALL_MACROTHEMES_REQUEST: '[macrothemess]RETRIEVE_ALL_MACROTHEMES_REQUEST',
  RETRIEVE_ALL_MACROTHEMES_SUCCESS: '[macrothemess]RETRIEVE_ALL_MACROTHEMES_SUCCESS',
  RETRIEVE_ALL_MACROTHEMES_FAILURE: '[macrothemess]RETRIEVE_ALL_MACROTHEMES_FAILURE',
  UPDATE_MACROTHEME_REQUEST: '[macrothemess]UPDATE_MACROTHEME_REQUEST',
  UPDATE_MACROTHEME_SUCCESS: '[macrothemess]UPDATE_MACROTHEME_SUCCESS',
  UPDATE_MACROTHEME_FAILURE: '[macrothemess]UPDATE_MACROTHEME_FAILURE',
  UPDATE_MACROTHEME_RESET_STATE: '[macrothemess]UPDATE_MACROTHEME_RESET_STATE',
  DELETE_MACROTHEME_REQUEST: '[macrothemess]DELETE_MACROTHEME_REQUEST',
  DELETE_MACROTHEME_SUCCESS: '[macrothemess]DELETE_MACROTHEME_SUCCESS',
  DELETE_MACROTHEME_FAILURE: '[macrothemess]DELETE_MACROTHEME_FAILURE',
  DELETE_MACROTHEME_RESET_STATE: '[macrothemess]DELETE_MENTOR_RESET_STATE',
};
