import React from 'react';
// import './ModalToRegisterWorkshop.scss';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { WorkshopsInfo } from '../NewWorkshopsInfo';

function ModalToRegisterWorkshop({ idWorkshop, useForEdit }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl">
      <WorkshopsInfo closeModal={closeModal} useForEdit={useForEdit} idWorkshop={idWorkshop} />
    </Modal>
  );
}

export default ModalToRegisterWorkshop;
