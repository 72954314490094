import { Types } from '../types';

export function deleteStudentRequest({ id, authToken }) {
  return {
    type: Types.DELETE_STUDENT_REQUEST,
    payload: { id, authToken },
  };
}

export function deleteStudentSuccess() {
  return {
    type: Types.DELETE_STUDENT_SUCCESS,
  };
}

export function deleteStudentFailure(errorMessage) {
  return {
    type: Types.DELETE_STUDENT_FAILURE,
    payload: errorMessage,
  };
}

export function deleteStudentResetState() {
  return {
    type: Types.DELETE_STUDENT_RESET_STATE,
  };
}
