import React from 'react';
import PropTypes from 'prop-types';
import './SecondStep.scss';

import Button from '../../../components/Button';
import icEmailSent from '../../../assets/images/ic_sent_email.svg';

const SecondStep = ({ eventButton, resendEmailEvent }) => (
  <>
    <img src={icEmailSent} alt="icone de um envelope sendo lançado" />

    <h4 className="commonFontStyle commonFontStyle--title mt-4">Confira seu e-mail</h4>
    <div className="commonFontStyle commonFontStyle--content">
      <p>Enviamos um e-mail com um link para redefinir sua senha.</p>

    </div>

    <div className="mt-5">
      <Button inputFilled handleClick={eventButton}>
        Voltar para o login
      </Button>
    </div>

    <div className="text-center mt-4 mb-5">
      <span className="commonFontStyle commonFontStyle--content fontColor--grey">Não recebeu o e-mail?&nbsp;</span>
      <a onClick={resendEmailEvent} title="Enviar email novamente">
        <span className="commonFontStyle commonFontStyle--content fontColor--blue underline">Enviar novamente</span>
      </a>
    </div>
  </>
);

SecondStep.propTypes = {
  eventButton: PropTypes.func.isRequired,
  resendEmailEvent: PropTypes.func.isRequired,
};

export default SecondStep;
