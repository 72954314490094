import React from 'react';
import './TopBar.scss';

import WidgetUser from './WidgetUser';
import Logo from '../Logo';

function TopBar() {
  return (
    <div className="topBar d-flex">
      <div className="topBar__logo">
        <Logo alt="texto gerando falcões e falcão acima do texto" title="Logo Gerando Falcões" height={40} />
      </div>

      <div className="topBar__rightSide">
        <div className="topBar__rightSide__contentUser">
          <WidgetUser />
        </div>
      </div>
    </div>
  );
}

export default TopBar;
