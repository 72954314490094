import { DashboardTypes } from '../types';

export function retrieveAllDashboardRequest({ authToken, signal }) {
  return {
    type: DashboardTypes.RETRIEVE_ALL_DASHBOARD_REQUEST,
    payload: { authToken, signal },
  };
}

export function retrieveAllDashboardSuccess(dashboards) {
  return {
    type: DashboardTypes.RETRIEVE_ALL_DASHBOARD_SUCCESS,
    payload: dashboards,
  };
}

export function retrieveAllDashboardFailure(errorMsg) {
  return {
    type: DashboardTypes.RETRIEVE_ALL_DASHBOARD_FAILURE,
    payload: errorMsg,
  };
}
