import { all, takeLatest } from 'redux-saga/effects';

import { Types as MentorTypes } from '../Redux/Mentor/types';
import { retrieveMentorsRowsTotalOffsetSaga, createMentorSaga, updateMentorSaga, deleteMentorSaga } from './Mentor';

import { Types as MacrothemesTypes } from '../Redux/Macrothemes/types';
import {
  retrieveMacrothemessRowsTotalOffsetSaga,
  createMacrothemesSaga,
  updateMacrothemesSaga,
  deleteMacrothemesSaga,
} from './Macrothemes';

import { Types as UnitsTypes } from '../Redux/Units/types';
import {
  retrieveUnitssRowsTotalOffsetSaga,
  retrieveUnitsByCountryStateSaga,
  createUnitsSaga,
  updateUnitsSaga,
  deleteUnitsSaga,
} from './Units';

import { Types as WorkshopTypes } from '../Redux/Workshop/types';
import {
  retrieveWorkshopsRowsTotalOffsetSaga,
  createWorkshopSaga,
  updateWorkshopSaga,
  deleteWorkshopSaga,
} from './Workshop';

import { Types as AdminTypes } from '../Redux/Admin/types';
import { retrieveAdminsRowsTotalOffsetSaga, createAdminSaga, updateAdminSaga, updateSelfAdminSaga } from './Admin';

import { Types as TeacherTypes } from '../Redux/Teacher/types';
import {
  retrieveTeachersRowsTotalOffsetSaga,
  createTeacherSaga,
  updateTeacherSaga,
  deleteTeacherSaga,
} from './Teacher';

import { Types as ClassesTypes } from '../Redux/Classes/types';
import {
  createClassSaga,
  updateClassSaga,
  deleteClassByidSaga,
  retrieveClassesRowsTotalOffsetSaga,
  retrieveClassContentSaga,
} from './Classes';

import { Types as EventsTypes } from '../Redux/Events/types';
import {
  createEventSaga,
  updateEventSaga,
  deleteEventByidSaga,
  retrieveEventsRowsTotalOffsetSaga,
  retrieveEventContentSaga,
} from './Events';

import { Types as StudentsTypes } from '../Redux/Students/types';
import { retrieveStudentsRowsTotalOffsetSaga, updateStudentSaga, deleteStudentByidSaga, resetPasswordSaga } from './Students';

import { Types as CitiesTypes } from '../Redux/Cities/types';
import { retriveCitiesOfStateReducerSaga } from './Cities';

import { Types as SubjectsTypes } from '../Redux/Subjects/types';
import { retrieveAllSubjects, retrieveSubjectsByUnit } from './Subjects';

import { Types as SchoolsType } from '../Redux/Schools/types';
import { retrieveSchoolByCountryStateNCitySaga } from './Schools';

import { signInSaga } from './Auth';
import { AuthTypes } from '../Redux/Auth/types';

import { DashboardTypes } from '../Redux/Dashboard/types';
import { retrieveAllDashboardSaga } from './Dashboard';

import { ChangePasswordTypes } from '../Redux/ChangePassword/types';
import { changePasswordSaga } from './ChangePassword';

import { ForgetPasswordTypes } from '../Redux/ForgetPassword/types';
import { forgetPasswordSaga } from './ForgetPassword';

export function* rootSaga() {
  return yield all([
    takeLatest(MentorTypes.RETRIEVE_ALL_MENTORS_REQUEST, retrieveMentorsRowsTotalOffsetSaga),
    takeLatest(MentorTypes.UPDATE_MENTOR_REQUEST, updateMentorSaga),
    takeLatest(MentorTypes.DELETE_MENTOR_REQUEST, deleteMentorSaga),
    takeLatest(MentorTypes.CREATE_MENTOR_REQUEST, createMentorSaga),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signInSaga),

    takeLatest(WorkshopTypes.RETRIEVE_ALL_WORKSHOPS_REQUEST, retrieveWorkshopsRowsTotalOffsetSaga),
    takeLatest(WorkshopTypes.CREATE_WORKSHOP_REQUEST, createWorkshopSaga),
    takeLatest(WorkshopTypes.UPDATE_WORKSHOP_REQUEST, updateWorkshopSaga),
    takeLatest(WorkshopTypes.DELETE_WORKSHOP_REQUEST, deleteWorkshopSaga),

    takeLatest(AdminTypes.RETRIEVE_ALL_ADMINS_REQUEST, retrieveAdminsRowsTotalOffsetSaga),
    takeLatest(AdminTypes.CREATE_ADMIN_REQUEST, createAdminSaga),
    takeLatest(AdminTypes.UPDATE_ADMIN_REQUEST, updateAdminSaga),
    takeLatest(AdminTypes.UPDATE_SELF_REQUEST, updateSelfAdminSaga),

    takeLatest(TeacherTypes.RETRIEVE_ALL_TEACHERS_REQUEST, retrieveTeachersRowsTotalOffsetSaga),
    takeLatest(TeacherTypes.CREATE_TEACHER_REQUEST, createTeacherSaga),
    takeLatest(TeacherTypes.UPDATE_TEACHER_REQUEST, updateTeacherSaga),
    takeLatest(TeacherTypes.DELETE_TEACHER_REQUEST, deleteTeacherSaga),

    takeLatest(ClassesTypes.CREATE_CLASS_REQUEST, createClassSaga),
    takeLatest(ClassesTypes.RETRIEVE_ALL_CLASSES_REQUEST, retrieveClassesRowsTotalOffsetSaga),
    takeLatest(ClassesTypes.RETRIEVE_CLASS_CONTENT_REQUEST, retrieveClassContentSaga),
    takeLatest(ClassesTypes.UPDATE_CLASS_REQUEST, updateClassSaga),
    takeLatest(ClassesTypes.DELETE_CLASSE_REQUEST, deleteClassByidSaga),

    takeLatest(EventsTypes.CREATE_EVENT_REQUEST, createEventSaga),
    takeLatest(EventsTypes.RETRIEVE_ALL_EVENTS_REQUEST, retrieveEventsRowsTotalOffsetSaga),
    takeLatest(EventsTypes.RETRIEVE_EVENT_CONTENT_REQUEST, retrieveEventContentSaga),
    takeLatest(EventsTypes.UPDATE_EVENT_REQUEST, updateEventSaga),
    takeLatest(EventsTypes.DELETE_EVENT_REQUEST, deleteEventByidSaga),

    takeLatest(MacrothemesTypes.RETRIEVE_ALL_MACROTHEMES_REQUEST, retrieveMacrothemessRowsTotalOffsetSaga),
    takeLatest(MacrothemesTypes.CREATE_MACROTHEME_REQUEST, createMacrothemesSaga),
    takeLatest(MacrothemesTypes.UPDATE_MACROTHEME_REQUEST, updateMacrothemesSaga),
    takeLatest(MacrothemesTypes.DELETE_MACROTHEME_REQUEST, deleteMacrothemesSaga),

    takeLatest(UnitsTypes.RETRIEVE_ALL_UNITS_REQUEST, retrieveUnitssRowsTotalOffsetSaga),
    takeLatest(UnitsTypes.RETRIEVE_UNITS_BY_COUNTRY_STATES_REQUEST, retrieveUnitsByCountryStateSaga),
    takeLatest(UnitsTypes.CREATE_UNITS_REQUEST, createUnitsSaga),
    takeLatest(UnitsTypes.UPDATE_UNITS_REQUEST, updateUnitsSaga),
    takeLatest(UnitsTypes.DELETE_UNITS_REQUEST, deleteUnitsSaga),

    takeLatest(StudentsTypes.RETRIEVE_ALL_STUDENTS_REQUEST, retrieveStudentsRowsTotalOffsetSaga),
    takeLatest(StudentsTypes.DELETE_STUDENT_REQUEST, deleteStudentByidSaga),
    takeLatest(StudentsTypes.UPDATE_STUDENT_REQUEST, updateStudentSaga),
    takeLatest(StudentsTypes.RESET_PASSWORD_REQUEST, resetPasswordSaga),

    takeLatest(CitiesTypes.RETRIEVE_CITIES_OF_STATE_REQUEST, retriveCitiesOfStateReducerSaga),

    takeLatest(SubjectsTypes.RETRIEVE_ALL_SUBJECTS_REQUEST, retrieveAllSubjects),
    takeLatest(SubjectsTypes.RETRIEVE_SUBJECTS_BY_UNIT_REQUEST, retrieveSubjectsByUnit),
    takeLatest(DashboardTypes.RETRIEVE_ALL_DASHBOARD_REQUEST, retrieveAllDashboardSaga),

    takeLatest(SchoolsType.RETRIEVE_SCHOOLS_BY_STATE_N_CITY_REQUEST, retrieveSchoolByCountryStateNCitySaga),

    takeLatest(ChangePasswordTypes.CHANGE_PASSWORD_REQUEST, changePasswordSaga),
    takeLatest(ForgetPasswordTypes.FORGET_PASSWORD_REQUEST, forgetPasswordSaga),
  ]);
}
