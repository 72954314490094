import { createSelector } from 'reselect';

const TeachersSelectors = state => state.crudTeachersReducers;

export const TeachersRetrieveAllSelectors = createSelector(
  TeachersSelectors,
  retrieveTeachersRowsTotalOffsetReducer =>
    retrieveTeachersRowsTotalOffsetReducer.retrieveTeachersRowsTotalOffsetReducer
);

export const TeacherSearchSelectors = createSelector(
  TeachersSelectors,
  teachersSearch => teachersSearch.retrieveTeachersRowsTotalOffsetReducer.search
);

export const TeacherCreateSelectors = createSelector(
  TeachersSelectors,
  createTeachersReducers => createTeachersReducers.createTeacherReducer
);

export const TeachersCreateHasErrorSelectors = createSelector(
  TeacherCreateSelectors,
  createTeachersReducers => createTeachersReducers.hasError
);

export const TeachersCreateGetUserSelectors = createSelector(
  TeacherCreateSelectors,
  createTeachersReducers => createTeachersReducers.user
);

export const TeachersCreateLoading = createSelector(
  TeacherCreateSelectors,
  createTeachersReducers => createTeachersReducers.isLoading
);

export const TeacherUpdateSelectors = createSelector(
  TeachersSelectors,
  updateTeachers => updateTeachers.updateTeacherReducer
);

export const TeacherUpdateLoading = createSelector(
  TeachersSelectors,
  updateTeachers => updateTeachers.updateTeacherReducer.isLoading
);

export const TeacherDeleteSelectors = createSelector(
  TeachersSelectors,
  deleteTeacher => deleteTeacher.deleteTeacherReducer
);

export const TeacherDeleteLoading = createSelector(TeacherDeleteSelectors, deleteTeacher => deleteTeacher.isLoading);