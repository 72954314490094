import { call, put } from 'redux-saga/effects';

import { getMacrothemess, createMacrothemess, updateMacrothemes, deleteMacrothemes } from '../../client/macrothemess';
import {
  retrieveMacrothemessRowsTotalOffsetSuccess,
  retrieveMacrothemessRowsTotalOffsetFailure,
} from '../../Redux/Macrothemes/Retrieve/actions';
import { createMacrothemesSuccess, createMacrothemesFailure } from '../../Redux/Macrothemes/Create/actions';
import { updateMacrothemesSucess, updateMacrothemesFailure } from '../../Redux/Macrothemes/Update/actions';
import { deleteMacrothemesSuccess, deleteMacrothemesFailure } from '../../Redux/Macrothemes/Delete/actions';

const formatAgeToTable = (age) =>
  age ? `${age} Anos` : 'Sem faixa etária'

export function* retrieveMacrothemessRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getMacrothemess, offParam, authToken, search, limit, signal);
    const { rows, total, offset } = response.data;
    const formattedRows = rows.map(data => ({
      ...data,
      from_age_formatted: formatAgeToTable(data.from_age),
      to_age_formatted: formatAgeToTable(data.to_age)
    }))
    yield put(retrieveMacrothemessRowsTotalOffsetSuccess({ rows: formattedRows, total, offset }));
  } catch (err) {
    yield put(retrieveMacrothemessRowsTotalOffsetFailure(err.message));
  }
}

export function* createMacrothemesSaga(action) {
  try {
    const { data, authToken } = action.payload;
    const response = yield call(createMacrothemess, data, authToken);
    yield put(createMacrothemesSuccess({ user: response.status }));
  } catch (error) {
    error.response && error.response.data ?
      yield put(createMacrothemesFailure(error.response.data)) : 
      yield put(createMacrothemesFailure(error.message));
  }
}

export function* updateMacrothemesSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    yield call(updateMacrothemes, id, data, authToken);
    yield put(updateMacrothemesSucess());
  } catch (error) {
    error.response && error.response.data ?
      yield put(updateMacrothemesFailure(error.response.data)) : 
      yield put(updateMacrothemesFailure(error.message));
  }
}

export function* deleteMacrothemesSaga(action) {
  try {
    const { id, authToken } = action.payload;
    yield call(deleteMacrothemes, id, authToken);
    yield put(deleteMacrothemesSuccess());
  } catch (error) {
    error.response && error.response.data ?
      yield put(deleteMacrothemesFailure(error.response.data)) : 
      yield put(deleteMacrothemesFailure(error.message));
  }
}
