import { Types } from '../types';

export function updateTeacherRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_TEACHER_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateTeacherSucess({ data }) {
  return {
    type: Types.UPDATE_TEACHER_SUCCESS,
    payload: { data },
  };
}

export function updateTeacherFailure(errorMessage) {
  return {
    type: Types.UPDATE_TEACHER_FAILURE,
    payload: errorMessage,
  };
}

export function updateTeacherReset() {
  return {
    type: Types.UPDATE_TEACHER_RESET_STATE,
  };
}
