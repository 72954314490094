import { Types } from '../types';

export function retrieveEventContentRequest(id, authToken) {
  return {
    type: Types.RETRIEVE_EVENT_CONTENT_REQUEST,
    payload: { id, authToken },
  };
}

export function retrieveEventContentSuccess(data) {
  return {
    type: Types.RETRIEVE_EVENT_CONTENT_SUCCESS,
    payload: { data },
  };
}

export function retrieveEventContentfailure(errMessage) {
  return {
    type: Types.RETRIEVE_EVENT_CONTENT_FAILURE,
    payload: errMessage,
  };
}

export function retrieveEventContentReset() {
  return {
    type: Types.RETRIEVE_EVENT_CONTENT_RESET_STATE,
  };
}
