import { Types } from '../types';

export function deleteClassRequest(id, authToken) {
  return {
    type: Types.DELETE_CLASSE_REQUEST,
    payload: { id, authToken },
  };
}

export function deleteClassSuccess() {
  return {
    type: Types.DELETE_CLASSE_SUCCESS,
  };
}

export function deleteClassFailure(errorMessage) {
  return {
    type: Types.DELETE_CLASSE_FAILURE,
    payload: errorMessage,
  };
}

export function deleteClassResetState() {
  return {
    type: Types.DELETE_CLASSE_RESET_STATE,
  };
}
