import { AuthTypes } from './types';

export function signInRequest(username, password, rememberLogin) {
  return {
    type: AuthTypes.SIGN_IN_REQUEST,
    payload: { username, password, rememberLogin },
  };
}

export function signInSuccess(authToken, user, username, password, rememberLogin, avatar) {
  return {
    type: AuthTypes.SIGN_IN_SUCCESS,
    payload: { authToken, user, username, password, rememberLogin, avatar },
  };
}

export function signInFailure(error) {
  return {
    type: AuthTypes.SIGN_IN_FAILURE,
    payload: error,
  };
}

export function logout() {
  return {
    type: AuthTypes.LOGOUT,
  };
}

export function signInForbidden(forbiddenMsg) {
  return {
    type: AuthTypes.SIGN_IN_FORBIDDEN,
    payload: forbiddenMsg,
  };
}

export function signInResetState() {
  return {
    type: AuthTypes.SIGN_IN_RESET,
  };
}
