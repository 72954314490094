import { createSelector } from 'reselect';

const UnitssSelectors = state => state.crudUnitssReducers;

export const UnitssRetrieveAllSelectors = createSelector(
  UnitssSelectors,
  retrieveUnitssRowsTotalOffsetReducer => retrieveUnitssRowsTotalOffsetReducer.retrieveUnitssRowsTotalOffsetReducer
);

export const UnitsSearchSelectors = createSelector(
  UnitssSelectors,
  unitsSearch => unitsSearch.retrieveUnitssRowsTotalOffsetReducer.search
);

export const UnitssRetrieveByCountryStateSelectors = createSelector(
  UnitssSelectors,
  retrieveUnitsByCountryStatesReducer => retrieveUnitsByCountryStatesReducer.retrieveUnitsByCountryStatesReducer
);

export const UnitssRetrieveByCountryStateIsLoadingSelectors = createSelector(
  UnitssSelectors,
  retrieveUnitsByCountryStatesReducer => retrieveUnitsByCountryStatesReducer.retrieveUnitsByCountryStatesReducer.isLoading
);

export const UnitsCreateSelectors = createSelector(
  UnitssSelectors,
  createUnitssReducers => createUnitssReducers.createUnitsReducer
);

export const UnitssCreateHasErrorSelectors = createSelector(
  UnitsCreateSelectors,
  createUnitssReducers => createUnitssReducers.hasError
);

export const UnitssCreateErrorMessage = createSelector(
  UnitsCreateSelectors,
  createUnitssReducers => createUnitssReducers.errorMsg
);

export const UnitsSuccessSelector = createSelector(
  UnitsCreateSelectors,
  createUnitssReducers => createUnitssReducers.success
);

export const UnitssCreateLoading = createSelector(
  UnitsCreateSelectors,
  createUnitssReducers => createUnitssReducers.isLoading
);

export const UnitsUpdateSelectors = createSelector(UnitssSelectors, updateUnitss => updateUnitss.updateUnitsReducer);

export const UnitsUpdateLoading = createSelector(UnitsUpdateSelectors, updateUnitss => updateUnitss.isLoading);

export const UnitsDeleteSelectors = createSelector(UnitssSelectors, deleteUnits => deleteUnits.deleteUnitsReducer);

export const UnitsDeleteLoading = createSelector(UnitsDeleteSelectors, deleteUnits => deleteUnits.isLoading);
