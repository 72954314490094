import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FiEye, FiEyeOff } from 'react-icons/fi';
import Button from '../../../components/Button';
import { BUTTON_CLASSES } from '../../../utils/enums';

import './ResetPassword.scss';

import { ResetPasswordSelectors } from '../../../Redux/Students/selectors';
import { resetPasswordRequest } from '../../../Redux/Students/ResetPassword/actions';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';

import Input from '../../../components/Input';

function ResetPassword({ id, cancelUpdate, closeModal }) {

  const [studentPassword, setStudentPassword] = useState();
  const [studentPasswordVisible, setStudentPasswordVisible] = useState(false);
  const [studentPasswordError, setStudentPasswordError] = useState();

  const [mentorPassword, setMentorPassword] = useState();
  const [mentorPasswordVisible, setMentorPasswordVisible] = useState(false);
  const [mentorPasswordError, setMentorPasswordError] = useState();

  const authToken = useSelector(AuthTokenSelector);

  const resetPassword = useSelector(ResetPasswordSelectors).resetPasswordReducer

  const [inputFocus, setInputFocus] = useState({
    studentPassword: false,
    mentorPassword: false,
  });

  const finalDispatch = (e) => {
    e.preventDefault();
    if (validateFields()) {
      updatePassword({ studentPassword, mentorPassword })
      closeModal();
    }
  }

  const validateFields = useCallback(() => {

    setStudentPasswordError('');
    setMentorPasswordError('');

    if (!studentPassword) {
      setStudentPasswordError('Erro! Informe a nova senha do aluno!');
      return false;
    }
    if (studentPassword.length < 8) {
      setStudentPasswordError('Erro! A senha deve possuir ao menos 8 caracteres!');
      return false;
    }
    if (!mentorPassword) {
      setMentorPasswordError('Erro! Informe a sua senha!');
      return false;
    }
    if (mentorPassword.length < 8) {
      setMentorPasswordError('Erro! A senha deve possuir ao menos 8 caracteres!');
      return false;
    }

    return true;

  }, [studentPassword, mentorPassword]);

  const dispatch = useDispatch()

  function updatePassword({ studentPassword, mentorPassword }) {
    dispatch(resetPasswordRequest({ id, password: studentPassword, mentorPassword }, authToken));
  }

  return (
    <div className="col-12">
      <form onSubmit={finalDispatch}>
        <div
          className={`bodyEditSelfUser__dividerLines ${inputFocus.studentPassword &&
            'bodyEditSelfUser__dividerLines--inFocus'} `}
        >
          <label htmlFor="studentPassword" >
            Nova senha do aluno
          </label>
          <div className='inputContainer w-100 input-group' >
            <div className="d-flex justify-content-end">
              <Input
                id="studentPassword"
                inputValue={studentPassword}
                typeInput="password"
                setInputValue={setStudentPassword}
                required
              />
            </div>
          </div>

        </div>
        {studentPasswordError && <div className="resetPasswordErrorMsg">{studentPasswordError}</div>}

        <div
          className={`bodyEditSelfUser__dividerLines ${inputFocus.mentorPassword &&
            'bodyEditSelfUser__dividerLines--inFocus'} `}
        >
          <label htmlFor="mentorPassword">
            Sua Senha
          </label>
          <div className='inputContainer w-100 input-group' >
            <div className="d-flex justify-content-end">
              <Input
                id="mentorPassword"
                inputValue={mentorPassword}
                typeInput="password"
                setInputValue={setMentorPassword}
                required
              />
            </div>
          </div>
        </div>

        {mentorPasswordError && <div className="resetPasswordErrorMsg">{mentorPasswordError}</div>}

        <div className="row justify-content-end">
          <div className="col-3">
            <Button handleClick={cancelUpdate} type="button" classes={BUTTON_CLASSES.AWAIT_WHITE}>
              Voltar
                  </Button>
          </div>
          <div className="col-3">
            <Button
              type="submit"
              classes={resetPassword.isLoading ? BUTTON_CLASSES.AWAIT : BUTTON_CLASSES.NO_AWAIT}
              disabled={resetPassword.isLoading}
            >
              Atualizar
          </Button>
          </div>
        </div>
      </form>
    </div >
  )
}

export default ResetPassword;
