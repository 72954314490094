import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import AuthContainer from '../../components/AuthContainer';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import { changePasswordRequest } from '../../Redux/ChangePassword/actions';
import { ChangePasswordHasErrorErrorMsgSelector, ChangePasswordSelectors } from '../../Redux/ChangePassword/selector';

function ChangePassword() {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [alertMessage, setAlertMessage] = useState('');
  const { token } = useParams(); 
  const { hasError, errorMsg } = useSelector(ChangePasswordHasErrorErrorMsgSelector);
  const { success } = useSelector(ChangePasswordSelectors);

  useEffect(() => {
    setAlertMessage(errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    if(success) nextStep();
  }, [success]);

  const history = useHistory();

  const nextStep = () => {
    switch (step) {
      case 2:
        break;
      default:
        setStep(step + 1);
        break;
    }
  };

  const validateInputFields = ({ password, confirmPassword }) => {
    if (password && confirmPassword) 

    if (!password) {
      setAlertMessage("Digite uma senha !");
      return false;
    }

    if (password.length < 8) {
      setAlertMessage("Sua senha deve conter no minimo 8 caracteres !");
      return false;
    }

    if (password !== confirmPassword) {
      setAlertMessage("A confirmação da senha está incorreta !");
      return false;
    }

    return true;
  };

  const returnToLogin = () => history.push('/login');

  function handleSubmit({ password, confirmPassword }) {
    const isValid = validateInputFields({ password, confirmPassword });
  
    if(isValid) {
      setAlertMessage('');
      dispatch(changePasswordRequest({ token, password }));
      return;
    }

    return;
  }

  return (
    <AuthContainer>
      {step === 1 ? (
          <FirstStep eventButton={handleSubmit} errorMessage={alertMessage} />
        ) : (
          <SecondStep eventButton={returnToLogin} />
      )}
    </AuthContainer>
  )
}

export default ChangePassword;