import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

// limit on backend defaults to 10
const route = '/subjects?limit=1000';

async function getSubjects(authToken) {
  const { data } = await Api.get(route, {
    headers: commonHeaders(authToken),
  });

  return { data: data.rows };
}

async function getSubjectsByUnit(siteId, authToken, offset = 0, limit = 10) {
  const { data } = await Api.get(`/subjects?siteIds=${siteId}&limit=${limit}&offset=${offset}`, {
    headers: commonHeaders(authToken),
  });

  return { data: data.rows };
}

export { getSubjects, getSubjectsByUnit };
