import { Types } from '../types';

export function defineMacrothemesSearchParams({ search }) {
  return {
    type: Types.DEFINE_MACROTHEMES_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveMacrothemessRowsTotalOffsetRequest(offset = 0, authToken, search, limit, signal) {
  return {
    type: Types.RETRIEVE_ALL_MACROTHEMES_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveMacrothemessRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_MACROTHEMES_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveMacrothemessRowsTotalOffsetFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_MACROTHEMES_FAILURE,
    payload: errorMsg,
  };
}
