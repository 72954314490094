import React, { useEffect } from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AuthTokenSelector } from '../../Redux/Auth/selector';

import {
  DashboardRetrieveAllDashboardSelector,
  DashboardRetrieveAllIsLoadingSelector,
} from '../../Redux/Dashboard/selectors';

import { retrieveAllDashboardRequest } from '../../Redux/Dashboard/Retrieve/actions';

import { CardItem } from '../../components/CardItem';

import { LoadingContent } from '../Classes/ModalContentsClass/LoadingContent';

import { ResponsiveBar } from '@nivo/bar'

function Dashboard() {
  const {
    location: { search },
  } = useHistory();
  const abortDashboardRetrieveAllRequestController = new AbortController();

  const authToken = useSelector(AuthTokenSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveAllDashboardRequest({ authToken, signal: abortDashboardRetrieveAllRequestController.signal }));
    return () => abortDashboardRetrieveAllRequestController.abort();
  }, []);
  
  const dashboardData = useSelector(DashboardRetrieveAllDashboardSelector);
  const loading = useSelector(DashboardRetrieveAllIsLoadingSelector);

  const ClassAccessChart = React.memo(({data}) => {
    const chartData = data.map(i => ({name: i.name ,[i.name]: i.total}));
    const chartKeys = data.map(i => i.name);

    return (
      <ResponsiveBar
        data={chartData}
        keys={chartKeys}
        indexBy="name"
        margin={{ top: 50, right: 250, bottom: 50, left: 180 }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 2.2 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Quantidade de aulas',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '                                                                      ',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        enableGridX={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />  
    )
    })

  return (
    <>
      {loading ? (
        <LoadingContent />
      ) :
        <>
        <div className="containerCard mt-4">
          <CardItem 
            title={'Alunos cadastrados'} 
            value={
              dashboardData.totalStudents && 
              dashboardData.totalStudents.total
            } 
          /> 
          <CardItem 
            title={'Aulas disponíveis'} 
            value={
              dashboardData.totalClasses && 
              dashboardData.totalClasses.total
            } 
          />        
          <CardItem 
            title={'Novos vídeos semanais'} 
            value={
              dashboardData.newWeekVideos && 
              dashboardData.newWeekVideos.total
            } 
          /> 
           <CardItem 
            title={'Total de cliques dos usuários'} 
            value={
              dashboardData.totalClicks && 
              dashboardData.totalClicks.total
            } 
          /> 
        </div>
        
        <div className="chartContainer">
          <div className="content">
            <span className="content__title">Total de aulas por categoria</span>
              <ClassAccessChart 
                  data={dashboardData && dashboardData.classesTypeCount ? dashboardData.classesTypeCount : []} 
              />
          </div>
        </div>  
      </>
      }
    </>
  );
}

export default Dashboard;
