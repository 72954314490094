import { Types } from '../types';

export function updateStudentRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_STUDENT_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateStudentSucess({ data }) {
  return {
    type: Types.UPDATE_STUDENT_SUCCESS,
    payload: { data },
  };
}

export function updateStudentFailure(errorMessage) {
  return {
    type: Types.UPDATE_STUDENT_FAILURE,
    payload: errorMessage,
  };
}

export function updateStudentReset() {
  return {
    type: Types.UPDATE_STUDENT_RESET_STATE,
  };
}
