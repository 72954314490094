import { Types } from '../types';

export function createWorkshopRequest({ data, authToken }) {
  return {
    type: Types.CREATE_WORKSHOP_REQUEST,
    payload: { data, authToken },
  };
}

export function createWorkshopSuccess() {
  return {
    type: Types.CREATE_WORKSHOP_SUCCESS,
  };
}

export function createWorkshopFailure(errorMsg) {
  return {
    type: Types.CREATE_WORKSHOP_FAILURE,
    payload: errorMsg,
  };
}

export function createWorkshopResetState() {
  return {
    type: Types.CREATE_WORKSHOP_RESET_STATE,
  };
}
