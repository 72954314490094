export const Types = {
  DEFINE_WORKSHOPS_SEARCH_PARAMS: '[workshops]DEFINE_WORKSHOPS_SEARCH_PARAMS',
  CREATE_WORKSHOP_REQUEST: '[workshops]CREATE_WORKSHOP_REQUEST',
  CREATE_WORKSHOP_SUCCESS: '[workshops]CREATE_WORKSHOP_SUCCESS',
  CREATE_WORKSHOP_FAILURE: '[workshops]CREATE_WORKSHOP_FAILURE',
  CREATE_WORKSHOP_RESET_STATE: '[workshops]CREATE_WORKSHOP_RESET_STATE', // TODO - need refactory.
  RETRIEVE_ALL_WORKSHOPS_REQUEST: '[workshops]RETRIEVE_ALL_WORKSHOPS_REQUEST',
  RETRIEVE_ALL_WORKSHOPS_SUCCESS: '[workshops]RETRIEVE_ALL_WORKSHOPS_SUCCESS',
  RETRIEVE_ALL_WORKSHOPS_FAILURE: '[workshops]RETRIEVE_ALL_WORKSHOPS_FAILURE',
  UPDATE_WORKSHOP_REQUEST: '[workshops]UPDATE_WORKSHOP_REQUEST',
  UPDATE_WORKSHOP_SUCCESS: '[workshops]UPDATE_WORKSHOP_SUCCESS',
  UPDATE_WORKSHOP_FAILURE: '[workshops]UPDATE_WORKSHOP_FAILURE',
  UPDATE_WORKSHOP_RESET_STATE: '[workshops]UPDATE_WORKSHOP_RESET_STATE',
  DELETE_WORKSHOP_REQUEST: '[workshops]DELETE_WORKSHOP_REQUEST',
  DELETE_WORKSHOP_SUCCESS: '[workshops]DELETE_WORKSHOP_SUCCESS',
  DELETE_WORKSHOP_FAILURE: '[workshops]DELETE_WORKSHOP_FAILURE',
  DELETE_WORKSHOP_RESET_STATE: '[workshops]DELETE_WORKSHOP_RESET_STATE',
};
