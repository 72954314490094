import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FiEye, FiEyeOff } from 'react-icons/fi';
import Button from '../../../Button';
import icLoadingWhite from '../../../../assets/images/ic_loading_white.svg';
import { ChangePasswordSelectors } from '../../../../Redux/ChangePassword/selector';
import { BUTTON_CLASSES } from '../../../../utils/enums';

import './EditPassword.scss';

function EditPassword({ email, updatePassword, returnToData, submitError, cleanSubmitError }) {
  const [currentPassword, setCurrentPassword] = useState();
  const [password, setPassword] = useState();
  const [checkPassword, setCheckPassword] = useState();

  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isCheckPasswordVisible, setIsCheckPasswordVisible] = useState(false);

  const [inputFocus, setInputFocus] = useState({
    currentPassword: false,
    password: false,
    checkPassword: false,
  });

  const [fieldCurrentPasswordError, setFieldCurrentPasswordError] = useState('');
  const [fieldPasswordError, setFieldPasswordError] = useState('');
  const [fieldCheckPasswordError, setFieldCheckPasswordError] = useState('');

  const [submitErrorMessage, setSubmitErrorMessage] = useState('');

  const changePasswordSelectors = useSelector(ChangePasswordSelectors);

  const toggleIsCurrentPasswordVisible = () => setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  const toggleIsPasswordVisible = () => setIsPasswordVisible(!isPasswordVisible);
  const toggleIsCheckPasswordVisible = () => setIsCheckPasswordVisible(!isCheckPasswordVisible);


  const validateFields = useCallback(() => {
    if (!currentPassword) {
      setFieldCurrentPasswordError('Erro! Informe a senha atual!');
      return false;
    }
    if (currentPassword.length < 8) {
      setFieldCurrentPasswordError('Erro! A senha possui ao menos 8 caracteres!');
      return false;
    }
    if (!password) {
      setFieldPasswordError('Erro! Informe a nova senha!');
      return false;
    }
    if (currentPassword === password) {
      setFieldPasswordError('Erro! A nova senha deve ser diferente da senha anterior!');
      return false;
    }
    if (password.length < 8) {
      setFieldPasswordError('Erro! A nova senha deve ter ao menos 8 caracteres!');
      return false;
    }
    if (!checkPassword) {
      setFieldCheckPasswordError('Erro! Informe a confirmação de senha!');
      return false;
    }
    if (password !== checkPassword) {
      setFieldCheckPasswordError('Erro! A confirmação de senha deve ser igual a senha!');
      return false;
    }

    setFieldCurrentPasswordError('');
    setFieldPasswordError('');
    setFieldCheckPasswordError('');

    return true;
  }, [currentPassword, password, checkPassword]);

  useEffect(() => {
    setFieldCurrentPasswordError('');
    setFieldPasswordError('');
    setFieldCheckPasswordError('');

    cleanSubmitError();
  }, [currentPassword, password, checkPassword]);

  function activeFocus(e) {
    const { name } = e.target;
    setInputFocus({
      ...inputFocus,
      [name]: !inputFocus[name],
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const areFieldsValid = validateFields();
    if (!areFieldsValid) return;

    cleanSubmitError();
    updatePassword(email, currentPassword, password);
  }

  useEffect(() => setSubmitErrorMessage(submitError), [submitError]);

  return (
    <form onSubmit={handleSubmit} className="bodyEditPassword">
      <div className="d-flex justify-content-between border-bottom border-dark bodyEditSelfUser__dividerLines bodyEditSelfUser__fontSize">
        <div className="d-flex justify-content-start bodyEditSelfUser__greyText">Email</div>
        <div className="d-flex justify-content-end">{email}</div>
      </div>

      <div
        className={`d-flex justify-content-between border-bottom border-dark bodyEditSelfUser__dividerLines ${inputFocus.currentPassword &&
          'bodyEditSelfUser__dividerLines--inFocus'} bodyEditSelfUser__fontSize`}
      >
        <div className="d-flex justify-content-start">
          <label htmlFor="currentPassword" className=" bodyEditSelfUser__greyText">
            Senha atual
          </label>
        </div>
        <div className="d-flex justify-content-end">
          <input
            type={isCurrentPasswordVisible ? 'text' : 'password'}
            id="currentPassword"
            name="currentPassword"
            onChange={e => setCurrentPassword(e.target.value)}
            className="text-right inputOcult"
            onFocus={activeFocus}
            onBlur={activeFocus}
            autoFocus
          />
          {isCurrentPasswordVisible ? (
            <FiEyeOff className="togglePasswordVisibilityIcon" onClick={toggleIsCurrentPasswordVisible} />
          ) : (
            <FiEye className="togglePasswordVisibilityIcon" onClick={toggleIsCurrentPasswordVisible} />
          )}
        </div>
      </div>
      {fieldCurrentPasswordError && <div className="submitErrorMsg">{fieldCurrentPasswordError}</div>}
      {submitErrorMessage === 'Senha atual incorreta!' && <div className="submitErrorMsg">{submitErrorMessage}</div>}

      <div
        className={`d-flex justify-content-between border-bottom border-dark bodyEditSelfUser__dividerLines ${inputFocus.password &&
          'bodyEditSelfUser__dividerLines--inFocus'} bodyEditSelfUser__fontSize`}
      >
        <div className="d-flex justify-content-start">
          <label htmlFor="password" className=" bodyEditSelfUser__greyText">
            Nova Senha
          </label>
        </div>
        <div className="d-flex justify-content-end">
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            id="password"
            name="password"
            onChange={e => setPassword(e.target.value)}
            className="text-right inputOcult"
            onFocus={activeFocus}
            onBlur={activeFocus}
          />
          {isPasswordVisible ? (
            <FiEyeOff className="togglePasswordVisibilityIcon" onClick={toggleIsPasswordVisible} />
          ) : (
            <FiEye className="togglePasswordVisibilityIcon" onClick={toggleIsPasswordVisible} />
          )}
        </div>
      </div>
      {fieldPasswordError && <div className="submitErrorMsg">{fieldPasswordError}</div>}

      <div
        className={`d-flex justify-content-between border-bottom border-dark bodyEditSelfUser__dividerLines ${inputFocus.checkPassword &&
          'bodyEditSelfUser__dividerLines--inFocus'} bodyEditSelfUser__fontSize`}
      >
        <div className="d-flex justify-content-start">
          <label htmlFor="checkPassword" className=" bodyEditSelfUser__greyText">
            {' '}
            Confirmar Senha
          </label>
        </div>
        <div className="d-flex justify-content-end">
          <input
            type={isCheckPasswordVisible ? 'text' : 'password'}
            id="checkPassword"
            name="checkPassword"
            onChange={e => setCheckPassword(e.target.value)}
            onFocus={activeFocus}
            onBlur={activeFocus}
            className="text-right inputOcult"
          />
          {isCheckPasswordVisible ? (
            <FiEyeOff className="togglePasswordVisibilityIcon" onClick={toggleIsCheckPasswordVisible} />
          ) : (
            <FiEye className="togglePasswordVisibilityIcon" onClick={toggleIsCheckPasswordVisible} />
          )}
        </div>
      </div>
      {fieldCheckPasswordError && <div className="submitErrorMsg">{fieldCheckPasswordError}</div>}
      {submitErrorMessage !== 'Senha atual incorreta!' && <div className="submitErrorMsg">{submitErrorMessage}</div>}

      <div className="d-flex justify-content-end mt-4">
        <div className="mr-4">
          <Button
            handleClick={returnToData}
            inputFilled
            classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
          >
            Cancelar
            </Button>
        </div>
        <div >
          <Button type="submit" inputFilled={validateFields} disabled={changePasswordSelectors.isLoading}>
            {!changePasswordSelectors.isLoading ? (
              'Atualizar'
            ) : (
              <img src={icLoadingWhite} alt="icone circular, movimentando-se em circulos." height="20px" />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

EditPassword.propTypes = {
  email: PropTypes.string.isRequired,
  updatePassword: PropTypes.func.isRequired,
  returnToData: PropTypes.func.isRequired,
};

export default EditPassword;
