import { call, put } from 'redux-saga/effects';

import { getTeachers, createTeachers, updateTeacher, deleteTeacher } from '../../client/teachers';
import {
  retrieveTeachersRowsTotalOffsetSuccess,
  retrieveTeachersRowsTotalOffsetFailure,
} from '../../Redux/Teacher/Retrieve/actions';
import { createTeacherSuccess, createTeacherFailure } from '../../Redux/Teacher/Create/actions';
import { updateTeacherSucess, updateTeacherFailure } from '../../Redux/Teacher/Update/actions';
import { deleteTeacherSuccess, deleteTeacherFailure } from '../../Redux/Teacher/Delete/actions';

export function* retrieveTeachersRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit: limitParam, signal } = action.payload;
    const response = yield call(getTeachers, offParam, authToken, search, limitParam, signal);
    const { rows, total, offset, limit } = response.data;
    yield put(retrieveTeachersRowsTotalOffsetSuccess({ rows, total, offset, limit }));
  } catch (err) {
    yield put(retrieveTeachersRowsTotalOffsetFailure(err.message));
  }
}

export function* createTeacherSaga(action) {
  try {
    const { data, authToken } = action.payload;
    const response = yield call(createTeachers, data, authToken);
    yield put(createTeacherSuccess({ user: response.data }));
  } catch (error) {
    error.response && error.response.data
      ? yield put(createTeacherFailure(error.response.data))
      : yield put(createTeacherFailure(error.message));
  }
}

export function* updateTeacherSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    const response = yield call(updateTeacher, id, data, authToken);
    yield put(updateTeacherSucess(response));
  } catch (error) {
    error.response && error.response.data
      ? yield put(updateTeacherFailure(error.response.data))
      : yield put(updateTeacherFailure(error.message));
  }
}

export function* deleteTeacherSaga(action) {
  try {
    const { id, authToken } = action.payload;
    yield call(deleteTeacher, id, authToken);
    yield put(deleteTeacherSuccess());
  } catch (error) {
    error.response && error.response.data
      ? yield put(deleteTeacherFailure(error.response.data))
      : yield put(deleteTeacherFailure(error.message));
  }
}
