import { Types } from '../types';

export function deleteMacrothemesRequest({ id, authToken }) {
  return {
    type: Types.DELETE_MACROTHEME_REQUEST,
    payload: { id, authToken },
  };
}

export function deleteMacrothemesSuccess() {
  return {
    type: Types.DELETE_MACROTHEME_SUCCESS,
  };
}

export function deleteMacrothemesFailure(errorMessage) {
  return {
    type: Types.DELETE_MACROTHEME_FAILURE,
    payload: errorMessage,
  };
}

export function deleteMacrothemesReset() {
  return {
    type: Types.DELETE_MACROTHEME_RESET_STATE,
  };
}
