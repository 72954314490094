import { Types } from '../types';

export function deleteWorkshopRequest({ id, authToken }) {
  return {
    type: Types.DELETE_WORKSHOP_REQUEST,
    payload: { id, authToken },
  };
}

export function deleteWorkshopSuccess() {
  return {
    type: Types.DELETE_WORKSHOP_SUCCESS,
  };
}

export function deleteWorkshopFailure(errorMessage) {
  return {
    type: Types.DELETE_WORKSHOP_FAILURE,
    payload: errorMessage,
  };
}

export function deleteWorkshopReset() {
  return {
    type: Types.DELETE_WORKSHOP_RESET_STATE,
  };
}
