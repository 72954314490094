import { createSelector } from 'reselect';

const ClassesSelectors = state => state.CrudClassesReducers;

export const ClassesCreateSelector = createSelector(
  ClassesSelectors,
  createClassReducers => createClassReducers.createClassReducer
);

export const ClassesRetrieveAllSelectors = createSelector(
  ClassesSelectors,
  retrieveClassesRowsTotalOffsetReducer => retrieveClassesRowsTotalOffsetReducer.retrieveClassesRowsTotalOffsetReducer
);

export const ClassesSearchSelectors = createSelector(
  ClassesSelectors,
  classesSearch => classesSearch.retrieveClassesRowsTotalOffsetReducer.search
);

export const ClassContentRetrieveSelectors = createSelector(
  ClassesSelectors,
  retrieveClassContentsReducer => retrieveClassContentsReducer.retrieveClassContentReducer
);

export const ClassesUpdateSelector = createSelector(
  ClassesSelectors,
  updateClassReducers => updateClassReducers.updateClassReducer
)

export const ClassDeleteSelectors = createSelector(
  ClassesSelectors,
  retrieveClassesRowsTotalOffsetReducer => retrieveClassesRowsTotalOffsetReducer.deleteClassReducer
);
