import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { RouteWrapper as Route } from './routeWrapper';
import TemplateInternal from '../components/TemplateInternal';
import { Login } from '../pages/Login';
import ForgetPassword from '../pages/ForgetPassword';
import ChangePassword from '../pages/ChangePassword';
import Mentors from '../pages/Mentors';
import Macrothemes from '../pages/Macrothemess';
import Unitss from '../pages/Unitss';
import Workshops from '../pages/Workshops';
import Admins from '../pages/Admins';
import Teachers from '../pages/Teachers';
import { Dashboard } from '../pages/Dashboard';
import { Classes } from '../pages/Classes';
import { Events } from '../pages/Events';
import { Students } from '../pages/Students';

import { ModalProvider } from '../contexts/ModalContext';
import { NotifyProvider } from '../contexts/NotifyContext';
import { LoadingProvider } from '../contexts/LodingContext';

import { AuthRetrieveUserSelector } from '../Redux/Auth/selector';
import { ADMIN_ROUTES, MENTOR_ROUTES, TEACHER_ROUTES, USER_ROLES } from '../utils/enums';

function Routes() {
  const user = useSelector(AuthRetrieveUserSelector);

  const AvailableRoutes = [
    { name: 'dashboard', component: <Route path="/dashboard" exact component={Dashboard} isPrivate /> },
    { name: 'professores', component: <Route path="/professores" exact component={Teachers} isPrivate /> },
    { name: 'aulas', component: <Route path="/aulas" exact component={Classes} isPrivate /> },
    { name: 'alunos', component: <Route path="/alunos" exact component={Students} isPrivate /> },
    { name: 'eventos', component: <Route path="/eventos" exact component={Events} isPrivate /> },
    { name: 'artigos', component: <Route path="/artigos" exact component={Macrothemes} isPrivate /> },
    { name: 'unidades', component: <Route path="/unidades" exact component={Unitss} isPrivate /> },
    { name: 'mentores', component: <Route path="/mentores" exact component={Mentors} isPrivate /> },
    { name: 'oficinas', component: <Route path="/oficinas" exact component={Workshops} isPrivate /> },
    { name: 'admins', component: <Route path="/admins" exact component={Admins} isPrivate /> },
  ];

  function routesByRole() {
    let userRoutes = ADMIN_ROUTES;

    if (user.role === USER_ROLES.admin) {
      userRoutes = ADMIN_ROUTES;
    }
    if (user.role === USER_ROLES.mentor) {
      userRoutes = MENTOR_ROUTES;
    }
    if (user.role === USER_ROLES.teacher) {
      userRoutes = TEACHER_ROUTES;
    }

    if (userRoutes)
      return AvailableRoutes.map(route => {
        if (route.name in userRoutes) {
          return route.component;
        }
        return null;
      }).filter(route => route !== null);

    return {};
  }

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/forgetpassword" component={ForgetPassword} />
      <Route path="/changepassword/:token" component={ChangePassword} />

      <ModalProvider>
        <NotifyProvider>
          <LoadingProvider>
            <TemplateInternal>
              {routesByRole()}
              {user.role === 'admin' && <Redirect from="/" to="/dashboard" />}
              {user.role === 'teacher' && <Redirect from="/" to="/aulas" />}
              {user.role === 'mentor' && <Redirect from="/" to="/unidades" />}
            </TemplateInternal>
          </LoadingProvider>
        </NotifyProvider>
      </ModalProvider>
    </Switch>
  );
}
export { Routes };
