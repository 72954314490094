export const Types = {
  DEFINE_ADMIN_SEARCH_PARAMS: '[admins]DEFINE_ADMIN_SEARCH_PARAMS',
  CREATE_ADMIN_REQUEST: '[admins]CREATE_ADMIN_REQUEST',
  CREATE_ADMIN_SUCCESS: '[admins]CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_FAILURE: '[admins]CREATE_ADMIN_FAILURE',
  CREATE_ADMIN_RESET_STATE: '[admins]CREATE_ADMIN_RESET_STATE', // TODO - need refactory.
  RETRIEVE_ALL_ADMINS_REQUEST: '[admins]RETRIEVE_ALL_ADMINS_REQUEST',
  RETRIEVE_ALL_ADMINS_SUCCESS: '[admins]RETRIEVE_ALL_ADMINS_SUCCESS',
  RETRIEVE_ALL_ADMINS_FAILURE: '[admins]RETRIEVE_ALL_ADMINS_FAILURE',
  UPDATE_ADMIN_REQUEST: '[admins]UPDATE_ADMIN_REQUEST',
  UPDATE_ADMIN_SUCCESS: '[admins]UPDATE_ADMIN_SUCCESS',
  UPDATE_ADMIN_FAILURE: '[admins]UPDATE_ADMIN_FAILURE',
  UPDATE_ADMIN_RESET_STATE: '[admins]UPDATE_ADMIN_RESET_STATE',

  UPDATE_SELF_REQUEST: '[admins]UPDATE_SELF_REQUEST',
  UPDATE_SELF_SUCCESS: '[admins]UPDATE_SELF_SUCCESS',
  UPDATE_SELF_FAILURE: '[admins]UPDATE_SELF_FAILURE',
  UPDATE_SELF_RESET_STATE: '[admins]UPDATE_SELF_RESET_STATE',
};
