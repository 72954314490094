import React from 'react';
import proptypes from 'prop-types';
// import './ModalToRegisterUnits.scss';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { UnitssInfo } from '../NewUnitssInfo';

function ModalToRegisterUnits({ idUnit, useForEdit }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl">
      <UnitssInfo closeModal={closeModal} useForEdit={useForEdit} idUnit={idUnit} />
    </Modal>
  );
}

ModalToRegisterUnits.propTypes = {
  idUnit: proptypes.string.isRequired,
  useForEdit: proptypes.bool.isRequired,
};

export default ModalToRegisterUnits;
