import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';

import icLocked from '../../../assets/images/ic_locked.svg';
import icWarning from '../../../assets/images/ic_warning.svg';


const FirstStep = ({ errorMessage, eventButton }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <>
    <h4 className="commonFontStyle commonFontStyle--title">Escolha uma nova senha</h4>
    <p className="commonFontStyle commonFontStyle--content">
      Digite abaixo a sua nova senha.
    </p>

    <Input
      icon={icLocked}
      alt="icone de um cadeado."
      title="icone de um cadeado."
      setInputValue={setPassword}
      inputValue={password}
      typeInput="password"
    />

    <br />
    <p className="commonFontStyle commonFontStyle--content">
      Confirme a nova senha.
    </p>

    <Input
      icon={icLocked}
      alt="icone de um cadeado."
      title="icone de um cadeado."
      setInputValue={setConfirmPassword}
      inputValue={confirmPassword}
      typeInput="password"
    />

    {errorMessage && showAlertError(errorMessage)}

    <div className="mt-4 mb-5">
      <Button handleClick={() => eventButton({password, confirmPassword})}>
        Redefinir senha
      </Button>
    </div>
    </>
  )
}

 function showAlertError(errorMessage) {
    return (
      errorMessage && (
        <div className="col-12 mt-2">
          <Alert type="danger" messageAlert={errorMessage} icon={icWarning} altIcon="simbolo de atenção" />
        </div>
      )
    );
  }

FirstStep.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  eventButton: PropTypes.func.isRequired,
};
  
FirstStep.defaultProps = {
  errorMessage: '',
};

export default FirstStep;