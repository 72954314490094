import { createSelector } from 'reselect';

const EventsSelectors = state => state.CrudEventsReducers;

export const EventsRetrieveAllSelectors = createSelector(
  EventsSelectors,
  retrieveEventsRowsTotalOffsetReducer => retrieveEventsRowsTotalOffsetReducer.retrieveEventsRowsTotalOffsetReducer
);

export const EventsSearchSelectors = createSelector(
  EventsSelectors,
  EventsSearch => EventsSearch.retrieveEventsRowsTotalOffsetReducer.search
);

export const EventContentRetrieveSelectors = createSelector(
  EventsSelectors,
  retrieveEventContentsReducer => retrieveEventContentsReducer.retrieveEventContentReducer
);

export const EventsCreateSelector = createSelector(
  EventsSelectors,
  createEventReducers => createEventReducers.createEventReducer
);

export const EventsUpdateSelector = createSelector(
  EventsSelectors,
  updateEventReducers => updateEventReducers.updateEventReducer
);

export const EventsDeleteSelectors = createSelector(
  EventsSelectors,
  retrieveEventsRowsTotalOffsetReducer => retrieveEventsRowsTotalOffsetReducer.deleteEventReducer
);
