import React from 'react';
// import './ModalToRegisterMacrothemes.scss';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { MacrothemessInfo } from '../NewMacrothemessInfo';

function ModalToRegisterMacrothemes({ idMacrothemes, useForEdit }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl">
      <MacrothemessInfo closeModal={closeModal} useForEdit={useForEdit} idMacrothemes={idMacrothemes} />
    </Modal>
  );
}

export default ModalToRegisterMacrothemes;
