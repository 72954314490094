import { call, put } from 'redux-saga/effects';

import { getAdmins, createAdmins, updateAdmin } from '../../client/admins';
import {
  retrieveAdminsRowsTotalOffsetSuccess,
  retrieveAdminsRowsTotalOffsetFailure,
} from '../../Redux/Admin/Retrieve/actions';
import { createAdminSuccess, createAdminFailure } from '../../Redux/Admin/Create/actions';
import { updateAdminSucess, updateAdminFailure } from '../../Redux/Admin/Update/actions';

import { updateSelfSucess, updateSelfFailure } from '../../Redux/Admin/UpdatedSelf/actions';

export function* retrieveAdminsRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getAdmins, offParam, authToken, search, limit, signal);
    const { rows, total, offset } = response.data;
    yield put(retrieveAdminsRowsTotalOffsetSuccess({ rows, total, offset }));
  } catch (err) {
    yield put(retrieveAdminsRowsTotalOffsetFailure(err.message));
  }
}

export function* createAdminSaga(action) {
  try {
    const { data, authToken } = action.payload;
    const response = yield call(createAdmins, data, authToken);
    yield put(createAdminSuccess({ user: response.data }));
  } catch (error) {
    error.response && error.response.data ?
      yield put(createAdminFailure(error.response.data )) : 
      yield put(createAdminFailure(error.message));
  }
}

export function* updateAdminSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    yield call(updateAdmin, id, data, authToken);
    yield put(updateAdminSucess(true));
  } catch (error) {
    error.response && error.response.data ?
      yield put(updateAdminFailure(error.response.data )) : 
      yield put(updateAdminFailure(error.message));
  }
}

export function* updateSelfAdminSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    yield call(updateAdmin, id, data, authToken);
    yield put(updateSelfSucess());
  } catch (error) {
    error.response && error.response.data ?
      yield put(updateSelfFailure(error.response.data)) : 
      yield put(updateSelfFailure(error.message));
  }
}
