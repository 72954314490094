import React from 'react';
import proptypes from 'prop-types';
// import './ModalToRegisterMentor.scss';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { MentorsInfo } from '../NewMentorsInfo';

function ModalToRegisterMentor({ idMentor, useForEdit }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl">
      <MentorsInfo closeModal={closeModal} idMentor={idMentor} useForEdit={useForEdit} />
    </Modal>
  );
}

ModalToRegisterMentor.propTypes = {
  idMentor: proptypes.number,
  useForEdit: proptypes.bool,
};

ModalToRegisterMentor.defaultProps = {
  idMentor: 0,
  useForEdit: false,
};

export default ModalToRegisterMentor;
