import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/workshops';
const subjectRoute = '/subjects';
const classTypeRoute = '/classTypes/admin/2/subjects';
const classTypeHierarchicalRoute = '/classTypes/hierarchical/2/subjects';

function getWorkshops(offset = 0, authToken, search, limit = 10, signal) {
  const finalRoute = search ? `${classTypeHierarchicalRoute}?${search}` : classTypeHierarchicalRoute;
  return Api.get(finalRoute, {
    params: {
      limit,
      offset,
    },
    headers: commonHeaders(authToken),
    signal,
  });
}

function createWorkshops(data, authToken) {
  const formattedData = { ...data, color: '#A9A9A9', classTypeId: 2};

  return Api.post(subjectRoute, formattedData, {
    headers: commonHeaders(authToken),
  });
}

function updateWorkshop(id, data, authToken) {
  return Api.patch(`${subjectRoute}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function deleteWorkshop(id, authToken) {
  console.log(`id: ${id}`);console.log(`id: ${id}`);
  return Api.delete(`${subjectRoute}/${id}`, {
    headers: commonHeaders(authToken),
  });
}

function getWorkshopReferences(authToken, id) {
  const finalRoute = `${subjectRoute}/${id}/references`;
  return Api.get(finalRoute, {
    headers: commonHeaders(authToken),
  });
}

export { getWorkshops, createWorkshops, updateWorkshop, deleteWorkshop, getWorkshopReferences };
