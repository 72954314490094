import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Table } from '../../components/Table';
import Button from '../../components/Button';
import { ModalToRegisterTeacher } from './ModalToRegisterTeacher';
import { ModalDispatch } from '../../contexts/ModalContext';
import { LoadingDispatch } from '../../contexts/LodingContext';

import { AuthTokenSelector } from '../../Redux/Auth/selector';
import { createTeacherResetState } from '../../Redux/Teacher/Create/actions';
import {
  retrieveTeachersRowsTotalOffsetRequest,
  defineTeachersSearchParams,
} from '../../Redux/Teacher/Retrieve/actions';
import { updateTeacherReset } from '../../Redux/Teacher/Update/actions';
import { deleteTeacherRequest, deleteTeacherReset } from '../../Redux/Teacher/Delete/actions';

import {
  TeachersRetrieveAllSelectors,
  TeachersCreateHasErrorSelectors,
  TeachersCreateGetUserSelectors,
  TeachersCreateLoading,
  TeacherUpdateLoading,
  TeacherUpdateSelectors,
  TeacherDeleteLoading,
  TeacherDeleteSelectors,
  TeacherCreateSelectors,
  TeacherSearchSelectors,
} from '../../Redux/Teacher/selectors';

import { showNotify } from '../../utils/showNotify';
import { BUTTON_CLASSES } from '../../utils/enums';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { ModalToDeleteTeacher } from './ModalToDeleteTeacher';

function Teachers() {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const createUserLoading = useSelector(TeachersCreateLoading);
  const deleteUserLoading = useSelector(TeacherDeleteLoading);
  const [valuePagination, setValuePagination] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const modalDispatch = ModalDispatch();
  const loadingDispatch = LoadingDispatch();
  const teachersSearch = useSelector(TeacherSearchSelectors);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [dataToEdit, setDataToEdit] = useState();
  const [searchText, setSearchText] = useState('');

  const dataOfTable = useSelector(TeachersRetrieveAllSelectors);
  const teacherUpdateLoading = useSelector(TeacherUpdateLoading);
  const user = useSelector(TeachersCreateGetUserSelectors);
  const createTeacherError = useSelector(TeachersCreateHasErrorSelectors);
  const updateTeacher = useSelector(TeacherUpdateSelectors);
  const deleteTeacher = useSelector(TeacherDeleteSelectors);
  const createTeacher = useSelector(TeacherCreateSelectors);
  const abortTeachersRetrieveAllRequestController = new AbortController();

  const Titles = ['Data', 'Modificado em', 'Nome', 'Email', 'Estado'];

  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'name' },
    { key: 'username' },
    { key: 'state' },
  ];

  const limit = itemsPerPage;
  const search = teachersSearch;

  useEffect(() => {
    setComponentLoaded(true);

    return () => { 
      dispatch(defineTeachersSearchParams({ search: '' }));
      abortTeachersRetrieveAllRequestController.abort();
    }
  }, []);

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  useEffect(() => {
    if (dataToEdit)
      modalDispatch({ type: 'open', payload: <ModalToRegisterTeacher useForEdit idTeacher={dataToEdit.userId} /> });
  }, [dataToEdit]);

  const dispatchDataForTable = offset => {
    dispatch(retrieveTeachersRowsTotalOffsetRequest(offset, authToken, search, limit, abortTeachersRetrieveAllRequestController.signal));
  };

  createUserLoading || teacherUpdateLoading || deleteUserLoading
    ? loadingDispatch({ type: 'open' })
    : loadingDispatch({ type: '' });

  useEffect(() => {
    if (dataToEdit)
      modalDispatch({ type: 'open', payload: <ModalToRegisterTeacher useForEdit idTeacher={dataToEdit.userId} /> });
  }, [dataToEdit]);

  function openModalToRegisterTeacher() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterTeacher /> });
  }

  const getItemById = userId => dataOfTable.rows.find(item => item.userId === userId);

  function deleteTeacherById(userId) {
    dispatch(deleteTeacherRequest({ id: userId, authToken }));
    modalDispatch({ type: '' });
  }

  async function _setDataToEdit(item) {
    if (dataToEdit?.userId === item.userId) {
      modalDispatch({ type: 'open', payload: <ModalToRegisterTeacher useForEdit idTeacher={item.userId} /> });
    } else setDataToEdit(item);
  }

  async function openModalToDelete(userId) {
    const { userId: idTeacher, name } = getItemById(userId);
    const payload = <ModalToDeleteTeacher nameTeacher={name} idTeacher={userId} actionIfYes={() => deleteTeacherById(idTeacher)} />;
    modalDispatch({ type: 'open', payload });
  }
  
  function handleSearch(event) {
    event.preventDefault();
    const search = searchText ? `query=${searchText}` : '';
    dispatch(defineTeachersSearchParams({ search }));
  }

  function createTeacherNotify() {
    let contentNotify;
    let typeNotify;
    if (createTeacherError) {
      typeNotify = 'error';
      contentNotify = `Ocorreu um erro ao cadastrar o Professor.`;

      const createTeacherErrorMessage = createTeacher?.errorMsg;

      if (createTeacherErrorMessage.sqlMessage) {
        if (createTeacherErrorMessage.sqlMessage === 'Duplicate entry for teachers CPF')
          contentNotify += ' CPF Duplicado!';
        else if (createTeacherErrorMessage.sqlMessage.includes('username_UNIQUE')) contentNotify += ' Email Duplicado!';
        else contentNotify += createTeacherErrorMessage.sqlMessage;
      } else if (createTeacherErrorMessage === 'Network Error')
        contentNotify += ' Erro de rede! A sua conexão está com problemas ou o servidor pode estar fora do ar!';
      else contentNotify += ' Tente novamente mais tarde ou contate um administrador';
    } else if (user) {
      modalDispatch({ type: 'close', payload: '' });
      typeNotify = 'success';
      contentNotify = `Professor(a) cadastrado(a) | Senha: 12345678`;
      dispatch(retrieveTeachersRowsTotalOffsetRequest(0, authToken, ''));
      dispatchDataForTable(valuePagination);
    }

    dispatch(createTeacherResetState());
    showNotify(typeNotify, contentNotify);
  }

  function updateTeacherNotify() {
    let contentNotify;
    let typeNotify;
    if (updateTeacher.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao atualizar cadastro do Professor.';

      const updateTeacherErrorMessage = updateTeacher?.errorMsg;

      if (updateTeacherErrorMessage.sqlMessage) {
        if (updateTeacherErrorMessage.sqlMessage) contentNotify += ' CPF Duplicado!';
        else if (updateTeacherErrorMessage.sqlMessage.includes('username_UNIQUE')) contentNotify += ' Email Duplicado!';
        else contentNotify += updateTeacher.errorMsg.sqlMessage;
      } else if (updateTeacherErrorMessage === 'Network Error')
        contentNotify += ' Erro de rede! A sua conexão está com problemas ou o servidor pode estar fora do ar!';
      else contentNotify += ' Tente novamente mais tarde ou contate um administrador';
    } else if (updateTeacher.data) {
      typeNotify = 'success';
      contentNotify = `Professor(a) atualizado(a)`;
      dispatchDataForTable(valuePagination);
    }

    dispatch(updateTeacherReset());
    showNotify(typeNotify, contentNotify);
  }

  function deleteTeacherNotify() {
    let contentNotify;
    let typeNotify;
    if (deleteTeacher.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao deletar cadastro do Professor.';

      const deleteTeacherErrorMessage = deleteTeacher?.errorMsg;

      if (deleteTeacherErrorMessage.sqlMessage) {
        if (deleteTeacherErrorMessage.sqlMessage) contentNotify += deleteTeacher.errorMsg.sqlMessage;
      } else if (deleteTeacherErrorMessage === 'Network Error') {
        contentNotify += ' Erro de rede! A sua conexão está com problemas ou o servidor pode estar fora do ar!';
      } else contentNotify += ' Tente novamente mais tarde ou contate um administrador';
    } else if (deleteTeacher.deleteSuccess) {
      typeNotify = 'success';
      contentNotify = 'Professor(a) excluído(a)';
      dispatchDataForTable(valuePagination);
    }
    dispatch(deleteTeacherReset());
    showNotify(typeNotify, contentNotify);
  }

  return (
    <>
      {(user || createTeacherError) && createTeacherNotify()}
      {(updateTeacher.data || updateTeacher.hasError) && updateTeacherNotify()}
      {(deleteTeacher.deleteSuccess || deleteTeacher.hasError) && deleteTeacherNotify()}

      <div className="row mb-2">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Professores</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center mb-2">
          <SearchAndFilter
            haveFilter={false}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            inputPlaceholder="Pesquisar por nome..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        <div className="col-auto">
          <Button handleClick={openModalToRegisterTeacher} classes={BUTTON_CLASSES.NO_AWAIT}>
            + Adicionar professor
          </Button>
        </div>
      </div>

      <Table
        titles={Titles}
        dataOfTable={dataOfTable}
        keysOfObjectsData={listOfKeys}
        dataKey="userId"
        itemsPerPage={itemsPerPage}
        notColapse
        notColapseFunction={openModalToDelete}
        editButton={item => _setDataToEdit(item)}
        functionForPagination={setValuePagination}
        shouldResetPagination={shouldResetPagination}
      />
    </>
  );
}

export default withRouter(Teachers);
