import React from 'react';
import PropTypes from 'prop-types';
import './Alert.scss';

const Alert = ({ type, messageAlert, icon, altIcon }) => (
  <div className={`alert alert--fontStyle alert--${type}`}>
    <div className="d-flex">
      <div className="col-1" hidden={!icon}>
        <img src={icon} alt={altIcon} className="mr-3" />
      </div>
      <div className="col-11">{messageAlert}</div>
    </div>
  </div>
);

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  messageAlert: PropTypes.string.isRequired,
  icon: PropTypes.string,
  altIcon: PropTypes.string,
};

Alert.defaultProps = {
  icon: '',
  altIcon: '',
};

export default Alert;
