import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Table } from '../../components/Table';
import Button from '../../components/Button';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { ModalToRegisterWorkshop } from './ModalToRegisterWorkshop';
import { ModalDispatch } from '../../contexts/ModalContext';
import { LoadingDispatch } from '../../contexts/LodingContext';

import { AuthTokenSelector } from '../../Redux/Auth/selector';
import { UnitssRetrieveAllSelectors } from '../../Redux/Units/selectors';
import { createWorkshopResetState } from '../../Redux/Workshop/Create/actions';
import { defineWorkshopsSearchParams, retrieveWorkshopsRowsTotalOffsetRequest } from '../../Redux/Workshop/Retrieve/actions';
import { updateWorkshopReset } from '../../Redux/Workshop/Update/actions';
import { deleteWorkshopRequest, deleteWorkshopReset } from '../../Redux/Workshop/Delete/actions';
import {
  WorkshopsRetrieveAllSelectors,
  WorkshopsCreateHasErrorSelectors,
  WorkshopSuccessSelectors,
  WorkshopsCreateLoading,
  WorkshopsCreateErrorMessage,
  WorkshopUpdateLoading,
  WorkshopUpdateSelectors,
  WorkshopDeleteLoading,
  WorkshopDeleteSelectors,
  WorkshopSearchSelectors
} from '../../Redux/Workshop/selectors';

import { retrieveUnitssRowsTotalOffsetRequest } from '../../Redux/Units/Retrieve/actions';
import { ModalToDeleteWorkshop } from './ModalToDeleteWorkshop';
import { showNotify } from '../../utils/showNotify';

function Workshops() {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const createUserLoading = useSelector(WorkshopsCreateLoading);
  const deleteUserLoading = useSelector(WorkshopDeleteLoading);
  const [valuePagination, setValuePagination] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataToEdit, setDataToEdit] = useState();

  const modalDispatch = ModalDispatch();
  const loadingDispatch = LoadingDispatch();
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState(0);
  const [searchText, setSearchText] = useState('');

  const sitesSelector = useSelector(UnitssRetrieveAllSelectors).rows;
  const workshopsSearch = useSelector(WorkshopSearchSelectors);
  const workshopsSelector = useSelector(WorkshopsRetrieveAllSelectors);
  const workshop = useSelector(WorkshopSuccessSelectors);
  const createUserError = useSelector(WorkshopsCreateHasErrorSelectors);
  const updateWorkshop = useSelector(WorkshopUpdateSelectors);
  const deleteWorkshop = useSelector(WorkshopDeleteSelectors);
  const errorMsg = useSelector(WorkshopsCreateErrorMessage);
  const workshopUpdateLoading = useSelector(WorkshopUpdateLoading);

  const abortWorkshopsRetrieveAllRequestController = new AbortController();
  const abortUnitsRetrieveAllRequestController = new AbortController();

  const Titles = ['Data', 'Modificado em', 'Nome', 'Unidade'];

  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'name' },
    { key: 'site_name' },
  ];

  const limit = itemsPerPage;
  const search = workshopsSearch;

  const dispatchDataForTable = offset => {
    dispatch(retrieveWorkshopsRowsTotalOffsetRequest(offset, authToken, search, limit, abortWorkshopsRetrieveAllRequestController.signal));
  };

  useEffect(() => {
    if (dataToEdit)
      modalDispatch({ type: 'open', payload: <ModalToRegisterWorkshop useForEdit idWorkshop={dataToEdit.id} /> });
  }, [dataToEdit]);

  useEffect(() => {
    setComponentLoaded(true);
    dispatch(retrieveUnitssRowsTotalOffsetRequest(0, authToken, undefined, 100, abortUnitsRetrieveAllRequestController.signal));
    return () => {
      dispatch(defineWorkshopsSearchParams({ search: '' }));
      abortWorkshopsRetrieveAllRequestController.abort();
      abortUnitsRetrieveAllRequestController.abort();
    } 
  }, []);

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  function handleSearch(event) {
    event.preventDefault();

    let search = searchText ? `keyword=${searchText}` : '';
    search += `&keywordType=${selectedFilter}`;

    dispatch(defineWorkshopsSearchParams({ search }));
  }

  const dataOfTable = {
    ...workshopsSelector,
    rows: workshopsSelector.rows.map(item => {
      const siteInfo = sitesSelector.find(({ id }) => id === item.siteId);

      return {
        ...item,
        site_name: siteInfo ? siteInfo.name : ""
      }
    })};

  createUserLoading || workshopUpdateLoading || deleteUserLoading
    ? loadingDispatch({ type: 'open' })
    : loadingDispatch({ type: '' });

  function openModalToRegisterWorkshop() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterWorkshop /> });
  }

  const getItemById = id => dataOfTable.rows.find(item => item.id === id);

  function deleteWorkshopsById(id) {
    dispatch(deleteWorkshopRequest({ id, authToken }));
    modalDispatch({ type: '' });
  }

  async function _setDataToEdit(item) {
    if (dataToEdit?.id === item.id) {
      modalDispatch({ type: 'open', payload: <ModalToRegisterWorkshop useForEdit idWorkshop={item.id} /> });
    }
    else setDataToEdit(item);
  }

  async function openModalToDelete(id) {
    const { id: idMacrotheme, name } = getItemById(id);
    const payload = (
      <ModalToDeleteWorkshop
        id={id}
        nameWorkshop={name}
        actionIfYes={() => deleteWorkshopsById(idMacrotheme)}
      />
    );
    modalDispatch({ type: 'open', payload });
  }

  function createWorkshopNotify() {
    let contentNotify;
    let typeNotify;
    if (createUserError) {
      typeNotify = 'error';
      contentNotify = `Ocorreu um erro ao cadastrar o workshop. ${errorMsg}. Tente novamente :)`;
    } else if (workshop) {
      typeNotify = 'success';
      contentNotify = 'Oficina cadastrada';
      dispatchDataForTable(valuePagination);
    }

    dispatch(createWorkshopResetState());
    showNotify(typeNotify, contentNotify);
  }

  function updateWorkshopNotify() {
    let contentNotify;
    let typeNotify;
    if (updateWorkshop.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao atualizar cadastro do workshop. Tente novamente :)';
    } else {
      typeNotify = 'success';
      contentNotify = `Oficina atualizada`;
      dispatchDataForTable(valuePagination);
    }
    dispatch(updateWorkshopReset());
    showNotify(typeNotify, contentNotify);
  }

  function deleteWorkshopNotify() {
    let contentNotify;
    let typeNotify;
    if (deleteWorkshop.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao excluir cadastro da oficina. Tente novamente :)';
    } else if (deleteWorkshop.deleteSuccess) {
      typeNotify = 'success';
      contentNotify = 'Oficina excluída';
      dispatchDataForTable(valuePagination);
    }
    dispatch(deleteWorkshopReset());
    showNotify(typeNotify, contentNotify);
  }

  return (
    <>
      {(workshop || createUserError) && createWorkshopNotify()}
      {(updateWorkshop.success || updateWorkshop.hasError) && updateWorkshopNotify()}
      {(deleteWorkshop.deleteSuccess || deleteWorkshop.hasError) && deleteWorkshopNotify()}

      <div className="row mb-4">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Oficinas</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center my-autocd mb-2">
          <SearchAndFilter   
            haveFilter={true}
            filterOptions={[{ value: 0, text: 'Oficina'}, { value: 1, text: 'Unidade'}]}
            selectedFilter={selectedFilter}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            setFilter={setSelectedFilter}
            inputPlaceholder="Pesquisar por oficina ou unidade..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        <div className="col-auto ml-auto">
          <Button inputFilled handleClick={openModalToRegisterWorkshop}>
            + Adicionar oficina
          </Button>
        </div>
      </div>

      <Table
        titles={Titles}
        dataOfTable={dataOfTable}
        keysOfObjectsData={listOfKeys}
        notColapse
        notColapseFunction={openModalToDelete}
        editButton={item => _setDataToEdit(item)}
        dataKey="id"
        functionForPagination={setValuePagination}
        itemsPerPage={itemsPerPage}
        shouldResetPagination={shouldResetPagination}
      />
    </>
  );
}

export default withRouter(Workshops);
