import { Types } from '../types';

export function retrieveUnitsByCountryStatesRequest(authToken, countryStates) {
  return {
    type: Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_REQUEST,
    payload: { authToken, countryStates },
  };
}

export function retrieveUnitsByCountryStatesSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveUnitsByCountryStatesFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_FAILURE,
    payload: errorMsg,
  };
}

export function retrieveUnitsByCountryStatesReset() {
  return {
    type: Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_RESET,
  };
}
