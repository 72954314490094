import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Api } from '../../../services/api';
import { AuthRetrieveUserSelector, AuthTokenSelector } from '../../../Redux/Auth/selector';

import PropTypes from 'prop-types';
import { NewComment } from '../../../components/NewComment';

import { Comment } from '../../../components/Comment';
import icBack from '../../../assets/images/ic_back.svg';
import icLoading from '../../../assets/images/ic_loading.svg';

import {
  Container,
  Section,
  HeaderClass,
  BackButtom,
  ClassTitle,
  Loading,
  SubTitle,
} from './styles'


function Comments(props) {

  const authToken = useSelector(AuthTokenSelector);
  const user = useSelector(AuthRetrieveUserSelector);
  const isOwnerClass = user.id === props.class.teacher.userId;

  const [comments, setComments] = useState(undefined);
  const [teacherNotes, setTeacherNotes] = useState(undefined);

  useEffect(() => {
    getAllComments()
  }, []);

  const requestConfig = {

    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer '.concat(authToken),
    },
  };

  const getAllComments = () => {
    try {
      Api.get(`/classesComments/${props.class.id}`, { ...requestConfig, params: { limit: 1000 } })
        .then(response => {
          setTeacherNotes(response.data.teacherNotes);
          setComments(response.data.rows);
        })
    } catch (error) { console.log(error.response); }
  }

  const getTeacherNotes = () => {
    setTeacherNotes(undefined);
    try {
      Api.get(`/classesComments/${props.class.id}`, requestConfig)
        .then(response => setTeacherNotes(response.data.teacherNotes))
    } catch (error) { console.log(error.response); }
  }

  const getOtherComments = async () => {
    setComments(undefined);
    try {
      Api.get(`/classesComments/${props.class.id}`, requestConfig)
        .then(response => {
          setComments(response.data.rows);
        });
    } catch (error) { }
  }

  const renderTeacherNotes = () => {
    if (!teacherNotes.length > 0)
      return (
        <div
          style={{ alignItems: 'center', fontSize: 12, color: 'gray', marginLeft: 20, marginRight: 20, marginBottom: 20 }}
        >
          {user.role === 'teacher'
            ? 'Você ainda não postou nenhum comentário.'
            : 'Nenhum comentário do professor.'
          }

        </div>
      )

    return (
      <>
        {teacherNotes.map((item) =>
          <Comment
            item={item}
            delete={isOwnerClass}
            answer={false}
            handleDelete={(commentId) => handleDeleteTeacherNote(commentId)}
            handleNewComment={() => handleNewComment}
          />
        )}
      </>
    )
  }

  const renderComments = () => {
    if (!comments.length > 0)
      return (
        <div style={{ alignItems: 'center', fontSize: 12, color: 'gray', marginLeft: 20, marginRight: 20 }}>Essa aula ainda não possui comentários.</div>)

    return (
      <>
        {comments.map((item) =>
          <Comment
            item={item}
            delete={isOwnerClass}
            answer={isOwnerClass}
            handleDelete={(commentId) => handleDelete(commentId)}
            handleNewComment={(message, commentId) => handleNewComment(message, commentId)}
          />)
        }
      </>
    )
  }

  const handleDeleteTeacherNote = (commentId) => {
    try {
      Api.delete(`/classesComments/${commentId}`, requestConfig)
        .then(getTeacherNotes());
    } catch (error) { console.log(error.response); }
  }

  const handleDelete = (commentId) => {
    try {
      Api.delete(`/classesComments/${commentId}`, requestConfig)
        .then((response) => {
          getOtherComments()
        });
    } catch (error) { console.log(error.response); }

  }

  const handleNewComment = (message, commentId) => {
    let data = { "message": message, "classId": props.class.id };
    if (commentId) data = { ...data, "classesCommentId": commentId };
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(authToken),
      },
    };

    try {
      Api.post('/classesComments', data, requestConfig)
        .then(() => commentId ? getOtherComments() : getTeacherNotes());
    } catch (error) { }
  }

  return (
    <div style={Container}>
      <div style={Section}>
        <div style={HeaderClass}>
          <img
            style={BackButtom}
            src={icBack}
            alt={'Voltar'}
            onClick={() => props.closeModal()}
          />
          <div style={ClassTitle}>
            {props.class.title}
          </div>
        </div>
        {user.role === 'teacher' &&
          <NewComment
            handleNewComment={(message, commentId = undefined) => handleNewComment(message, commentId)}
          />
        }
        <div style={{ marginLeft: 22, marginTop: 40 }}>
          <div style={SubTitle}> {user.id === props.class.teacher.userId ? 'Meus comentários' : 'Comentários do professor'} </div>
          {teacherNotes
            ? renderTeacherNotes()
            : <div style={Loading}>
              <img src={icLoading} alt="icone circular, movimentando-se em circulos." height={40} />
            </div>
          }
        </div>
      </div>
      <div style={Section}>
        <div style={SubTitle}> Comentários dos alunos </div>
        {comments
          ? renderComments()
          : <div style={Loading}>
            <img src={icLoading} alt="icone circular, movimentando-se em circulos." height={40} />
          </div>
        }
      </div>
    </div>
  );
}

Comments.defaultProps = {
  class: null,
  closeModal: () => { },
};

Comments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  class: PropTypes.object,
  closeModal: PropTypes.func,
};

export default Comments;
