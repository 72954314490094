import React, { useEffect } from 'react';
import './NotifyAction.scss';

import icX from '../../assets/images/ic_x.svg';
import { NotifyState, NotifyDispatch } from '../../contexts/NotifyContext';

function NotifyAction() {
  const { contentNotify, typeNotify } = NotifyState();
  const notifyDispatch = NotifyDispatch();
  const closeModal = () => notifyDispatch({ type: '' });
  useEffect(() => {
    setTimeout(closeModal, 7000);
  }, []);
  return (
    <span className={`w-100 notify notify--${typeNotify}`} title="Clique para fechar" onClick={closeModal}>
      <span className="notify__closeBtn">
        <img src={icX} alt="icon Of X" />
      </span>
      {contentNotify}
    </span>
  );
}

export default NotifyAction;
