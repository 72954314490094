import { Types } from '../types';

export function deleteEventRequest(id, authToken, moderatorPassword) {
  return {
    type: Types.DELETE_EVENT_REQUEST,
    payload: { id, authToken, moderatorPassword },
  };
}

export function deleteEventSuccess() {
  return {
    type: Types.DELETE_EVENT_SUCCESS,
  };
}

export function deleteEventFailure(errorMessage) {
  return {
    type: Types.DELETE_EVENT_FAILURE,
    payload: errorMessage,
  };
}

export function deleteEventResetState() {
  return {
    type: Types.DELETE_EVENT_RESET_STATE,
  };
}
