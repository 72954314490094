import { Types } from '../types';

const initialState = {
  limit: 0,
  offset: 0,
  total: 0,
  rows: [],
  isLoading: false,
  hasError: false,
  errorMsg: '',
};

function retrieveUnitsByCountryStatesReducer(state = initialState, action) {
  const { rows, total, offset } = action.payload || initialState;
  switch (action.type) {
    case Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMsg: '',
      };
    case Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_SUCCESS:
      return {
        ...state,
        rows,
        total,
        offset,
        isLoading: false,
        hasError: false,
        errorMsg: '',
      };
    case Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.RETRIEVE_UNITS_BY_COUNTRY_STATES_RESET:
      return initialState;
    default:
      return state;
  }
}

export { retrieveUnitsByCountryStatesReducer };
