import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MultiSelect from 'react-select';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { UnitssRetrieveAllSelectors } from '../../../Redux/Units/selectors';
import { WorkshopsRetrieveAllSelectors } from '../../../Redux/Workshop/selectors';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';

import { createWorkshopRequest } from '../../../Redux/Workshop/Create/actions';
import { updateWorkshopRequest } from '../../../Redux/Workshop/Update/actions';

import { ModalDispatch } from '../../../contexts/ModalContext';
import { BUTTON_CLASSES } from '../../../utils/enums';
function WorkshopsInfo({ idWorkshop, useForEdit, closeModal }) {
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [site, setSite] = useState();

  const [nameError, setNameError] = useState('');
  const [siteError, setSiteError] = useState('');

  const sitesSelector = useSelector(UnitssRetrieveAllSelectors).rows.map(val => {
    const { id: value, name: label } = val;
    return { value, label };
  });

  const textButton = useForEdit ? 'Atualizar' : 'Adicionar';

  const authToken = useSelector(AuthTokenSelector);

  const areFieldsValid = () => {
    if (!name) setNameError('Nome é obrigatório!');
    if (!site || (site && site.value === undefined)) setSiteError('É obrigatório selecionar uma unidade!');

    if (!name || !site || (site && site.value === undefined)) return false;

    setNameError('');
    setSiteError('');
    return true;
  };

  function createWorkshop(e) {
    e.preventDefault();

    if (!areFieldsValid()) return false;

    const parameters = {
      data: site && site.value ? { name, siteId: site.value } : { name },
      authToken,
    };
    dispatch(createWorkshopRequest(parameters));
    closeModal();
  }

  function updateWorkshop(e) {
    e.preventDefault();

    if (!areFieldsValid()) return false;

    const parameters = {
      id: idWorkshop,
      data: site && site.value ? { name, siteId: site.value } : { name },
      authToken,
    };
    dispatch(updateWorkshopRequest(parameters));
    closeModal();
  }

  const finalDispatch = useForEdit ? updateWorkshop : createWorkshop;

  function startComponent() {
    if (useForEdit) {
      const { name: Name, siteId } = workshops.rows.find(val => val.id === idWorkshop);

      const selectedSiteFilter = sitesSelector.filter(site => site.value === siteId);
      const selectedSite = selectedSiteFilter[0];

      setName(Name);
      setSite(selectedSite);
    }
  }

  useEffect(() => {
    startComponent();
  }, []);

  const workshops = useSelector(WorkshopsRetrieveAllSelectors);

  return (
    <>
      <div className="mt-4 row">
        <form onSubmit={finalDispatch} className="col-12">
          <div className="row" style={{ height: '24em' }}>
            <div className="col-4 mr-4">
              <label htmlFor="name">Escolha um título</label>
              <Input id="name" inputValue={name} setInputValue={setName} required maxLength={40} />
              {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
            </div>
            <div className="col-4 mr-4">
              <label htmlFor="name">Unidade</label>
              <MultiSelect placeholder="Selecione..." value={site} onChange={setSite} options={sitesSelector} />
              {siteError && <p style={{ color: 'red' }}>{siteError}</p>}
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4 mb-4">
              <Button
                classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} col-2 mr-3`}
                type="button"
                handleClick={closeModal}
              >
                Cancelar
              </Button>

            <Button inputFilled type="submit" classes={`${BUTTON_CLASSES.NO_AWAIT} col-2`}>
              {textButton}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

WorkshopsInfo.propTypes = {
  idWorkshop: PropTypes.number,
  useForEdit: PropTypes.bool,
  closeModal: PropTypes.func,
};

WorkshopsInfo.defaultProps = {
  idWorkshop: 0,
  useForEdit: false,
  closeModal: () => {},
};

export default WorkshopsInfo;
