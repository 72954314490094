import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DeleteModalStyles as Styles } from '../../../components/Modal';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

import { ModalDispatch } from '../../../contexts/ModalContext';
import { BUTTON_CLASSES } from '../../../utils/enums';

function ModalToJoinEvent({ actionIfYes }) {
  const modalDispatch = ModalDispatch();

  const [isModerator, setIsModerator] = useState();
  const [password, setPassword] = useState();

  return (
    <Modal size="md" alignHorizontal="center" centralizeVertical>
      <div style={Styles.Container}>
        <div>
          <p style={{fontSize: 16}}>
            Entrar como:
          </p>

        <div onChange={({ target: { value }}) => setIsModerator(value)}>
          <input type="radio" value={true} name="userType" /> Moderador
          <span className="mr-4"></span>
          <input type="radio" value={false} name="userType" /> Participante
        </div>

          <div className="mt-2">
            <label htmlFor="name">Digite a senha:</label>
            <Input
              id="password"
              inputValue={password}
              typeInput="password"
              setInputValue={setPassword}
              required
            />
          </div>
        </div>

        <div className="mt-5 mb-4 d-flex justify-content-center">
          <div className="col-md-4">
            <Button
              inputFilled
              type="button"
              handleClick={() => modalDispatch({ type: '' })}
              classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
            >
              Não
            </Button>
          </div>
          <div className="col-md-4">
            <Button 
              inputFilled 
              type="button"
              disabled={isModerator === undefined || !password}
              classes={isModerator === undefined || !password ? BUTTON_CLASSES.AWAIT : BUTTON_CLASSES.NO_AWAIT}
              handleClick={() => actionIfYes(password, isModerator)}
            >
              Sim
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalToJoinEvent.propTypes = {
  actionIfYes: PropTypes.string.isRequired,
};

export default ModalToJoinEvent;
