import { Types } from '../types';

export function deleteTeacherRequest({ id, authToken }) {
  return {
    type: Types.DELETE_TEACHER_REQUEST,
    payload: { id, authToken },
  };
}

export function deleteTeacherSuccess() {
  return {
    type: Types.DELETE_TEACHER_SUCCESS,
  };
}

export function deleteTeacherFailure(errorMessage) {
  return {
    type: Types.DELETE_TEACHER_FAILURE,
    payload: errorMessage,
  };
}

export function deleteTeacherReset() {
  return {
    type: Types.DELETE_TEACHER_RESET_STATE,
  };
}
