import { call, put } from 'redux-saga/effects';

import { getSchoolsByCountryStateNCity } from '../../client/schools';

import { retrieveSchoolsSuccess, retrieveSchoolsFailure } from '../../Redux/Schools/Retrieve/actions';

export function* retrieveSchoolByCountryStateNCitySaga(action) {
  try {
    const { countryState, city, authToken } = action.payload;
    const response = yield call(getSchoolsByCountryStateNCity, countryState, city, authToken);
    yield put(retrieveSchoolsSuccess(response));
  } catch (err) {
    yield put(retrieveSchoolsFailure(err.message));
  }
}
