import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const defaultValue = {
  modalIsOpen: false,
  contentModal: null,
};

const StateModalContext = createContext(defaultValue);
const DispatchModalContext = createContext();

function modalIsOpen(state, action) {
  switch (action.type) {
    case 'open': {
      return {
        ...state.defaultValue,
        modalIsOpen: true,
        contentModal: action.payload,
      };
    }
    default:
      return {
        ...defaultValue,
        modalIsOpen: true,
        contentModal: null,
      };
  }
}

function ModalProvider({ children }) {
  const [state, dispatch] = useReducer(modalIsOpen, defaultValue);

  return (
    <StateModalContext.Provider value={state}>
      <DispatchModalContext.Provider value={dispatch}>{children}</DispatchModalContext.Provider>
    </StateModalContext.Provider>
  );
}

const ModalState = () => useContext(StateModalContext);

const ModalDispatch = () => useContext(DispatchModalContext);

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { ModalProvider, ModalState, ModalDispatch };
