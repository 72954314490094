import { call, put } from 'redux-saga/effects';

import { forgetPasswordRequest } from '../../client/forgetPassword';
import { forgetPasswordSuccess, forgetPasswordFailure } from '../../Redux/ForgetPassword/actions';

export function* forgetPasswordSaga({ payload }) {
  try {
    const { email } = payload;

    yield call(forgetPasswordRequest, { email });
    yield put(forgetPasswordSuccess());
  } catch (err) {
    let errorMessage = '';
    if (err && err.response && err.response.data) {
      errorMessage = setErrorMessage(err.response.data);
    } else {
      errorMessage = setErrorMessage(err);
    }
    yield put(forgetPasswordFailure(errorMessage));
  }
}

function setErrorMessage(errResponse) {
  const { status } = errResponse;

  if (status === 401 || status === 404) {
    return 'Esse usuário não existe ou não é um administrador!';
  }
  if (errResponse === 'Database Record Not Found') {
    return 'O e-mail informado não está associado a nenhuma conta!';
  }
  return 'Falha ao conectar. Por favor, verifique sua conexão com a Internet e tente novamente.';
}
