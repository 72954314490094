import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import './StudentsInfo.scss';

import { Select } from '../../../components/Select';
import Thumbnail from '../../../components/Thumbnail';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import listOfState from '../../../utils/listOfState';
import { maskToCpf } from '../../../utils/maskToInputs';

import { StudentsRetrieveAllSelectors, StudentUpdateLoading } from '../../../Redux/Students/selectors';
import { AuthRetrieveUserSelector, AuthTokenSelector } from '../../../Redux/Auth/selector';

import { updateStudentRequest } from '../../../Redux/Students/Update/actions';

import { retriveCitiesOfStateReducerRequest } from '../../../Redux/Cities/Retrieve/actions';
import { RetrieveCitiesSelectors } from '../../../Redux/Cities/selectors';

import { retrieveSchoolsRequest } from '../../../Redux/Schools/Retrieve/actions';
import { retrieveSchoolsSelectors } from '../../../Redux/Schools/selectors';

import { BUTTON_CLASSES } from '../../../utils/enums';

import undefinedUserBase64 from '../../../assets/images/undefinedUserBase64';
import { getProfilePicture } from '../../../client/users';

import { ResetPassword } from '../ResetPassword';
function StudentsInfo({ idStudent, closeModal }) {
  const dispatch = useDispatch();

  const user = useSelector(AuthRetrieveUserSelector);

  const [avatar, setAvatar] = useState(undefinedUserBase64);
  const [onHoover, setOnHover] = useState(false);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [schoolName, setSchoolName] = useState();
  const [isPublicSchool, setIsPublicSchool] = useState();
  const [countryState, setCountryState] = useState('AC');
  const [city, setCity] = useState('Acrelândia');
  const [cpf, setCpf] = useState();

  const [resetPassword, setResetPassword] = useState();

  const updateLoading = useSelector(StudentUpdateLoading);

  function getParameters() {
    let Cpf = '';

    if (cpf) {
      Cpf = cpf.replace(/\./g, '').replace(/-/g, '');
    }

    return {
      data: {
        name,
        email,
        schoolName,
        isPublicSchool: isPublicSchool === 'Pública',
        state: countryState,
        city,
        cpf: Cpf,
        avatar: avatar && avatar !== undefinedUserBase64 ? avatar.replace(/^data:image\/png;base64,/, '') : null,
      },
      authToken,
    };
  }

  function updateStudent(e) {
    e.preventDefault();
    const parameters = getParameters();
    parameters.id = idStudent;
    dispatch(updateStudentRequest(parameters));
    closeModal();
  }

  async function startComponent() {
    const result = students.rows.find(val => val.userId === idStudent);

    setName(result.name);
    setEmail(result.username);
    setSchoolName(result.schoolName);
    setIsPublicSchool(result.isPublicSchool === 1 ? 'Pública' : 'Privada');
    setCpf(result.cpf);
    setCountryState(result.state);
    setCity(result.city);

    const profilePicture = await getProfilePicture(result.profilePicture, authToken);
    setAvatar('data:image/png;base64,' + profilePicture.data);
  }

  function getCities() {
    dispatch(retriveCitiesOfStateReducerRequest(countryState, authToken));
  }

  const finalDispatch = updateStudent;

  useEffect(() => {
    startComponent();
    return setAvatar(null);
  }, []);

  useEffect(() => {
    getCities();
  }, [countryState]);

  useEffect(() => {
    dispatch(retrieveSchoolsRequest(countryState, city, authToken));
  }, [countryState, city]);

  const students = useSelector(StudentsRetrieveAllSelectors);
  const authToken = useSelector(AuthTokenSelector);
  const cities = useSelector(RetrieveCitiesSelectors).sort();
  const schools = useSelector(retrieveSchoolsSelectors);

  const handleRemoveAvatar = () => {
    setAvatar(null);
  };

  const handleResetPassword = () => {
    setResetPassword(true);
  }

  return (
    <>
      <h2 className="font-weight-bold text-dark">{name}</h2>
      {!resetPassword ?
        <>
          <div>
            <div className="avatarContainer" onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
              {avatar && onHoover && (
                <div className="avatarRemove" onClick={() => handleRemoveAvatar()}>
                  Excluir
                </div>
              )}

              <Thumbnail alt="foto do aluno" image={avatar} height="104px" />
            </div>
          </div>

          <div className="mt-4 row">
            <form onSubmit={finalDispatch} className="col-12">
              <>
                <div className="row">
                  <div className="col-3 mr-4">
                    <label htmlFor="name">Nome</label>
                    <Input id="name" inputValue={name} setInputValue={setName} required maxLength={40} />
                  </div>

                  <div className="col-3 mr-4">
                    <label htmlFor="cpf">CPF</label>
                    <Input
                      id="cpf"
                      placeholder="_ _ _ . _ _ _ . _ _ _ - _ _ "
                      inputValue={maskToCpf(cpf)}
                      typeInput="text"
                      setInputValue={setCpf}
                      maxLength={14}
                    />
                  </div>

                  <div className="col-3 mr-4">
                    <label htmlFor="email">Email</label>
                    <Input id="email" inputValue={email} typeInput="email" setInputValue={setEmail} required />
                  </div>
                </div>

                <div className="row mt-4 mb-4">
                  <div className="col-3 mr-4">
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="state">Estado</label>
                        <br />
                        <Select valueOfSelect={countryState} setValueOfSelect={setCountryState}>
                          {listOfState.map(val => (
                            <option key={val.initials} value={val.initials}>
                              {val.name}
                            </option>
                          ))}
                        </Select>
                      </div>

                      <div className="col-6">
                        <label htmlFor="city">Cidade</label>
                        <br />
                        <Select valueOfSelect={city} setValueOfSelect={setCity} deactivate={!cities.length > 0}>
                          {cities.map(val => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <label htmlFor="schoolName">Escola</label>
                    <Select valueOfSelect={schoolName} setValueOfSelect={setSchoolName} deactivate={!schools.data.length > 0}>
                      {schools.data.map((item, index) => {
                        const { schoolName: itemSchoolName, name: itemName } = item;
                        const valueSchoolName = itemSchoolName || itemName;
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <option key={index} value={valueSchoolName}>
                            {valueSchoolName}
                          </option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="col-3 ml-4">
                    <label htmlFor="city">Escola Pública ou Privada</label>
                    <br />
                    <Select valueOfSelect={isPublicSchool} setValueOfSelect={setIsPublicSchool}>
                      <option key="Pública" value="Pública">
                        Pública
                </option>
                      <option key="Privada" value="Privada">
                        Privada
                </option>
                    </Select>
                  </div>
                </div>
              </>
              <div className="d-flex justify-content-end mt-6 mb-1">
                {user.role === 'mentor'
                  ?
                  <div className="col-6">
                    <Button handleClick={handleResetPassword} type="button" classes={`${BUTTON_CLASSES.NO_AWAIT} col-5`}>
                      Resetar Senha
                  </Button>
                  </div>
                  :
                  <div className="col-6"></div>
                }
                <div className="col-3">
                  <Button handleClick={closeModal} type="button" classes={BUTTON_CLASSES.AWAIT_WHITE}>
                    Cancelar
                  </Button>
                </div>
                <div className="col-3">
                  <Button type="submit" classes={updateLoading ? BUTTON_CLASSES.AWAIT : BUTTON_CLASSES.NO_AWAIT}>
                    Atualizar
                  </Button>
                </div>
              </div>
            </form>

          </div>
        </>
        :
        <ResetPassword
          id={idStudent}
          cancelUpdate={() => setResetPassword(false)}
          closeModal={closeModal}
        />}
    </>
  );
}

StudentsInfo.propTypes = {
  idStudent: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
};

StudentsInfo.defaultProps = {
  idStudent: 0,
};

export default StudentsInfo;
