export const Container = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: 350,
  marginBottom: 10,
}

export const Avatar = {
  width: 45,
  height: 45,
  borderRadius: 10,
  marginRight: 8,
}

export const CommentContainer = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  width: '60%',
}

export const Header = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 5,
}

export const Name = {
  color: '#00BDF7',
  fontSize: 10,
  marginRight: 8,
  fontFamily: 'Rubik-Medium',
}

export const DateStyle = {
  color: '#696969',
  fontSize: 9,
  fontFamily: 'Rubik-Regular',
}

export const Message = {
  color: '#696969',
  fontSize: 12,
  fontFamily: 'Rubik-Regular',
}

export const Tools = {
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 10,
  width: '20%',
}

export const Icon = {
  height: 25,
  width: 25,
  borderRadius: 5,
  backgroundColor: '#00BDF7',
  margin: 5,
  padding: 5,
  cursor: 'pointer',
}

export const TeacherAnswer = {
  marginTop: 8,
  marginBottom: 8,
  marginLeft: 8,
  display: 'flex',
  flexDirection: 'row',
  fontSize: 10,
  color: '#FF795B',
  alignItems: 'center'
}
