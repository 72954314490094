import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/dashboard';

function getDashBoard(from, to, authToken, signal) {
  return Api.get(route, {
    headers: commonHeaders(authToken),
    params: { from, to },
    signal,
  });
}

export { getDashBoard };
