import { combineReducers } from 'redux';

import { createMentorReducer } from './Create/reducer';
import { retrieveMentorsRowsTotalOffsetReducer } from './Retrieve/reducer';
import { updateMentorReducer } from './Update/reducer';
import { deleteMentorReducer } from './Delete/reducer';

const reducer = combineReducers({
  createMentorReducer,
  retrieveMentorsRowsTotalOffsetReducer,
  updateMentorReducer,
  deleteMentorReducer,
});

export { reducer as crudMentorsReducers };
