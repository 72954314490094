import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalToDeleteClass } from './ModalToDeleteClass';
import { ModalContentClass } from './ModalContentsClass';
import Button from '../../components/Button';
import { BUTTON_CLASSES } from '../../utils/enums';
import { ModalToRegisterClass } from './ModalToRegisterClass';
import { ModalToCommentsClass } from './ModalToCommentsClass';

import { AuthRetrieveUserSelector, AuthTokenSelector } from '../../Redux/Auth/selector';
import {
  ClassesRetrieveAllSelectors,
  ClassesCreateSelector,
  ClassesUpdateSelector,
  ClassDeleteSelectors,
  ClassesSearchSelectors,
} from '../../Redux/Classes/selectors';
import { retrieveClassesRowsTotalOffsetRequest, defineClassesSearchParams } from '../../Redux/Classes/Retrieve/actions';
import { deleteClassRequest, deleteClassResetState } from '../../Redux/Classes/Delete/actions';
import { createClassResetState } from '../../Redux/Classes/Create/actions';
import { updateClassResetState } from '../../Redux/Classes/Update/actions';

import { Table } from '../../components/Table';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { SearchAndFilter } from '../../components/SearchAndFilter';

import { LoadingDispatch } from '../../contexts/LodingContext';
import { ModalDispatch } from '../../contexts/ModalContext';

import { showNotify } from '../../utils/showNotify';

function Classes() {
  const dispatch = useDispatch();
  const loadingDispatch = LoadingDispatch();
  const modalDispatch = ModalDispatch();
  const [valuePagination, setValuePagination] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataToEdit, setDataToEdit] = useState();
  const [dataToComments, setDataToComments] = useState();
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [data, setData] = useState({
    limit: 0,
    offset: 0,
    total: 0,
    rows: [],
    search: '',
    isLoading: false,
    hasError: false,
    errorMsg: '',
  });
  const [searchText, setSearchText] = useState('');

  const createClass = useSelector(ClassesCreateSelector);
  const updateClass = useSelector(ClassesUpdateSelector);
  const classesSearch = useSelector(ClassesSearchSelectors);
  const user = useSelector(AuthRetrieveUserSelector);
  const authToken = useSelector(AuthTokenSelector);
  const dataOfTable = useSelector(ClassesRetrieveAllSelectors);
  const deleteClass = useSelector(ClassDeleteSelectors);

  const abortClassesRetrieveAllRequestController = new AbortController();  

  const Titles = ['Data', 'Modificado em', 'Título', 'Professor', 'Disciplina'];
  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'title' },
    { key: 'teacher_name' },
    { key: 'subject_name' },
  ];

  const limit = itemsPerPage;
  const search = classesSearch;

  const getItemById = id => dataOfTable.rows.find(item => item.id === id);

  useEffect(() => {
    setComponentLoaded(true);
    return () => {
      dispatch(defineClassesSearchParams({ search: '' }));
      abortClassesRetrieveAllRequestController.abort();
    } 
  }, []);

  useEffect(() => {
    if (dataToEdit) modalDispatch({ type: 'open', payload: <ModalToRegisterClass initialData={dataToEdit} /> });
  }, [dataToEdit]);

  useEffect(() => {
    if (dataToComments)
      modalDispatch({
        type: 'open',
        payload: <ModalToCommentsClass initialData={dataToComments} clearDataOnClose={() => setDataToComments('')} />,
      });
  }, [dataToComments]);

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  function createClassNotify() {
    let contentNotify;
    let typeNotify;

    if (createClass.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao cadastrar a aula.';
    } else if (createClass.success) {
      typeNotify = 'success';
      contentNotify = 'Aula cadastrada';
      dispatch(createClassResetState());
      dispatchDataForTable(valuePagination);
    }
    showNotify(typeNotify, contentNotify);
    modalDispatch({ type: '' });
  }

  function updateClassNotify() {
    let contentNotify;
    let typeNotify;

    if (updateClass.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao atualizar a aula.';
    } else if (updateClass.success) {
      typeNotify = 'success';
      contentNotify = 'Aula atualizada';
      dispatch(updateClassResetState());
      dispatchDataForTable(valuePagination);
    }
    showNotify(typeNotify, contentNotify);
    modalDispatch({ type: '' });
  }

  function deleteAdminNotify() {
    let contentNotify;
    let typeNotify;
    if (deleteClass.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao excluir Aula. Tente novamente :)';
    } else if (deleteClass.deleteSuccess) {
      typeNotify = 'success';
      contentNotify = 'Aula excluída';
      dispatch(deleteClassResetState());
      dispatchDataForTable(valuePagination);
    }
    showNotify(typeNotify, contentNotify);
  }

  function deleteClassByid(id) {
    dispatch(deleteClassRequest(id, authToken));
    modalDispatch({ type: '' });
  }

  function openModalToDelete(id) {
    const { title } = getItemById(id);
    modalDispatch({
      type: 'open',
      payload: (
        <ModalToDeleteClass
          classTitle={title}
          actionIfYes={() => {
            deleteClassByid(id);
          }}
        />
      ),
    });
  }

  function alternativeRowClick(id) {
    const { title, subjects, teacher } = getItemById(id);
    const { name: subjectName, color: subjectColor } = subjects[0];
    const { name: teacherName } = teacher;

    modalDispatch({
      type: 'open',
      payload: (
        <ModalContentClass
          idClass={id}
          classTitle={title}
          subject={subjectName}
          subjectColor={subjectColor}
          teacherName={teacherName}
          deleteFunction={() => deleteClassByid(id)}
        />
      ),
    });
  }

  function openModalToRegisterClass() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterClass /> });
  }

  async function _setDataToEdit(item) {
    if (dataToEdit?.id === item.id)
      modalDispatch({ type: 'open', payload: <ModalToRegisterClass initialData={item} /> });
    else setDataToEdit(item);
  }

  function dispatchDataForTable(offset) {
    dispatch(retrieveClassesRowsTotalOffsetRequest(offset, authToken, search, limit, abortClassesRetrieveAllRequestController.signal));
  }

  function handleSearch(event) {
    event.preventDefault();
    const search = searchText ? `keyword=${searchText}` : '';
    dispatch(defineClassesSearchParams({ search }));
  }

  deleteClass.isLoading ? loadingDispatch({ type: 'open' }) : loadingDispatch({ type: '' });
  (deleteClass.deleteSuccess || deleteClass.hasError) && deleteAdminNotify();

  useEffect(() => {
    if (dataOfTable) {
      const { rows } = dataOfTable;
      const formatedRows = rows.map(item => {
        return {
          ...item,
          teacher_name: item.teacher.name,
          subject_name: item?.subjects[0]?.name,
        };
      });
      setData({ ...dataOfTable, rows: formatedRows });
    }
  }, [dataOfTable]);

  return (
    <>
      {(createClass.success || createClass.hasError) && createClassNotify()}
      {(updateClass.success || updateClass.hasError) && updateClassNotify()}
      <div className="row mb-2">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Aulas</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center mb-2">
          <SearchAndFilter
            haveFilter={false}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            inputPlaceholder="Pesquisar por título..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        <div className="col-auto">
          {user.role !== 'mentor' && (
            <Button handleClick={openModalToRegisterClass} classes={BUTTON_CLASSES.NO_AWAIT}>
              + Adicionar aula
            </Button>
          )}
        </div>
      </div>

      {data && (
        <Table
          titles={Titles}
          dataOfTable={data}
          itemsPerPage={itemsPerPage}
          keysOfObjectsData={listOfKeys}
          shouldResetPagination={shouldResetPagination}
          dataKey="id"
          functionForPagination={setValuePagination}
          notColapse
          notColapseFunction={openModalToDelete}
          alternativeRowClick={alternativeRowClick}
          editButton={item => _setDataToEdit(item)}
          commentsButton={setDataToComments}
        />
      )}
    </>
  );
}

export default Classes;
