import { Types } from '../types';

export function retrieveClassContentRequest(id, authToken) {
  return {
    type: Types.RETRIEVE_CLASS_CONTENT_REQUEST,
    payload: { id, authToken },
  };
}

export function retrieveClassContentSuccess(data) {
  return {
    type: Types.RETRIEVE_CLASS_CONTENT_SUCCESS,
    payload: { data },
  };
}

export function retrieveClassContentfailure(errMessage) {
  return {
    type: Types.RETRIEVE_CLASS_CONTENT_FAILURE,
    payload: errMessage,
  };
}

export function retrieveClassContentReset() {
  return {
    type: Types.RETRIEVE_CLASS_CONTENT_RESET_STATE,
  };
}
