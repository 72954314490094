import Axios from 'axios';

const Api = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_BASE_URL,
});

export { Api };
