export const Types = {
  DEFINE_UNITS_SEARCH_PARAMS: '[unitss]DEFINE_UNITS_SEARCH_PARAMS',
  CREATE_UNITS_REQUEST: '[unitss]CREATE_UNITS_REQUEST',
  CREATE_UNITS_SUCCESS: '[unitss]CREATE_UNITS_SUCCESS',
  CREATE_UNITS_FAILURE: '[unitss]CREATE_UNITS_FAILURE',
  CREATE_UNITS_RESET_STATE: '[unitss]CREATE_UNITS_RESET_STATE', // TODO - need refactory.
  RETRIEVE_ALL_UNITS_REQUEST: '[unitss]RETRIEVE_ALL_UNITS_REQUEST',
  RETRIEVE_ALL_UNITS_SUCCESS: '[unitss]RETRIEVE_ALL_UNITS_SUCCESS',
  RETRIEVE_ALL_UNITS_FAILURE: '[unitss]RETRIEVE_ALL_UNITS_FAILURE',
  RETRIEVE_ALL_UNITS_RESET: '[unitss]RETRIEVE_ALL_UNITS_RESET',
  RETRIEVE_UNITS_BY_COUNTRY_STATES_REQUEST: '[unitss]RETRIEVE_UNITS_BY_COUNTRY_STATES_REQUEST',
  RETRIEVE_UNITS_BY_COUNTRY_STATES_SUCCESS: '[unitss]RETRIEVE_UNITS_BY_COUNTRY_STATES_SUCCESS',
  RETRIEVE_UNITS_BY_COUNTRY_STATES_FAILURE: '[unitss]RETRIEVE_UNITS_BY_COUNTRY_STATES_FAILURE',
  RETRIEVE_UNITS_BY_COUNTRY_STATES_RESET: '[unitss]RETRIEVE_UNITS_BY_COUNTRY_STATES_RESET',
  UPDATE_UNITS_REQUEST: '[unitss]UPDATE_UNITS_REQUEST',
  UPDATE_UNITS_SUCCESS: '[unitss]UPDATE_UNITS_SUCCESS',
  UPDATE_UNITS_FAILURE: '[unitss]UPDATE_UNITS_FAILURE',
  UPDATE_UNITS_RESET_STATE: '[unitss]UPDATE_UNITS_RESET_STATE',
  DELETE_UNITS_REQUEST: '[unitss]DELETE_UNITS_REQUEST',
  DELETE_UNITS_SUCCESS: '[unitss]DELETE_UNITS_SUCCESS',
  DELETE_UNITS_FAILURE: '[unitss]DELETE_UNITS_FAILURE',
  DELETE_UNITS_RESET_STATE: '[unitss]DELETE_UNITS_RESET_STATE',
};
