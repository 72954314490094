import React from 'react';
import './LoadingContent.scss';
import icLoading from '../../../../assets/images/ic_loading.svg';

function LoadingContent() {
  return (
    <div className="containerLoading mb-4">
      <img src={icLoading} alt="Loading contents" height={68} />
    </div>
  );
}

export default LoadingContent;
