import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Api } from '../../services/api';
import { ModalToDeleteEvent } from './ModalToDeleteEvent';
import Button from '../../components/Button';
import { BUTTON_CLASSES } from '../../utils/enums';
import { ModalToRegisterEvent } from './ModalToRegisterEvent';

import { AuthRetrieveUserSelector, AuthTokenSelector } from '../../Redux/Auth/selector';
import {
  EventsRetrieveAllSelectors,
  EventsCreateSelector,
  EventsUpdateSelector,
  EventsDeleteSelectors,
  EventsSearchSelectors,
} from '../../Redux/Events/selectors';
import { retrieveEventsRowsTotalOffsetRequest, defineEventsSearchParams } from '../../Redux/Events/Retrieve/actions';
import { deleteEventRequest, deleteEventResetState } from '../../Redux/Events/Delete/actions';
import { createEventResetState } from '../../Redux/Events/Create/actions';
import { updateEventResetState } from '../../Redux/Events/Update/actions';

import { Table } from '../../components/Table';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { SearchAndFilter } from '../../components/SearchAndFilter';

import { LoadingDispatch } from '../../contexts/LodingContext';
import { ModalDispatch } from '../../contexts/ModalContext';

import { showNotify } from '../../utils/showNotify';
import { ModalToJoinEvent } from './ModalToJoinEvent';


function Events() {
  const dispatch = useDispatch();
  const loadingDispatch = LoadingDispatch();
  const modalDispatch = ModalDispatch();
  const [valuePagination, setValuePagination] = useState(0);
  const [showNotifyCopy, setShowNotifyCopy] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataToEdit, setDataToEdit] = useState();
  const [submitSuccessToastText, setSubmitSuccessToastText] = useState('');
  const [submitErrorToastText, setSubmitErrorToastText] = useState('');
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [searchText, setSearchText] = useState('');

  const createEvent = useSelector(EventsCreateSelector);
  const updateEvent = useSelector(EventsUpdateSelector);
  const deleteEvent = useSelector(EventsDeleteSelectors);
  const eventsSearch = useSelector(EventsSearchSelectors);
  const user = useSelector(AuthRetrieveUserSelector);
  const authToken = useSelector(AuthTokenSelector);

  const abortEventsRetrieveAllRequestController = new AbortController();

  const Titles = ['Data de registro', 'Modificado em', 'Data do evento', 'Horario do evento', 'Nome', 'Organizador'];
  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'eventDate', isDate: true },
    { key: 'eventTime' },
    { key: 'name' },
    { key: 'username' },
  ];

  function copyEventNotify() {
    const typeNotify = 'success';
    const contentNotify = 'Evento copiado.';
    if (setShowNotifyCopy) {
      showNotify(typeNotify, contentNotify);
      setShowNotifyCopy(false);
    }
  }
  const handleCopy = useCallback(
    ({ recordLink, status, id, ...item }) => {
      const insertNavigatorCopy = text => navigator.clipboard.writeText(text);
      if (recordLink && status === 'published') {
        insertNavigatorCopy(recordLink);
        setShowNotifyCopy(true);
        return;
      }
      if (status === 'started') {
        insertNavigatorCopy(
          `${process.env.REACT_APP_APPSFLYER_BASE_URL}?af_dp=appestudogf://app/Events/?eventId=${id}`
        );
        setShowNotifyCopy(true);
        return;
      }
    },
    [showNotify]
  );

  const [data, setData] = useState({
    limit: 0,
    offset: 0,
    total: 0,
    rows: [],
    search: '',
    isLoading: false,
    hasError: false,
    errorMsg: '',
  });

  useEffect(() => {
    return () => {
      dispatch(defineEventsSearchParams({ search: '' }));
      abortEventsRetrieveAllRequestController.abort();
    };
  }, []);

  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer '.concat(authToken),
    },
  };

  const submitErrorNotify = () => {
    const typeNotify = 'error';
    const contentNotify = submitErrorToastText;

    showNotify(typeNotify, contentNotify);

    setSubmitErrorToastText('');
  };

  const submitSuccessNotify = () => {
    const typeNotify = 'success';
    const contentNotify = submitSuccessToastText;

    showNotify(typeNotify, contentNotify);

    setSubmitSuccessToastText('');
  };

  const getItemById = id => dataOfTable.rows.find(item => item.id === id);

  const startEvent = async ({ id }) => {
    try {
      await Api.get(`/events/start/${id}`, requestConfig);
      dispatchDataForTable(valuePagination);
    } catch (err) {
      setSubmitErrorToastText('Ocorreu um erro ao iniciar o evento');
    }
  };

  const joinEvent = async ({ id, password, isModerator }) => {
    try {
      await startEvent({ id });

      const { data: eventUrl } = await Api.post(
        `/events/join/${id}`,
        {
          fullName: user.name ?? `${(Math.random() + 1).toString(36).substring(7)}`,
          password,
          isModerator: JSON.parse(isModerator),
        },
        requestConfig
      );
      modalDispatch({ type: '' });
      window.open(eventUrl, '_blank');
    } catch (err) {
      if (err?.response?.data === "Meeting doesn't exist or is not started") {
        setSubmitErrorToastText('Essa reunião não existe ou ainda não foi iniciada');
        return;
      }

      if (err?.response?.data === 'Unauthorized') {
        setSubmitErrorToastText('Senha incorreta!');
        return;
      }

      setSubmitErrorToastText('Ocorreu um erro ao entrar no evento');
    }
  };

  function createEventNotify() {
    let contentNotify;
    let typeNotify;

    if (createEvent?.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao cadastrar o evento.';

      if (createEvent.errorMsg.includes('ER_DUP_ENTRY')) contentNotify = 'Já existe um evento cadastrado com esse nome';
    } else if (createEvent?.success) {
      typeNotify = 'success';
      contentNotify = 'Evento cadastrado';  
      dispatchDataForTable(valuePagination);
    }
    dispatch(createEventResetState());
    showNotify(typeNotify, contentNotify);
    modalDispatch({ type: '' });
  }

  function updateEventNotify() {
    let contentNotify;
    let typeNotify;

    if (updateEvent.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao atualizar o evento.';

      if (updateEvent.errorMsg === 'Editing is disabled for this event')
        contentNotify = 'Essa reunião já foi iniciada e não pode mais ser editada';

        if (updateEvent.errorMsg.includes('ER_DUP_ENTRY')) contentNotify = 'Já existe um evento cadastrado com esse nome';
    } else if (updateEvent.success) {
      typeNotify = 'success';
      contentNotify = 'Evento atualizado';
      dispatchDataForTable(valuePagination);
    }
    dispatch(updateEventResetState());
    showNotify(typeNotify, contentNotify);
    modalDispatch({ type: '' });
  }

  function deleteAdminNotify() {
    let contentNotify;
    let typeNotify;
    if (deleteEvent.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao excluir Evento. Tente novamente :)';

      if (deleteEvent.errorMsg === 'Unauthorized') contentNotify = 'Senha de moderador incorreta!';
    } else if (deleteEvent.deleteSuccess) {
      typeNotify = 'success';
      contentNotify = 'Evento excluído';
      dispatchDataForTable(valuePagination);
    }
    dispatch(deleteEventResetState());
    showNotify(typeNotify, contentNotify);
  }

  function deleteEventByid(id, moderatorPassword) {
    dispatch(deleteEventRequest(id, authToken, moderatorPassword));
    modalDispatch({ type: '' });
  }

  function openModalToJoin({ id }) {
    const { name } = getItemById(id);
    modalDispatch({
      type: 'open',
      payload: (
        <ModalToJoinEvent
          eventName={name}
          requirePassword={user.role !== 'admin'}
          actionIfYes={(password, isModerator) => {
            joinEvent({ id, password, isModerator });
          }}
        />
      ),
    });
  }

  function openModalToDelete(id) {
    const { name } = getItemById(id);
    modalDispatch({
      type: 'open',
      payload: (
        <ModalToDeleteEvent
          eventName={name}
          requirePassword={user.role !== 'admin'}
          actionIfYes={password => {
            deleteEventByid(id, password);
          }}
        />
      ),
    });
  }

  function openModalToRegisterEvent() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterEvent /> });
  }

  async function _setDataToEdit(item) {
    if (dataToEdit?.id === item.id)
      modalDispatch({ type: 'open', payload: <ModalToRegisterEvent initialData={item} /> });
    else setDataToEdit(item);
  }

  function handleSearch(event) {
    event.preventDefault();
    const search = searchText ? `query=${searchText}` : '';
    dispatch(defineEventsSearchParams({ search }));
  }

  useEffect(() => {
    if (dataToEdit) modalDispatch({ type: 'open', payload: <ModalToRegisterEvent initialData={dataToEdit} /> });
  }, [dataToEdit]);

  const limit = itemsPerPage;
  const search = eventsSearch;

  function dispatchDataForTable(offset) {
    dispatch(retrieveEventsRowsTotalOffsetRequest(offset, authToken, search, limit, abortEventsRetrieveAllRequestController.signal));
  }

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  useEffect(() => setComponentLoaded(true), []);

  const dataOfTable = useSelector(EventsRetrieveAllSelectors);

  createEvent.isLoading || updateEvent.isLoading || deleteEvent.isLoading
  ? loadingDispatch({ type: 'open' })
  : loadingDispatch({ type: '' });

  return (
    <>
      {(createEvent?.success || createEvent?.hasError) && createEventNotify()}
      {(updateEvent?.success || updateEvent?.hasError) && updateEventNotify()}
      {(deleteEvent.deleteSuccess || deleteEvent.hasError) && deleteAdminNotify()}

      {showNotifyCopy && copyEventNotify()}
      {submitErrorToastText && submitErrorNotify()}
      {submitSuccessToastText && submitSuccessNotify()}
      <div className="row mb-2">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Eventos</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center mb-2">
          <SearchAndFilter
            haveFilter={false}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            inputPlaceholder="Pesquisar por nome..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        <div className="col-auto">
          <Button handleClick={openModalToRegisterEvent} classes={BUTTON_CLASSES.NO_AWAIT}>
            + Adicionar Evento
          </Button>
        </div>
      </div>

      <Table
        titles={Titles}
        dataOfTable={dataOfTable}
        itemsPerPage={itemsPerPage}
        keysOfObjectsData={listOfKeys}
        shouldResetPagination={shouldResetPagination}
        dataKey="id"
        functionForPagination={setValuePagination}
        notColapse
        type="event"
        notColapseFunction={openModalToDelete}
        startEvent={startEvent}
        joinEvent={openModalToJoin}
        alternativeRowClick={handleCopy}
        editButton={item => _setDataToEdit(item)}
      />
    </>
  );
}

export default Events;
