import { call, put } from 'redux-saga/effects';

import { getDashBoard } from '../../client/dashboard';
import { retrieveAllDashboardSuccess, retrieveAllDashboardFailure } from '../../Redux/Dashboard/Retrieve/actions';

export function* retrieveAllDashboardSaga(action) {
  try {
    const { from, to, authToken, signal } = action.payload;
    const response = yield call(getDashBoard, from, to, authToken, signal);
    yield put(retrieveAllDashboardSuccess(response.data));
  } catch (err) {
    yield put(retrieveAllDashboardFailure(err.message));
  }
}
