import { call, put } from 'redux-saga/effects';

import { getUnitss, getUnitsByCountryState, createUnitss, updateUnits, deleteUnits } from '../../client/unitss';
import {
  retrieveUnitssRowsTotalOffsetSuccess,
  retrieveUnitssRowsTotalOffsetFailure,
} from '../../Redux/Units/Retrieve/actions';
import {
  retrieveUnitsByCountryStatesSuccess,
  retrieveUnitsByCountryStatesFailure,
} from '../../Redux/Units/RetrieveByCountryState/actions';
import { createUnitsSuccess, createUnitsFailure } from '../../Redux/Units/Create/actions';
import { updateUnitsSucess, updateUnitsFailure } from '../../Redux/Units/Update/actions';
import { deleteUnitsSuccess, deleteUnitsFailure } from '../../Redux/Units/Delete/actions';

export function* retrieveUnitssRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getUnitss, offParam, authToken, search, limit, signal);
    const { rows, total, offset } = response.data;
    yield put(retrieveUnitssRowsTotalOffsetSuccess({ rows, total, offset }));
  } catch (err) {
    yield put(retrieveUnitssRowsTotalOffsetFailure(err.message));
  }
}

export function* retrieveUnitsByCountryStateSaga(action) {
  try {
    const { authToken, countryStates } = action.payload;
    const response = yield call(getUnitsByCountryState, authToken, countryStates);
    const { rows, total, offset } = response.data;
    yield put(retrieveUnitsByCountryStatesSuccess({ rows, total, offset }));
  } catch (err) {
    yield put(retrieveUnitsByCountryStatesFailure(err.message));
  }
}

export function* createUnitsSaga(action) {
  try {
    const { data, authToken } = action.payload;
    yield call(createUnitss, data, authToken);
    yield put(createUnitsSuccess());
  } catch (error) {
    error && error.response && error.response.data ?
    yield put(createUnitsFailure(error.response.data)) : 
    yield put(createUnitsFailure(error.message))
  }
}

export function* updateUnitsSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    const response = yield call(updateUnits, id, data, authToken);
    yield put(updateUnitsSucess(response));
  } catch (error) {
    error && error.response && error.response.data ?
    yield put(updateUnitsFailure(error.response.data)) :
    yield put(updateUnitsFailure(error.message));
  }
}

export function* deleteUnitsSaga(action) {
  try {
    const { id, authToken } = action.payload;
    yield call(deleteUnits, id, authToken);
    yield put(deleteUnitsSuccess());
  } catch (error) {
    error && error.response && error.response.data ?
    yield put(deleteUnitsFailure(error.response.data)) : 
    yield put(deleteUnitsFailure(error.message));
  }
}
