import { call, put } from 'redux-saga/effects';

import { getClasses, deleteClass, getClassContentById, createClass, updateClass } from '../../client/classes';

import {
  retrieveClassesRowsTotalOffsetSuccess,
  retrieveClassesRowsTotalOffsetFailure,
} from '../../Redux/Classes/Retrieve/actions';

import {
  retrieveClassContentSuccess,
  retrieveClassContentfailure,
} from '../../Redux/Classes/RetrieveClassContent/actions';

import { createClassSuccess, createClassFailure } from '../../Redux/Classes/Create/actions';
import { deleteClassSuccess, deleteClassFailure } from '../../Redux/Classes/Delete/actions';
import { updateClassSuccess, updateClassFailure } from '../../Redux/Classes/Update/actions';

export function* createClassSaga(action) {
  try {
    const { data, authToken } = action.payload;
    yield call(createClass, data, authToken);
    yield put(createClassSuccess());
  } catch (error) {
    error.response && error.response.data ?
      yield put(createClassFailure(error.response.data)) : 
      yield put(createClassFailure(error.message));
  }
}

export function* updateClassSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    yield call(updateClass, id, data, authToken);
    yield put(updateClassSuccess());
  } catch (error) {
    error.response && error.response.data ?
      yield put(updateClassFailure(error.response.data)) : 
      yield put(updateClassFailure(error.message));
  }
}

export function* retrieveClassesRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getClasses, offParam, authToken, search, limit, signal);
    yield put(retrieveClassesRowsTotalOffsetSuccess(response.data));
  } catch (err) {
    yield put(retrieveClassesRowsTotalOffsetFailure(err.message));
  }
}

export function* retrieveClassContentSaga(action) {
  try {
    const { id, authToken } = action.payload;
    const response = yield call(getClassContentById, id, authToken);
    const { contents } = response.data.rows[0];
    yield put(retrieveClassContentSuccess(contents));
  } catch (err) {
    yield put(retrieveClassContentfailure(err.message));
  }
}

export function* deleteClassByidSaga(action) {
  try {
    const { id, authToken } = action.payload;
    yield call(deleteClass, id, authToken);
    yield put(deleteClassSuccess());
  } catch (err) {
    yield put(deleteClassFailure(err.message));
  }
}
