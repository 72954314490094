import { Types } from '../types';

export function createMentorRequest({ data, authToken }) {
  return {
    type: Types.CREATE_MENTOR_REQUEST,
    payload: { data, authToken },
  };
}

export function createMentorSuccess({ user }) {
  return {
    type: Types.CREATE_MENTOR_SUCCESS,
    payload: { user },
  };
}

export function createMentorFailure(errorMsg) {
  return {
    type: Types.CREATE_MENTOR_FAILURE,
    payload: errorMsg,
  };
}

export function createMentorResetState() {
  return {
    type: Types.CREATE_MENTOR_RESET_STATE,
  };
}
