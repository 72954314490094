import { Types } from '../types';

export function defineStudentsSearchParams({ search }) {
  return {
    type: Types.DEFINE_STUDENTS_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveStudentsRowsTotalOffsetRequest(offset = 0, authToken, search, limit = 10, signal) {
  return {
    type: Types.RETRIEVE_ALL_STUDENTS_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveStudentsRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_STUDENTS_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveStudentsRowsTotalOffsetFailure(errorMessage) {
  return {
    type: Types.RETRIEVE_ALL_STUDENTS_FAILURE,
    payload: errorMessage,
  };
}
