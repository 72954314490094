import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/utils/schools';

function getSchoolsByCountryStateNCity(state, city, authToken) {
  return Api.get(`${route}/${state}/${city}`, {
    headers: commonHeaders(authToken),
  });
}

export { getSchoolsByCountryStateNCity };
