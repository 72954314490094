import { combineReducers } from 'redux';

import { createAdminReducer } from './Create/reducer';
import { retrieveAdminsRowsTotalOffsetReducer } from './Retrieve/reducer';
import { updateAdminReducer } from './Update/reducer';
import { updateSelfReducer } from './UpdatedSelf/reducer';

const reducer = combineReducers({
  createAdminReducer,
  retrieveAdminsRowsTotalOffsetReducer,
  updateAdminReducer,
  updateSelfReducer,
});

export { reducer as crudAdminsReducers };
