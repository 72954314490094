import { Types } from '../types';

export function defineMentorsSearchParams({ search }) {
  return {
    type: Types.DEFINE_MENTORS_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveMentorsRowsTotalOffsetRequest(offset = 0, authToken, search, limit, signal) {
  return {
    type: Types.RETRIEVE_ALL_MENTORS_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveMentorsRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_MENTORS_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveMentorsRowsTotalOffsetFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_MENTORS_FAILURE,
    payload: errorMsg,
  };
}
