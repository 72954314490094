import { Types } from '../types';

export function updateMentorRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_MENTOR_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateMentorSucess({ data }) {
  return {
    type: Types.UPDATE_MENTOR_SUCCESS,
    payload: { data },
  };
}

export function updateMentorFailure(errorMessage) {
  return {
    type: Types.UPDATE_MENTOR_FAILURE,
    payload: errorMessage,
  };
}

export function updateMentorReset() {
  return {
    type: Types.UPDATE_MENTOR_RESET_STATE,
  };
}
