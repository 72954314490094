import { Types } from '../types';

export function createClassRequest({ data, authToken }) {
  return {
    type: Types.CREATE_CLASS_REQUEST,
    payload: { data, authToken },
  };
}

export function createClassSuccess() {
  return {
    type: Types.CREATE_CLASS_SUCCESS,
  };
}

export function createClassFailure(errorMsg) {
  return {
    type: Types.CREATE_CLASS_FAILURE,
    payload: errorMsg,
  };
}

export function createClassResetState() {
  return {
    type: Types.CREATE_CLASS_RESET_STATE,
  };
}