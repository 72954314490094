import React from 'react';
import PropTypes from 'prop-types';
import './AvatarAndName.scss';

import Thumbnail from '../../../../components/Thumbnail';

// dar um amend
function AvatarAndName({ name }) {
  return (
    <div className="mt-4">
      <Thumbnail alt="perfil do professor" height={40} image=" " />
      <span className="nameTeacher">{name}</span>
    </div>
  );
}

AvatarAndName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AvatarAndName;
