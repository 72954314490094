import { combineReducers } from 'redux';

import { createWorkshopReducer } from './Create/reducer';
import { retrieveWorkshopsRowsTotalOffsetReducer } from './Retrieve/reducer';
import { updateWorkshopReducer } from './Update/reducer';
import { deleteWorkshopReducer } from './Delete/reducer';

const reducer = combineReducers({
  createWorkshopReducer,
  retrieveWorkshopsRowsTotalOffsetReducer,
  updateWorkshopReducer,
  deleteWorkshopReducer,
});

export { reducer as crudWorkshopsReducers };
