import { Types } from '../types';

const initialState = {
  isLoading: false,
  hasError: false,
  deleteSuccess: false,
  errorMsg: '',
};

export function deleteWorkshopReducer(state = initialState, action) {
  switch (action.type) {
    case Types.DELETE_WORKSHOP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.DELETE_WORKSHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_WORKSHOP_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        deleteSuccess: false,
        errorMsg: action.payload,
      };
    case Types.DELETE_WORKSHOP_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
