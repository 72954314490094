export const NewCommentContainer ={
  marginLeft: 22,
}

export const NewCommentTitle = {
  color: '#484848',
  fontSize: 14,
  marginLeft: 5,
  fontFamily: 'Rubik-Medium',
}

export const NewCommentInput = {
  width: '100%',
  backgroundColor: '#F0F0F0',
  borderColor: '#474649',
  borderRadius: 10,
  borderWidth: 1,
  outline: 'none',
  marginTop: 5,
  marginBottom: 10,
  padding: 5,
  color: '#696969',
  fontFamily: 'Rubik-Regular',
}

export const ButtomContainer = {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 22,
}

export const Buttom = {
  fontSize: 18,
  borderRadius: 10,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 20,
  paddingRight: 20,
  fontFamily: 'Rubik-Medium',
}

export const ButtomComment = {
  color: '#FFFFFF',
  backgroundColor: '#00BDF7',
  border: 'none',
}

export const ButtomCancel = {
  color: '#00BDF7',
  backgroundColor: '#FFF',
  borderColor: '#00BDF7',
  marginRight: 30,
}

export const ButtomDisable = {
  opacity: 0.5,
}
