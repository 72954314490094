import React from 'react';
import proptypes from 'prop-types';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { AdminsInfo } from '../NewAdminsInfo';

function ModalToRegisterAdmin({ idAdmin, useForEdit }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl">
      <AdminsInfo closeModal={closeModal} idAdmin={idAdmin} useForEdit={useForEdit} />
    </Modal>
  );
}

ModalToRegisterAdmin.propTypes = {
  idAdmin: proptypes.number,
  useForEdit: proptypes.bool,
};

ModalToRegisterAdmin.defaultProps = {
  idAdmin: 0,
  useForEdit: false,
}

export default ModalToRegisterAdmin;
