import { Types } from '../types';

const initialState = {
  success: false,
  isLoading: false,
  hasError: false,
  errorMsg: '',
};

export function createUnitsReducer(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_UNITS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Types.CREATE_UNITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        success: true,
      };
    case Types.CREATE_UNITS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.CREATE_UNITS_RESET_STATE: // TODO - need refactory.
      return initialState;
    default:
      return state;
  }
}
