import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Input from '../../../components/Input';
import icPerson from '../../../assets/images/ic_person.svg';
import Button from '../../../components/Button';

const FirstStep = ({ state, setState, alertMessage, submitEvent }) => (
  <>
    <h4 className="commonFontStyle commonFontStyle--title">Recuperar senha</h4>
    <p className="commonFontStyle commonFontStyle--content">
      Digite o seu e-mail cadastrado para solicitar a recuperação da senha.
    </p>

    <form onSubmit={submitEvent}>
      <Input
        icon={icPerson}
        alt="icone de pessoa, mostrando cabeça e ombros."
        title="icone de pessoa."
        placeholder="E-mail"
        setInputValue={setState}
        inputValue={state}
        typeInput="email"
      />

      {alertMessage && <div className="w-100 mt-2">{alertMessage}</div>}

      <div className="mt-5">
        <Button inputFilled={state} type="submit" >
          Recuperar senha
        </Button>
      </div>
      </form>

      <div className="col-12 text-center mb-5 mt-3">
          <Link to="/login" className="forgotPasswordText" dataTest="forgotPassword">
            Voltar
          </Link>
      </div>
  </>
);

FirstStep.propTypes = {
  state: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  submitEvent: PropTypes.func.isRequired,
  alertMessage: PropTypes.string,
};

FirstStep.defaultProps = {
  alertMessage: '',
};

export default FirstStep;
