import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/admins';

function getAdmins(offset = 0, authToken, search, limit = 10, signal) {
  const finalRoute = search ? `${route}?${search}` : route;
  return Api.get(finalRoute, {
    params: {
      limit,
      offset,
    },
    headers: commonHeaders(authToken),
    signal,
  });
}

function createAdmins(data, authToken) {
  return Api.post(route, data, {
    headers: commonHeaders(authToken),
  });
}

function updateAdmin(id, data, authToken) {
  return Api.patch(`${route}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

export { getAdmins, createAdmins, updateAdmin };
