import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { showNotify } from '../../../utils/showNotify';
import { createEventRequest } from '../../../Redux/Events/Create/actions';
import { updateEventRequest } from '../../../Redux/Events/Update/actions';
import { AuthTokenSelector } from '../../../Redux/Auth/selector';
import Input from '../../../components/Input';
import { BUTTON_CLASSES } from '../../../utils/enums';

function EventsInfo({ closeModal, initialData }) {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [attendeePassword, setAttendeePassword] = useState('');
  const [moderatorPassword, setModeratorPassword] = useState('');
  const [webcamsOnlyForModerators, setWebcamsOnlyForModerators] = useState('');
  const [disablePrivateChats, setDisablePrivateChats] = useState('');
  const [disableCamera, setDisableCamera] = useState('');
  const [maxParticipants, setMaxParticipants] = useState(150);
  const [maxDuration, setMaxDuration] = useState(60);
  const [enableRecord, setEnableRecord] = useState(true);
  const [autoStartRecording, setAutoStartRecording] = useState(false);
  const [eventDate, setEventDate] = useState('');
  const [eventTime, setEventTime] = useState('');
  const [invalidFieldsError, setInvalidFieldsError] = useState(false);
  const [submitError, setSubmitError] = useState('');

  useEffect(() => {
    resetValues();
    return resetValues();
  }, []);

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setAttendeePassword(initialData.attendeePassword);
      setModeratorPassword(initialData.moderatorPassword);
      setWebcamsOnlyForModerators(!!initialData.webcamsOnlyForModerators);
      setDisablePrivateChats(!!initialData.disablePrivateChats);
      setDisableCamera(!!initialData.disableCamera);
      setMaxParticipants(initialData.maxParticipants);
      setMaxDuration(initialData.maxDuration);
      setEnableRecord(!!initialData.enableRecord);
      setAutoStartRecording(!!initialData.autoStartRecording);
      setEventDate(initialData.eventDate.substring(0, 10));
      setEventTime(initialData.eventTime);
    }
  }, [initialData]);

  const authToken = useSelector(AuthTokenSelector);

  const validateTimeByNow = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    // check if the day chosen is today
    const isToday = currentDate === eventDate;
    const momentHour = moment().format('HH:mm:ss');
    const beginningTime = moment(momentHour, 'HH:mm:ss');
    const endTime = moment(eventTime, 'HH:mm:ss');
    const isAfterTimeSinceToday = isToday && beginningTime.isAfter(endTime);
    return !isAfterTimeSinceToday;
  };

  const validateFields = () => {
    const isTimeDateValid = validateTimeByNow();

    if (!name) return 'Preencha o campo de Nome';
    if (!attendeePassword) return 'Preencha o campo de senha de participante';
    if (attendeePassword.length < 6) return 'É necessário uma senha de participante de no mínimo 6 digitos';
    if (moderatorPassword.length < 6) return 'É necessário uma senha de organizador de no mínimo 6 digitos';
    if (!moderatorPassword.localeCompare(attendeePassword))
      return 'As senhas de participante e de organizador não podem ser iguais';
    if (!moderatorPassword) return 'Preencha o campo de senha do organizador';
    if (!maxParticipants || maxParticipants < 1) return 'Preencha o campo máximo de participantes';
    if (!maxDuration) return 'Preencha o campo máximo de duração';
    if (!eventDate) return 'Indique a data que o evento irá acontecer';
    if (!eventTime) return 'Indique em que horas o evento começará';
    if (!isTimeDateValid) return 'Insira um horário válido, o horário escolhido já passou';

    return '';
  };

  const resetValues = () => {
    setName('');
    setAttendeePassword('');
    setModeratorPassword('');
    setWebcamsOnlyForModerators(false);
    setDisablePrivateChats(false);
    setDisableCamera(false);
    setMaxParticipants(150);
    setMaxDuration(60);
    setEnableRecord(true);
    setAutoStartRecording(false);
    setEventDate('');
    setEventTime('');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const invalidFields = validateFields();

    if (invalidFields) {
      setInvalidFieldsError(invalidFields);
      return false;
    }

    setInvalidFieldsError('');

    const data = {
      name,
      attendeePassword,
      moderatorPassword,
      disablePrivateChats,
      disableCamera,
      webcamsOnlyForModerators,
      maxParticipants: parseInt(maxParticipants, 10),
      maxDuration: parseInt(maxDuration, 10),
      enableRecord,
      autoStartRecording,
      // Enable custom recording (true by standard)
      allowStartStopRecording: enableRecord,
      eventDate,
      eventTime,
    };

    try {
      if (initialData) {
        dispatch(updateEventRequest({ id: initialData.id, data, authToken }));
      } else {
        dispatch(createEventRequest({ data, authToken }));
      }
      closeModal();
    } catch (err) {}
    setSubmitError('');
  };

  const submitErrorNotify = () => {
    const typeNotify = 'error';
    const contentNotify = submitError;

    showNotify(typeNotify, contentNotify);
  };

  return (
    <>
      {submitError && submitErrorNotify()}

      <h2 style={{ color: 'black', padding: '0 0 20px 4px', fontWeight: 'bold' }}>
        {initialData ? 'Editar Evento' : 'Adicionar Evento'}
      </h2>
      <div className="col-12 my-2 row align-items-start">
        <div className="col-4 mt-2">
          <label htmlFor="name">Nome do evento</label>
          <Input id="name" inputValue={name} typeInput="text" setInputValue={setName} required />
        </div>
        <div className="col-4 mt-2">
          <label htmlFor="attendeePassword">Senha do participante</label>
          <Input
            id="attendeePassword"
            inputValue={attendeePassword}
            typeInput="password"
            setInputValue={setAttendeePassword}
            required
          />
        </div>
        <div className="col-4 mt-2">
          <label htmlFor="moderatorPassword">Senha do organizador </label>
          <Input
            id="moderatorPassword"
            inputValue={moderatorPassword}
            typeInput="password"
            setInputValue={setModeratorPassword}
            required
          />
          <small className="text-danger">*Cuidado! Quem obtiver essa senha terá permissões de administrador!</small>
        </div>
        <div className="col-2 mt-2">
          <label htmlFor="maxParticipants">Máximo de participantes</label>
          <Input
            id="maxParticipants"
            inputValue={maxParticipants}
            typeInput="number"
            min={1}
            setInputValue={value => (value >= 1 ? setMaxParticipants(value) : setMaxParticipants(1))}
            required
          />
        </div>
        <div className="col-2 mt-2">
          <label htmlFor="maxDuration">Duração (em minutos)</label>
          <Input id="maxDuration" inputValue={maxDuration} typeInput="number" setInputValue={setMaxDuration} required />
        </div>
        <div className="col-4  mt-2">
          <label htmlFor="eventDate">Data do evento</label>
          <Input
            id="eventDate"
            inputValue={eventDate}
            typeInput="date"
            min={new Date().toISOString().split('T')[0]}
            setInputValue={setEventDate}
            required
          />
        </div>
        <div className="col-4  mt-2">
          <label htmlFor="eventTime">Horário do evento</label>
          <Input id="eventTime" inputValue={eventTime} typeInput="time" setInputValue={setEventTime} required />
        </div>
        <div className="col-4 mt-2">
          <Input
            id="disablePrivateChats"
            inputValue={disablePrivateChats}
            typeInput="checkbox"
            setInputValue={setDisablePrivateChats}
            labelText="Desabilitar chats privados"
          />
          <Input
            id="disableCamera"
            inputValue={disableCamera}
            typeInput="checkbox"
            setInputValue={setDisableCamera}
            labelText="Desabilitar câmera"
          />
          <Input
            id="webcamsOnlyForModerators"
            inputValue={webcamsOnlyForModerators}
            typeInput="checkbox"
            setInputValue={setWebcamsOnlyForModerators}
            labelText="Câmera apenas para organizadores"
          />
        </div>
        <div className="col-4 mt-2">
          <Input
            id="enableRecord"
            inputValue={enableRecord}
            typeInput="checkbox"
            setInputValue={setEnableRecord}
            labelText="Habilitar gravação do evento"
          />
          <Input
            id="autoStartRecording"
            inputValue={autoStartRecording}
            typeInput="checkbox"
            setInputValue={setAutoStartRecording}
            labelText="Habilitar gravação automática"
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2 mb-1">
        {invalidFieldsError ? (
          <span style={{ color: 'red', margin: 'auto', marginLeft: 0 }}>Erro: {invalidFieldsError}</span>
        ) : (
          ''
        )}

        <Button
          classes={`${BUTTON_CLASSES.NO_AWAIT_WHITE} mr-2 col-2`}
          type="button"
          handleClick={() => {
            resetValues();
            closeModal();
          }}
        >
          Cancelar
        </Button>

        <Button classes={`${BUTTON_CLASSES.NO_AWAIT} col-2`} type="button" handleClick={event => handleSubmit(event)}>
          {initialData ? 'Atualizar' : 'Adicionar'}
        </Button>
      </div>
    </>
  );
}

EventsInfo.defaultProps = {
  initialData: null,
  closeModal: () => {},
};

EventsInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.object,
  closeModal: PropTypes.func,
};

export default EventsInfo;
