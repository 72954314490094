import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const defaultValue = {
  notifyIsOpen: false,
  contentNotify: '',
  typeNotify: '',
};

const StateNotifyContext = createContext(defaultValue);
const DispatchNotifyContext = createContext();

function notifyIsOpen(state, action) {
  switch (action.type) {
    case 'open': {
      const { contentNotify, typeNotify } = action.payload;
      return {
        ...state.defaultValue,
        notifyIsOpen: true,
        contentNotify,
        typeNotify,
      };
    }
    default:
      return defaultValue;
  }
}

function NotifyProvider({ children }) {
  const [state, dispatch] = useReducer(notifyIsOpen, defaultValue);
  return (
    <StateNotifyContext.Provider value={state}>
      <DispatchNotifyContext.Provider value={dispatch}>{children}</DispatchNotifyContext.Provider>
    </StateNotifyContext.Provider>
  );
}

const NotifyState = () => useContext(StateNotifyContext);

const NotifyDispatch = () => useContext(DispatchNotifyContext);

NotifyProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { NotifyProvider, NotifyState, NotifyDispatch };
