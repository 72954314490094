import { AuthTypes } from './types';

import undefinedUserBase64 from '../../assets/images/undefinedUserBase64';

const initialState = {
  authToken: null,
  user: {},
  email: '',
  password: '',
  avatar: undefinedUserBase64,
  isSignIn: false,
  isSigned: false,
  hasError: false,
  errorMsg: '',
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case AuthTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        isSignIn: true,
        hasError: false,
        errorMsg: '',
      };
    case AuthTypes.SIGN_IN_SUCCESS:
      const { authToken, user, username, password, rememberLogin, avatar } = action.payload;

      const newState = {
        ...state,
        user,
        authToken,
        username,
        password,
        avatar,
        isSigned: true,
        isSignIn: false,
        hasError: false,
      };

      if (rememberLogin) return newState;

      return { ...newState, username: '', password: '' };

    case AuthTypes.SIGN_IN_FAILURE:
      const { errorMsg, errorType } = action.payload;
      return {
        ...state,
        isSignIn: false,
        hasError: true,
        errorMsg,
        errorType,
      };
    case AuthTypes.SIGN_IN_FORBIDDEN:
      return {
        ...state,
        hasError: true,
        isSignIn: false,
        isSigned: false,
        errorMsg: action.payload,
      };
    case AuthTypes.LOGOUT:
    case AuthTypes.SIGN_IN_RESET:
      return { ...initialState, username: state.username, password: state.password };
    default:
      return state;
  }
}
