import { Types } from '../types';

export function updateClassRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_CLASS_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateClassSuccess() {
  return {
    type: Types.UPDATE_CLASS_SUCCESS,
  };
}

export function updateClassFailure(errorMsg) {
  return {
    type: Types.UPDATE_CLASS_FAILURE,
    payload: errorMsg,
  };
}

export function updateClassResetState() {
  return {
    type: Types.UPDATE_CLASS_RESET_STATE,
  };
}
