import { createSelector } from 'reselect';
import { authReducer } from '.';

const AuthSelectorState = state => state.authReducer;

export const AuthRetrieveUserSelector = createSelector(AuthSelectorState, authReducer => authReducer.user);

export const AuthRetrieveAvatarSelector = createSelector(AuthSelectorState, authReducer => authReducer.avatar);

export const AuthSignInSelector = createSelector(AuthSelectorState, authReducer => authReducer.isSignIn);

export const AuthHasErrorErrorMsgSelector = createSelector(AuthSelectorState, authReducer => ({
  hasError: authReducer.hasError,
  errorMsg: authReducer.errorMsg,
  errorType: authReducer.errorType,
}));

export const AuthSignedSelector = createSelector(AuthSelectorState, authReducer => authReducer.isSigned);

export const AuthTokenSelector = createSelector(AuthSelectorState, authReducer => authReducer.authToken);

export const AuthCredentialsSelector = createSelector(AuthSelectorState, authReducer => ({
  username: authReducer.username,
  password: authReducer.password,
}));
