import React from 'react';
import PropTypes from 'prop-types';
import './ModalToDeleteMentor.scss';

import icAttetion from '../../../assets/images/ic_attetion.svg';
import { DeleteModalStyles as Styles } from '../../../components/Modal';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

import { ModalDispatch } from '../../../contexts/ModalContext';
import { BUTTON_CLASSES } from '../../../utils/enums';

function ModalToDeleteMentor({ nameMentor, actionIfYes }) {
  const modalDispatch = ModalDispatch();
  return (
    <Modal size="md" alignHorizontal="center" centralizeVertical>
      <div style={Styles.Container}>
        <div style={Styles.Header}>
          <img style={Styles.Image} src={icAttetion} alt="attention" />
          Cuidado!
        </div>
        <div>
          <div style={Styles.Text}>
            {'Tem certeza que deseja excluir o(a) líder '} <span style={Styles.TextBold}>{nameMentor}</span> {'?'}
          </div>
        </div>

        <div className="mt-5 mb-4 d-flex justify-content-center">
          <div className="col-md-4">
            <Button
              inputFilled
              type="button"
              handleClick={() => modalDispatch({ type: '' })}
              classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
            >
              Não
            </Button>
          </div>
          <div className="col-md-4">
            <Button inputFilled type="button" handleClick={actionIfYes}>
              Sim
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalToDeleteMentor.propTypes = {
  nameMentor: PropTypes.string.isRequired,
  actionIfYes: PropTypes.string.isRequired,
};

export default ModalToDeleteMentor;
