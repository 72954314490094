import { Types } from '../types';

export function defineUnitsSearchParams({ search }) {
  return {
    type: Types.DEFINE_UNITS_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveUnitssRowsTotalOffsetRequest(offset = 0, authToken, search, limit = 10, signal) {
  return {
    type: Types.RETRIEVE_ALL_UNITS_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveUnitssRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_UNITS_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveUnitssRowsTotalOffsetFailure(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_UNITS_FAILURE,
    payload: errorMsg,
  };
}

export function retrieveUnitssRowsTotalOffsetReset(errorMsg) {
  return {
    type: Types.RETRIEVE_ALL_UNITS_RESET,
    payload: errorMsg,
  };
}
