import { combineReducers } from 'redux';

import { createUnitsReducer } from './Create/reducer';
import { retrieveUnitssRowsTotalOffsetReducer } from './Retrieve/reducer';
import { retrieveUnitsByCountryStatesReducer } from './RetrieveByCountryState/reducer';
import { updateUnitsReducer } from './Update/reducer';
import { deleteUnitsReducer } from './Delete/reducer';

const reducer = combineReducers({
  createUnitsReducer,
  retrieveUnitssRowsTotalOffsetReducer,
  retrieveUnitsByCountryStatesReducer,
  updateUnitsReducer,
  deleteUnitsReducer,
});

export { reducer as crudUnitssReducers };
