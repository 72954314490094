import { Types } from '../types';

export function resetPasswordRequest({ id, password, mentorPassword }, authToken) {
  return {
    type: Types.RESET_PASSWORD_REQUEST,
    payload: { id, password, mentorPassword, authToken },
  };
}

export function resetPasswordSucess() {
  return {
    type: Types.RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordFailure(errorMessage) {
  return {
    type: Types.RESET_PASSWORD_FAILURE,
    payload: errorMessage,
  };
}

export function resetPasswordReset() {
  return {
    type: Types.RESET_PASSWORD_RESET_STATE,
  };
}
