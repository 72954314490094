import React from 'react';
import PropTypes from 'prop-types';
import './Thumbnail.scss';
import UndefinedUserImage from '../../assets/images/undefined_user.jpg';

const Thumbnail = ({ 
    image, 
    alt, 
    height, 
    borderColor, 
    clickFn }) => (
  <>
    <img
      onClick={clickFn}
      className="thumbnaill"
      style={{ 
          borderColor, 
          cursor: clickFn && 'pointer' 
      }}
      src={image || UndefinedUserImage}
      alt={alt}
      height={height}
      width={height}
    />
  </>
);

Thumbnail.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
  borderColor: PropTypes.string,
};

Thumbnail.defaultProps = {
  image: '',
  height: '24px',
  borderColor: '#d4d4d4',
  alt: '',
};

export default Thumbnail;
