import { Types } from '../types';

const initialState = {
  isLoading: false,
  hasError: false,
  deleteSuccess: null,
  errorMsg: '',
};

export function deleteTeacherReducer(state = initialState, action) {
  switch (action.type) {
    case Types.DELETE_TEACHER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_TEACHER_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        deleteSuccess: false,
        errorMsg: action.payload,
      };
    case Types.DELETE_TEACHER_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
