import React from 'react';
import proptypes from 'prop-types';

import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';
import { StudentsInfo } from '../StudentsInfo';

function ModalToRegisterStudent({ idStudent }) {
  const modalDispatch = ModalDispatch();
  const closeModal = () => modalDispatch({ type: 'close', payload: '' });
  return (
    <Modal size="xl">
      <StudentsInfo closeModal={closeModal} idStudent={idStudent} />
    </Modal>
  );
}

ModalToRegisterStudent.propTypes = {
  idStudent: proptypes.number,
};

ModalToRegisterStudent.defaultProps = {
  idStudent: 0,
};

export default ModalToRegisterStudent;
