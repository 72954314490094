import React, { useEffect } from 'react';
import { ModalDispatch } from '../../../contexts/ModalContext';
import Modal from '../../../components/Modal';

import { Comments } from "../Comments";


// eslint-disable-next-line react/prop-types
function ModalToCommentsClass({ initialData, clearDataOnClose }) {

  const modalDispatch = ModalDispatch();

  const closeModal = () => {
    clearDataOnClose();
    modalDispatch({ type: 'close', payload: '' });
  }

  return (
    <Modal size="xl" alignHorizontal="center" clearDataOnCloseModal={() => closeModal()}>
      <Comments
        class={initialData}
        closeModal={() => closeModal()}
      />
    </Modal>
  );
}

export default ModalToCommentsClass;
