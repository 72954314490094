export const Types = {
  DEFINE_EVENTS_SEARCH_PARAMS: '[classes]DEFINE_EVENTS_SEARCH_PARAMS',

  RETRIEVE_ALL_EVENTS_REQUEST: '[classes]RETRIEVE_ALL_EVENTS_REQUEST',
  RETRIEVE_ALL_EVENTS_SUCCESS: '[classes]RETRIEVE_ALL_EVENTS_SUCCESS',
  RETRIEVE_ALL_EVENTS_FAILURE: '[classes]RETRIEVE_ALL_EVENTS_FAILURE',

  RETRIEVE_EVENT_CONTENT_REQUEST: '[classes]RETRIEVE_EVENT_CONTENT_REQUEST',
  RETRIEVE_EVENT_CONTENT_SUCCESS: '[classes]RETRIEVE_EVENT_CONTENT_SUCCESS',
  RETRIEVE_EVENT_CONTENT_FAILURE: '[classes]RETRIEVE_EVENT_CONTENT_FAILURE',
  RETRIEVE_EVENT_CONTENT_RESET_STATE: '[classes]RETRIEVE_EVENT_CONTENT_RESET_STATE',

  CREATE_EVENT_REQUEST: '[classes]CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: '[classes]CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILURE: '[classes]CREATE_EVENT_FAILURE',
  CREATE_EVENT_RESET_STATE: '[classes]CREATE_EVENT_RESET_STATE',

  UPDATE_EVENT_REQUEST: '[classes]UPDATE_EVENT_REQUEST',
  UPDATE_EVENT_SUCCESS: '[classes]UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAILURE: '[classes]UPDATE_EVENT_FAILURE',
  UPDATE_EVENT_RESET_STATE: '[classes]UPDATE_EVENT_RESET_STATE',

  DELETE_EVENT_REQUEST: '[classes]DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: '[classes]DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: '[classes]DELETE_EVENT_FAILURE',
  DELETE_EVENT_RESET_STATE: '[classes]DELETE_EVENT_RESET_STATE',
};
