import { Types } from '../types';

export function updateSelfRequest({ id, data, authToken }) {
  return {
    type: Types.UPDATE_SELF_REQUEST,
    payload: { id, data, authToken },
  };
}

export function updateSelfSucess() {
  return {
    type: Types.UPDATE_SELF_SUCCESS,
  };
}

export function updateSelfFailure(errorMessage) {
  return {
    type: Types.UPDATE_SELF_FAILURE,
    payload: errorMessage,
  };
}

export function updateSelfReset() {
  return {
    type: Types.UPDATE_SELF_RESET_STATE,
  };
}
