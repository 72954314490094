import { createSelector } from 'reselect';

const SubjectsSelectors = state => state.crudSubjects;

export const SubjectsRetrieveAllSelectors = createSelector(
  SubjectsSelectors,
  retrieveSubjectsData => retrieveSubjectsData.retrieveAllSubjectsReducers
);

export const SubjectsRetrieveByUnitSelectors = createSelector(
  SubjectsSelectors,
  retrieveSubjectsData => retrieveSubjectsData.retrieveSubjectsByUnitReducers.data
);

export const SubjectsRetriveIsLoadingSelectors = createSelector(
  SubjectsSelectors,
  retrieveSubjects => retrieveSubjects.retrieveSubjectsByUnitReducers.isLoading
);