import { Types } from '../types';

const initialState = {
  data: [],
  isLoading: false,
  hasError: false,
  errorMsg: '',
};

export function retrieveEventContentReducer(state = initialState, action) {
  const { data } = action.payload || state;
  switch (action.type) {
    case Types.RETRIEVE_EVENT_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.RETRIEVE_EVENT_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data,
      };
    case Types.RETRIEVE_ALL_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    case Types.RETRIEVE_EVENT_CONTENT_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
