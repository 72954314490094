import { Types } from '../types';

export function deleteMentorRequest({ id, authToken }) {
  return {
    type: Types.DELETE_MENTOR_REQUEST,
    payload: { id, authToken },
  };
}

export function deleteMentorSuccess() {
  return {
    type: Types.DELETE_MENTOR_SUCCESS,
  };
}

export function deleteMentorFailure(errorMessage) {
  return {
    type: Types.DELETE_MENTOR_FAILURE,
    payload: errorMessage,
  };
}

export function deleteMentorReset() {
  return {
    type: Types.DELETE_MENTOR_RESET_STATE,
  };
}
