import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/utils/cities';

export function getCitiesByState(initialState, authToken) {
  return Api.get(`${route}/${initialState}`, {
    headers: commonHeaders(authToken),
  });
}
