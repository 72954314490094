import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/sites';
const hierarchicalRoute = `${route}/hierarchical`;

function getUnitss(offset = 0, authToken, search, limit = 10, signal) {
  const finalRoute = search ? `${hierarchicalRoute}/list?${search}` : `${hierarchicalRoute}/list`;
  return Api.get(finalRoute, {
    params: {
      limit,
      offset,
    },
    headers: commonHeaders(authToken),
    signal,
  });
}

async function getUnitsById(authToken, siteId) {
  return Api.get(`${route}/${siteId}`, {
    headers: commonHeaders(authToken),
  });
}

async function getUnitRelations(authToken, siteId) {
  return Api.get(`/sites/${siteId}​/references`, {
    headers: commonHeaders(authToken),
  });
}

function getUnitsByCountryState(authToken, countryState, limit = 10000) {
  return Api.get(`${route}/admin/list?states=${countryState}`, {
    params: {
      limit,
    },
    headers: commonHeaders(authToken),
  });
}

function createUnitss(data, authToken) {
  return Api.post(route, data, {
    headers: commonHeaders(authToken),
  });
}

function updateUnits(id, data, authToken) {
  return Api.patch(`${route}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function deleteUnits(id, authToken) {
  return Api.delete(`${route}/${id}`, {
    headers: commonHeaders(authToken),
  });
}

export { getUnitss, getUnitsById, getUnitRelations, getUnitsByCountryState, createUnitss, updateUnits, deleteUnits };
