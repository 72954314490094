import { createSelector } from 'reselect';

const CitiesSelectors = state => state.crudCitiesReducer;

export const RetrieveCitiesSelectors = createSelector(
  CitiesSelectors,
  retrieveCities => retrieveCities.retriveCitiesOfStateReducer.data
);

export const RetrieveCitiesIsLoading = createSelector(
  CitiesSelectors,
  retrieveCities => retrieveCities.retriveCitiesOfStateReducer.isLoading
);
