import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';

const SecondStep = ({ eventButton }) => {
  return (
    <>
    <h4 className="commonFontStyle commonFontStyle--title">Senha alterada com sucesso!</h4>
    <div className="mt-5">
      <Button handleClick={eventButton}>
        Voltar ao login
      </Button>
    </div>
    </>
  )
}

SecondStep.propTypes = {
  eventButton: PropTypes.func.isRequired,
};

export default SecondStep;