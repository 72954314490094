import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { Routes } from './routes';
import { createAppStore } from './Redux';

const { store, persistor } = createAppStore();

export default () => (
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter>
          <Routes />
        </HashRouter>
      </PersistGate>
    </Provider>
  </>
);
