import React from 'react';
import PropTypes from 'prop-types';

function DontHaveData({ totalColumns }) {
  return (
    <>
      <tr>
        <td colSpan={totalColumns}>
          <span className="d-flex justify-content-center w-100">Não encontramos nada!</span>
        </td>
      </tr>
    </>
  );
}

DontHaveData.propTypes = {
  totalColumns: PropTypes.number.isRequired,
};

export default DontHaveData;
