import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './WidgetUser.scss';
import { ModalDispatch } from '../../../contexts/ModalContext';

import icArrowDown from '../../../assets/images/ic_arrow_down.svg';
import ModalEditSelfUser from '../ModalEditSelfUser';
import Thumbnail from '../../Thumbnail';

import { AuthRetrieveUserSelector, AuthRetrieveAvatarSelector } from '../../../Redux/Auth/selector';

import undefinedUserBase64 from '../../../assets/images/undefinedUserBase64';

function WidgetUser() {

  const modalDispatch = ModalDispatch();
  const user = useSelector(AuthRetrieveUserSelector);

  const avatar = useSelector(AuthRetrieveAvatarSelector);

  const [_avatar, setAvatar] = useState(undefinedUserBase64);

  useEffect(() => {
    if (avatar) {
      setAvatar(`data:image/png;base64,${avatar}`);
    }
  }, [avatar])

  function openModalEditUser() {
    modalDispatch({
      type: 'open',
      payload: <ModalEditSelfUser />,
    });
  }

  return (
    <div className="widget d-flex flex-row" title="Editar dados do perfil" onClick={openModalEditUser}>
      <Thumbnail image={_avatar} />
      <div className="widget__nameUser"> {user.name} </div>
      <div style={{ marginLeft: '8px' }}>
        <img src={icArrowDown} alt="" className="iconTopBar" />
      </div>
    </div>
  );
}

export default WidgetUser;
