import React from 'react';
import PropTypes from 'prop-types';
import './ContentClass.scss';

import icMeSalva from '../../../../assets/images/me_salva.jpg';

function ContentClass({ description, url, thumbnail, type, index }) {
  const finalUrl = type === 'mesalva' ? `https://www.mesalva.com/${url}` : url;
  return (
    <div className="containerClassItem">
      <div className="contentClassItem">
        {index === 1 && <h4 className="relationContents__title mt-4">Aulas Relacionadas</h4>}

        <div className="mt-5">
          {index > 0 && (
            <>
              <div className="markup" />
              <div>
                <h4 className="mt-4 contentClassItem__description  contentClassItem__description--withMarkup">
                  <div>{description}</div>
                </h4>
              </div>
            </>
          )}
          <a href={finalUrl} target="blank">
            <img src={thumbnail} alt={description} height="300px" title="clique para abrir conteudo" />
          </a>
          {type === 'mesalva' && <img src={icMeSalva} className="meSalvaIcon" alt="" />}
          {index === 0 && <h4 className="mt-4 contentClassItem__description">{description}</h4>}
        </div>
      </div>
    </div>
  );
}

ContentClass.propTypes = {
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default ContentClass;
