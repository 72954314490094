import { createSelector } from 'reselect';

const MacrothemessSelectors = state => state.crudMacrothemessReducers;

export const MacrothemessRetrieveAllSelectors = createSelector(
  MacrothemessSelectors,
  retrieveMacrothemessRowsTotalOffsetReducer =>
    retrieveMacrothemessRowsTotalOffsetReducer.retrieveMacrothemessRowsTotalOffsetReducer
);

export const MacrothemessSearchSelectors = createSelector(
  MacrothemessSelectors,
  macrothemesSearch =>
  macrothemesSearch.retrieveMacrothemessRowsTotalOffsetReducer.search
);

export const MacrothemesCreateSelectors = createSelector(
  MacrothemessSelectors,
  createMacrothemessReducers => createMacrothemessReducers.createMacrothemesReducer
);

export const MacrothemessCreateHasErrorSelectors = createSelector(
  MacrothemesCreateSelectors,
  createMacrothemessReducers => createMacrothemessReducers.hasError
);

export const MacrothemessCreateErrorMessage = createSelector(
  MacrothemesCreateSelectors,
  createMacrothemessReducers => createMacrothemessReducers.errorMsg
);

export const MacrothemesSuccessSelectors = createSelector(
  MacrothemesCreateSelectors,
  createMacrothemessReducers => createMacrothemessReducers.success
);

export const MacrothemessCreateLoading = createSelector(
  MacrothemesCreateSelectors,
  createMacrothemessReducers => createMacrothemessReducers.isLoading
);

export const MacrothemesUpdateSelectors = createSelector(
  MacrothemessSelectors,
  updateMacrothemess => updateMacrothemess.updateMacrothemesReducer
);

export const MacrothemesUpdateLoading = createSelector(
  MacrothemesUpdateSelectors,
  updateMacrothemess => updateMacrothemess.isLoading
);

export const MacrothemesDeleteSelectors = createSelector(
  MacrothemessSelectors,
  deleteMacrothemes => deleteMacrothemes.deleteMacrothemesReducer
);

export const MacrothemesDeleteLoading = createSelector(
  MacrothemesDeleteSelectors,
  deleteMacrothemes => deleteMacrothemes.isLoading
);
