import React from 'react';
import PropTypes from 'prop-types';
import IcCopy from '../../assets/images/ic_copy.svg';
import { useMousePosition } from '../../hooks/useMousePosition';
import './style.scss';

function CopyTooltip({ isVisible, text }) {
  const position = useMousePosition();
  if (isVisible) {
    return (
      <div className="copy_tooltip" style={{ top: position.y + 4, left: position.x - 145 }}>
        <img src={IcCopy} alt="Copy Icon" />
        <span>{text ?? 'Copiar link da aula'}</span>
      </div>
    );
  }

  return <></>;
}

CopyTooltip.propTypes = {
  isVisible: PropTypes.bool,
};

CopyTooltip.defaultProps = {
  isVisible: false,
};

export { CopyTooltip };
