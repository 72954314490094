import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Input.scss';

import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

function Input({
  id,
  icon,
  alt,
  title,
  placeholder,
  typeInput,
  inputValue,
  labelText,
  setInputValue,
  readOnly,
  required,
  maxLength,
  invalidValue,
  dataTest,
  ...props
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [inputType, setInputType] = useState(typeInput);

  const togglePasswordVisible = () => {
    // if isPasswordVisible, the input type will be setted to password
    // because it is the next state of isPasswordVisible
    isPasswordVisible ? setInputType('password') : setInputType('text');
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="d-flex">
      {icon && (
        <div className="mr-1">
          <img className="iconInputLogin styleCommonInputAndIcon" src={icon} alt={alt} title={title} />
        </div>
      )}
      <div className={`inputContainer w-100 input-group ${typeInput === 'checkbox' && 'mt-2'}`}>
        <input
          id={id}
          className={`inputStyle styleCommonInputAndIcon 
            ${invalidValue ? 'inputStyle--invalid form-control' : ''} 
            ${typeInput === 'checkbox' ? 'checkboxInput' : ''} 
            ${typeInput === 'password' ? 'passwordInput' : ''}`}
          placeholder={placeholder}
          type={inputType}
          value={inputValue}
          checked={typeInput === 'checkbox' && inputValue}
          onChange={e => {
            if (typeInput === 'checkbox') {
              setInputValue(e.target.checked);
            } else {
              setInputValue(e.target.value);
            }
          }}
          readOnly={readOnly}
          required={required}
          maxLength={maxLength}
          autoComplete="off"
          data-test={dataTest}
          {...props}
        />
        {labelText && (
          <label className={`${typeInput === 'checkbox' ? 'checkboxLabel' : ''}`} htmlFor={id}>
            {labelText}
          </label>
        )}
        {typeInput === 'password' && isPasswordVisible && (
          <span className="passwordActionButton" onClick={togglePasswordVisible}>
            <BsFillEyeFill />
          </span>
        )}

        {typeInput === 'password' && !isPasswordVisible && (
          <span className="passwordActionButton" onClick={togglePasswordVisible}>
            <BsFillEyeSlashFill />
          </span>
        )}
      </div>
    </div>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  typeInput: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputValue: PropTypes.any,
  setInputValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  invalidValue: PropTypes.bool,
  dataTest: PropTypes.string,
  labelText: PropTypes.string,
};

Input.defaultProps = {
  id: '',
  icon: '',
  alt: '',
  title: '',
  readOnly: false,
  required: false,
  inputValue: '',
  typeInput: '',
  placeholder: '',
  maxLength: 100,
  invalidValue: false,
  dataTest: '',
  labelText: null,
};

export default Input;
