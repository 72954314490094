import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ModalEditSelfUser.scss';

import Modal from '../../Modal';
import Thumbnail from '../../Thumbnail';
import { AuthRetrieveAvatarSelector, AuthRetrieveUserSelector, AuthTokenSelector } from '../../../Redux/Auth/selector';
import { logout } from '../../../Redux/Auth/actions';

import { ViewDataUser } from './ViewDataUser';
import { EditPassword } from './EditPassword';

import { changePasswordRequest, changePasswordResetState } from '../../../Redux/ChangePassword/actions';
import { ChangePasswordSelectors } from '../../../Redux/ChangePassword/selector';


import { ModalDispatch } from '../../../contexts/ModalContext';
import { showNotify } from '../../../utils/showNotify';

import undefinedUserBase64 from '../../../assets/images/undefinedUserBase64';

function ModalEditSelfUser() {
  const [editSelf, setEditSelf] = useState(false);
  const dispatch = useDispatch();
  const modalDispatch = ModalDispatch();
  const { name, username, created_at: createdAt } = useSelector(AuthRetrieveUserSelector);
  const authToken = useSelector(AuthTokenSelector);
  const changePasswordSelectors = useSelector(ChangePasswordSelectors);
  const [submitError, setSubmitError] = useState('');

  const dataOfUserToList = [
    { label: 'Email:', value: username },
    { label: 'Senha:', value: '• • • • • • • • • •' },
  ];

  const avatar = useSelector(AuthRetrieveAvatarSelector);

  const [_avatar, setAvatar] = useState(undefinedUserBase64);

  useEffect(() => {
    if (avatar)
      setAvatar(`data:image/png;base64,${avatar}`);
  }, [avatar])

  const changeToEdit = () => setEditSelf(!editSelf);

  const logoutOnClick = () => dispatch(logout());

  function updatePassword(username, currentPassword, newPassword) {
    dispatch(changePasswordRequest({ username, currentPassword, newPassword }, authToken));
  }

  const cleanSubmitError = () => setSubmitError('');

  const responseOfUpdate = () => {
    let typeNotify;
    let message;
    if (changePasswordSelectors.hasError) {
      if (changePasswordSelectors.errorMsg === 'Unauthorized') {
        setSubmitError('Senha atual incorreta!');
      } else {
        setSubmitError('Ocorreu um erro ao atualizar os dados. Tente novamente :)');
      }
    } else {
      setSubmitError('');
      typeNotify = 'success';
      message = 'Senha alterada com sucesso!';
      modalDispatch({ type: '' });
    }
    showNotify(typeNotify, message);
    dispatch(changePasswordResetState());
  }

  (changePasswordSelectors.success || changePasswordSelectors.hasError) && responseOfUpdate();

  return (
    <Modal
      size="fit"
      alignHorizontal="center"
      centralizeVertical
    >
      <div className="bodyEditSelfUser">
        <div className="bodyEditSelfUser__leftWrapper">
          <div className="d-flex justify-content-center">
            <Thumbnail height="95px" alt="Foto de perfil" image={_avatar} />
          </div>
        </div>
        <div className="bodyEditSelfUser__rightWrapper">
          <div
            className="d-flex justify-content-start bodyEditSelfUser__name">
            {name}
          </div>
          {editSelf ? (
            <EditPassword email={username} updatePassword={updatePassword} returnToData={changeToEdit} submitError={submitError} cleanSubmitError={cleanSubmitError} />
          ) : (
            <ViewDataUser dataOfUserToList={dataOfUserToList} logoutOnClick={logoutOnClick} changeToEdit={changeToEdit} />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ModalEditSelfUser;
