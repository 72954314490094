import { Types } from '../types';

const initialState = {
  isLoading: false,
  hasError: false,
  success: false,
  errorMsg: '',
};

export function createClassReducer(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_CLASS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.CREATE_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case Types.CREATE_CLASS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.CREATE_CLASS_RESET_STATE:
      return initialState;
    default:
      return initialState;
  }
}
