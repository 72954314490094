import { call, put } from 'redux-saga/effects';

import jwt from 'jsonwebtoken';
import { Api } from '../../services/api';
import { history } from '../../services/history';
import { loginRequest } from '../../client/auth';
import { signInSuccess, signInFailure } from '../../Redux/Auth/actions';
import { USER_ROLES } from '../../utils/enums';
import { getProfilePicture } from '../../client/users';

export function* signInSaga({ payload }) {
  try {
    const { username, password, rememberLogin } = payload;

    const response = yield call(loginRequest, { username, password });
    const { data: authToken } = response;
    const user = jwt.decode(authToken);
    const { role: alias } = user;

    if (!(alias in USER_ROLES)) {
      const error = setSuccessMessage(alias);
      yield put(signInFailure(error));
    } else {
      Api.defaults.headers.Authorization = `Bearer ${authToken}`;
      const pictureName = user.profilePicture;
      if (pictureName) {
        const avatar = yield call(getProfilePicture, pictureName, authToken);
        yield put(signInSuccess(authToken, user, username, password, rememberLogin, avatar.data));
      } else yield put(signInSuccess(authToken, user, username, password, rememberLogin));

      history.push('/dashboard');
    }
  } catch (err) {
    const error = setErrorMessage(err.response);
    yield put(signInFailure(error));
  }
}

function setSuccessMessage(alias) {
  let errorMsg = '';
  const errorType = 'password';

  if (alias === 'student') {
    errorMsg = 'Ops! Seu login é de aluno, por favor  utilize o aplicativo disponível para Android.';
  }

  return { errorMsg, errorType };
}

function setErrorMessage(errResponse) {
  let errorMsg = '';
  let errorType = '';
  if (errResponse) {
    const { status } = errResponse;
    if (status === 400) {
      errorMsg = 'A senha deve ter no mínimo 8 caracteres';
      errorType = 'password';
      if (errResponse.data === 'Database Record Not Found') {
        errorMsg = 'Campo incorreto ou usuário não existe';
        errorType = 'email';
      }
    } else if (status === 401 && errResponse.data === 'Unauthorized') {
      errorMsg = 'Senha incorreta';
      errorType = 'password';
    } else {
      errorMsg = 'Falha ao conectar';
    }
  } else {
    errorMsg = 'Falha ao conectar. Por favor, verifique sua conexão com a Internet e tente novamente.';
  }
  return { errorMsg, errorType };
}
