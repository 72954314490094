import { useState } from 'react';

function useTable() {
  const [open, setOpen] = useState(false);

  function openRow() {
    setOpen(true);
  }

  function closeRow() {
    setOpen(false);
  }

  return [open, openRow, closeRow];
}

export default useTable;
