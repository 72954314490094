import React from 'react';
import PropTypes from 'prop-types';
import errorDraw from '../../../assets/images/error_draw.svg';

import Button from '../../../components/Button';

function NotConnected({ alertComponent }) {
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="w-100 d-flex justify-content-center">
        <img src={errorDraw} alt="desenho de um folha com expressão de espanto" />
      </div>
      <div className="w-100 mt-3">{alertComponent}</div>
      <div className="mt-4">
        <Button inputFilled handleClick={refreshPage}>
          Tentar novamente
        </Button>
      </div>
    </div>
  );
}

NotConnected.propTypes = {
  alertComponent: PropTypes.func.isRequired,
};

export default NotConnected;
