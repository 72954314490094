import React from 'react';
import PropTypes from 'prop-types';
import './TemplateInternal.scss';

import { SideBar } from '../SideBar';
import TopBar from '../TopBar';
import { NotifyAction } from '../NotifyAction';
import Loading from '../Loading';

import { LoadingState } from '../../contexts/LodingContext';
import { ModalState } from '../../contexts/ModalContext';
import { NotifyState } from '../../contexts/NotifyContext';

function TemplateInternal({ children }) {
  const modalState = ModalState();
  const { notifyIsOpen } = NotifyState();
  const { isLoading } = LoadingState();
  return (
    <div className="container-fluid row cleanMarginAndPading">
      {isLoading && <Loading />}

      <TopBar />

      <aside className="col-2">
        <SideBar />
      </aside>

      <div className="col-10 cleanMarginAndPading">
        {notifyIsOpen && <NotifyAction />}
        {modalState.modalIsOpen && modalState.contentModal}
        <main className="contentView">{children}</main>
      </div>
    </div>
  );
}

TemplateInternal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default TemplateInternal;
