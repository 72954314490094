export const Types = {
  DEFINE_STUDENTS_SEARCH_PARAMS: '[students]DEFINE_STUDENTS_SEARCH_PARAMS',
  RETRIEVE_ALL_STUDENTS_REQUEST: '[students]RETRIEVE_ALL_STUDENTS_REQUEST',
  RETRIEVE_ALL_STUDENTS_SUCCESS: '[students]RETRIEVE_ALL_STUDENTS_SUCCESS',
  RETRIEVE_ALL_STUDENTS_FAILURE: '[students]RETRIEVE_ALL_STUDENTS_FAILURE',

  UPDATE_STUDENT_REQUEST: '[students]UPDATE_STUDENT_REQUEST',
  UPDATE_STUDENT_SUCCESS: '[students]UPDATE_STUDENT_SUCCESS',
  UPDATE_STUDENT_FAILURE: '[students]UPDATE_STUDENT_FAILURE',
  UPDATE_STUDENT_RESET_STATE: '[students]UPDATE_STUDENT_RESET_STATE',

  DELETE_STUDENT_REQUEST: '[students]DELETE_STUDENT_REQUEST',
  DELETE_STUDENT_SUCCESS: '[students]DELETE_STUDENT_SUCCESS',
  DELETE_STUDENT_FAILURE: '[students]DELETE_STUDENT_FAILURE',
  DELETE_STUDENT_RESET_STATE: '[students]DELETE_STUDENT_RESET_STATE',

  RESET_PASSWORD_REQUEST: '[students]RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: '[students]RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: '[students]RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_RESET_STATE: '[students]RESET_PASSWORD_RESET_STATE',

};
