import { Types } from '../types';

const initialState = {
  data: [],
  isLoading: false,
  hasError: false,
  errorMsg: '',
};

export function retrieveSchoolsReducer(state = initialState, action) {
  const { data } = action.payload || state;
  switch (action.type) {
    case Types.RETRIEVE_SCHOOLS_BY_STATE_N_CITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.RETRIEVE_SCHOOLS_BY_STATE_N_CITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data,
      };
    case Types.RETRIEVE_SCHOOLS_BY_STATE_N_CITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
}
