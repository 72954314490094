export const CLASS_TYPES = {
  1: 'subject_name',
  2: 'theme_name',
  3: 'workshop_name',
};

export const BUTTON_CLASSES = {
  AWAIT: '--Await',
  NO_AWAIT: '--noAwait',
  AWAIT_WHITE: '--AwaitWhite',
  NO_AWAIT_WHITE: '--noAwaitWhite',
  GREY: '--grey',
};

export const USER_ROLES = {
  admin: 'admin',
  teacher: 'teacher',
  mentor: 'mentor',
};

export const ADMIN_ROUTES = {
  dashboard: 'dashboard',
  professores: 'professores',
  alunos: 'alunos',
  aulas: 'aulas',
  eventos: 'eventos',
  admins: 'admins',
  unidades: 'unidades',
  oficinas: 'oficinas',
  artigos: 'artigos',
  mentores: 'mentores',
};

export const MENTOR_ROUTES = {
  professores: 'professores',
  alunos: 'alunos',
  aulas: 'aulas',
  unidades: 'unidades',
  eventos: 'eventos',
  oficinas: 'oficinas',
};

export const TEACHER_ROUTES = {
  aulas: 'aulas',
  eventos: 'eventos',
};

export const ITEMS_PER_PAGE_ARR = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
