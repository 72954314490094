import { ChangePasswordTypes } from './types';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMsg: '',
  success: false
};

export function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case ChangePasswordTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        errorMsg: '',
      };
    case ChangePasswordTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        success: true
      };
    case ChangePasswordTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        hasError: true,
        success: false,
        isLoading: false,
        errorMsg: action.payload,
      };
    case ChangePasswordTypes.CHANGE_PASSWORD_RESET:
      return initialState;
    default:
      return state;
  }
}
