import { createSelector } from 'reselect';

const AdminsSelectors = state => state.crudAdminsReducers;

export const AdminsRetrieveAllSelectors = createSelector(
  AdminsSelectors,
  retrieveAdminsRowsTotalOffsetReducer => retrieveAdminsRowsTotalOffsetReducer.retrieveAdminsRowsTotalOffsetReducer
);

export const AdminSearchSelectors = createSelector(
  AdminsSelectors,
  adminsSearch => adminsSearch.retrieveAdminsRowsTotalOffsetReducer.search
);

export const AdminCreateSelectors = createSelector(
  AdminsSelectors,
  createAdminsReducers => createAdminsReducers.createAdminReducer
);

export const AdminsCreateHasErrorSelectors = createSelector(
  AdminCreateSelectors,
  createAdminsReducers => createAdminsReducers.hasError
);

export const AdminsCreateGetUserSelectors = createSelector(
  AdminCreateSelectors,
  createAdminsReducers => createAdminsReducers.user
);

export const AdminsCreateLoading = createSelector(
  AdminCreateSelectors,
  createAdminsReducers => createAdminsReducers.isLoading
);

export const AdminsCreateErrorMessage = createSelector(
  AdminCreateSelectors,
  createAdminsReducers => createAdminsReducers.errorMsg
);

export const AdminsCreateSuccessSelectors = createSelector(
  AdminCreateSelectors,
  createAdminsReducers => createAdminsReducers.success
);

export const AdminUpdateSelectors = createSelector(AdminsSelectors, updateAdmins => updateAdmins.updateAdminReducer);

export const AdminUpdateLoading = createSelector(AdminUpdateSelectors, updateAdmins => updateAdmins.isLoading);

export const AdminUpdateSelf = createSelector(AdminsSelectors, UpdateSelf => UpdateSelf.updateSelfReducer);
