import React from 'react';
import PropTypes from 'prop-types';
import './AuthContainer.scss';

import Logo from '../Logo';

const AuthContainer = ({ children }) => (
  <div className="d-flex containerScreen justify-content-center align-items-center">
    <div className="containerScreen__rounded col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
      <div className="col-12 d-flex justify-content-center mt-5">
        <Logo alt="texto gerando falcões e falcão acima do texto" title="Logo Gerando Falcões" />
      </div>

      <div className="col-12 text-center my-3">Acesso ao Painel de Administrador</div>

      <div className="col-8 offset-2 mb-4">{children}</div>
    </div>
  </div>
);

AuthContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthContainer;
