import React from 'react';
import PropTypes from 'prop-types';
import './ButtonOFPagination.scss';

function ButtonOFPagination({ icon, alt, classNameCss, handleClick, disabled }) {
  return (
    <button
      className={`buttonOFPagination mr-1 ${classNameCss}`}
      onClick={handleClick}
      type="button"
      disabled={disabled}
    >
      <img src={icon} alt={alt} />
    </button>
  );
}

ButtonOFPagination.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  classNameCss: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ButtonOFPagination.defaultProps = {
  disabled: false,
};

export default ButtonOFPagination;
