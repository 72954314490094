import { Types } from '../types';

export function createMacrothemesRequest({ data, authToken }) {
  return {
    type: Types.CREATE_MACROTHEME_REQUEST,
    payload: { data, authToken },
  };
}

export function createMacrothemesSuccess() {
  return {
    type: Types.CREATE_MACROTHEME_SUCCESS,
  };
}

export function createMacrothemesFailure(errorMsg) {
  return {
    type: Types.CREATE_MACROTHEME_FAILURE,
    payload: errorMsg,
  };
}

export function createMacrothemesResetState() {
  return {
    type: Types.CREATE_MACROTHEME_RESET_STATE,
  };
}
