import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './ForgetPassword.scss';

import AuthContainer from '../../components/AuthContainer';
import Alert from '../../components/Alert';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import icWarning from '../../assets/images/ic_warning.svg';

import { forgetPasswordRequest, forgetPasswordResetState } from '../../Redux/ForgetPassword/actions';
import { ForgetPasswordHasErrorErrorMsgSelector, ForgetPasswordSelectors } from '../../Redux/ForgetPassword/selector';

const ForgetPassword = ({ history }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const { success, hasError, errorMsg } = useSelector(ForgetPasswordSelectors);

  useEffect(() => {
    if(errorMsg) {
      setAlertMessage(
        <Alert type="danger" messageAlert={errorMsg} icon={icWarning} altIcon="simbolo de atenção" />
      );
    }
  }, [errorMsg]);

  // clear error messages when page unmounts
  useEffect(() => {
    return () => {
      dispatch(forgetPasswordResetState())
    } 
  }, []);

  useEffect(() => {
    if(success) nextStep();
  }, [success]);

  const nextStep = () => {
    switch (step) {
      case 2:
        break;
      default:
        setStep(step + 1);
        break;
    }
  };

  const returnForLogin = () => history.push('/login');

  const validateInputFields = ({ email }) => {
    if(email.length > 0) return true;

    setAlertMessage(
      <Alert type="danger" messageAlert={'Email inválido!'} icon={icWarning} altIcon="simbolo de atenção" />
    );
    return false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateInputFields({ email });
    
    if(isValid) {
      dispatch(forgetPasswordRequest({ email }));
    }

    return;
  };

  return (
    <>
      <AuthContainer>
        {step === 1 ? (
          <FirstStep state={email} setState={setEmail} alertMessage={alertMessage} submitEvent={handleSubmit} />
        ) : (
          <SecondStep eventButton={returnForLogin} resendEmailEvent={handleSubmit} />
        )}
      </AuthContainer>
    </>
  );
};

ForgetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ForgetPassword);
