import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Table.scss';
import { MdModeEdit, MdComment } from 'react-icons/md';

import { PaginationTable } from './PaginationTable';
import { LoadingDataTable } from './LoadingDataTable';
import { DontHaveData } from './DontHaveData';

import Button from '../Button';

import { formateDate } from '../../utils/formatDate';

import SingleArrow from '../../assets/images/ic_navigation_single_arrow_left.svg';
import icTrash from '../../assets/images/ic_trash.svg';

import useTable from '../../hooks/useTable';
import { CopyTooltip } from '../CopyTooltip';

function Table({
  titles,
  dataOfTable,
  keysOfObjectsData,
  dataKey,
  itemColapse,
  functionForPagination,
  shouldResetPagination,
  itemsPerPage = 10,
  notColapse,
  notColapseFunction,
  alternativeRowClick,
  editButton,
  startEvent,
  joinEvent,
  type = 'class',
  commentsButton,
  canDelete = true,
}) {
  // tamanhso de dataOfTable e keysOfObjectsData devem ser iguais
  const totalColumns = titles.length + 1;
  const [indexColapse, setIndexColapse] = useState();
  const [onHover, setOnHover] = useState();
  const [open, openRow, closeRow] = useTable();
  const { rows, offset, total, isLoading } = dataOfTable;

  const EventButton = item => {
    if (item.status === 'created')
      return (
        <Button classes="--primary" handleClick={() => startEvent(item)}>
          Começar Evento
        </Button>
      );
    if (item.status === 'started')
      return (
        <Button classes="--secondary" handleClick={() => joinEvent(item)}>
          Entrar no Evento
        </Button>
      );
    if (item.status === 'published')
      return (
        <div className="w-100 d-flex justify-content-center">
          <a
            className="text-justify font-weight-bold"
            style={{ color: '#00bdf7' }}
            target="_blank"
            href={item?.recordLink}
          >
            Aula Publicada
          </a>
        </div>
      );
    if (item.status === 'finished')
      return (
          <span className="text-justify font-weight-bold" href={item?.recordLink}>
            Evento Finalizado
          </span>
      );
    if (item.status === 'expired')
      return (
          <span className="badge badge-danger">
            Evento Expirado
          </span>
      );
    return (
      <Button classes="--primary" handleClick={() => startEvent(item)}>
        Começar Evento
      </Button>
    );
  };

  const hasLink = useCallback(status => {
    if (status === 'published') {
      return true;
    }
    if (status === 'started') {
      return true;
    }
    return false;
  }, []);

  const ref = useRef(null);

  if (isLoading && open) {
    closeRow();
    setIndexColapse(null);
  }

  function onRowClick(index, item, dataKey) {
    if (type === 'event') return alternativeRowClick(item);
    if (notColapse) {
      alternativeRowClick(item[dataKey]);
    } else {
      setIndexColapse(index);
      if (!open) {
        openRow();
      } else if (open && index === indexColapse) {
        closeRow();
      }
    }
  }

  const onHoverEnds = useCallback(() => {
    setOnHover(false);
  }, [setOnHover]);

  const onHoverStarts = useCallback(() => {
    setOnHover(true);
  }, [setOnHover]);

  const Tooltip = useMemo(() => <CopyTooltip isVisible={onHover} />, [onHover]);

  const moutTableHead = item => <td key={`title-${item}`}>{item}</td>;

  function mountRowsOfBodyWithData(item, index) {
    const isOpen = indexColapse === index && open;
    const handleRowClick = () => onRowClick(index, item, dataKey);

    function handleOnClickIcon() {
      if (notColapse) {
        notColapseFunction(item[dataKey]);
      } else {
        handleRowClick();
      }
    }

    function getIcon() {
      if (notColapse) {
        return <img src={icTrash} alt="trash" title="Excluir" className='btn' />;
      }
      return (
        <img
          src={SingleArrow}
          alt="arrow"
          className={isOpen ? 'btn closeCollapse' : 'btn openCollapse'}
          title="Ver mais Detalhes"
        />
      );
    }

    return (
      <React.Fragment key={`key-of-${index}`}>
        <tr key={`row-${index}`}>
          {keysOfObjectsData.map(informationkey => {
            const { key, isDate } = informationkey;
            let dataRow;
            if (isDate) {
              if (key === 'eventDate') {
                dataRow = formateDate(item[key].split('T')[0]);
              } else {
                dataRow = formateDate(item[key]);
              }
            } else {
              dataRow = item[key];
            }

            const handleOnClickRow = () => handleRowClick(index, item);
            return (
              <td
                key={`item-${index}-${key}`}
                onClick={handleOnClickRow}
                style={hasLink(item.status) ? { cursor: 'pointer' } : { cursor: 'auto' } }
                {...(hasLink(item.status) ? { onMouseOver: onHoverStarts, onMouseOut: onHoverEnds } : {})}
              >
                {dataRow}
              </td>
            );
          })}
          {type === 'event' && <td key={`item3-${index}`}>{EventButton(item)}</td>}
          {commentsButton && (
            <td key={`itemComments-${index}`}  >
              <button className='__btn' onClick={() => commentsButton(item)}><MdComment /></button>  
            </td>
          )}
          {editButton && (
            <td key={`itemEdit-${index}`} >
              <button className='__btn' onClick={() => editButton(item)}><MdModeEdit /></button>
            </td>
          )}
          {canDelete && (
            <td key={`itemDelete-${index}`} >
              <button className="__btn" onClick={handleOnClickIcon}> {getIcon()}</button>
            </td>
          )}
        </tr>

        {isOpen && !notColapse && (
          <tr key={`cola-${index}`}>
            <td colSpan={totalColumns}>{itemColapse(item[dataKey])}</td>
          </tr>
        )}
      </React.Fragment>
    );
  }

  function pagination() {
    const lenghtRows = rows.length;
    return {
      page: Math.ceil(lenghtRows > 0 ? offset / Number(itemsPerPage) + 1 : 0),
      totalPages: Math.ceil(total / Number(itemsPerPage)),
      totalInPage: Number(itemsPerPage),
      totalRegisters: total,
    };
  }
  const { page, totalPages, totalInPage, totalRegisters } = pagination();

  function renderBody() {
    let content = <DontHaveData totalColumns={totalColumns} />;
    if (rows.length > 0) {
      content = rows.map(mountRowsOfBodyWithData);
    }
    return content;
  }

  return (
    <div className="customTable card" ref={ref}>
      <table className="table">
        <thead className="customTable__head">
          <tr>
            {titles.map(moutTableHead)}
            {commentsButton && <td />}
            {editButton && <td />}
            {canDelete && <td />}
            {type === 'event' && <td />} 
          </tr>
        </thead>
        {type === 'event' && Tooltip}
        <tbody>{isLoading ? <LoadingDataTable totalColumns={totalColumns} /> : renderBody()}</tbody>
      </table>

      <PaginationTable
        currentPage={page}
        totalInPage={totalInPage}
        totalPages={totalPages}
        totalRegisters={totalRegisters}
        functionForPagination={functionForPagination}
        shouldReset={shouldResetPagination}
        isLoading={isLoading}
      />
    </div>
  );
}

Table.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  keysOfObjectsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataOfTable: PropTypes.shape({
    rows: PropTypes.array.isRequired,
    offset: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  dataKey: PropTypes.string.isRequired,
  functionForPagination: PropTypes.func.isRequired,
  shouldResetPagination: PropTypes.bool,
  itemColapse: PropTypes.func,
  notColapse: PropTypes.bool,
  startEvent: PropTypes.func,
  joinEvent: PropTypes.func,
  notColapseFunction: PropTypes.func,
  alternativeRowClick: PropTypes.func,
  editButton: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['class', 'event', 'unit', 'user']).isRequired,
  commentsButton: PropTypes.func.isRequired,
};

Table.defaultProps = {
  itemColapse: undefined,
  notColapse: false,
  shouldResetPagination: false,
  notColapseFunction: () => {},
  startEvent: () => {},
  joinEvent: () => {},
  alternativeRowClick: () => {},
};

export default Table;
