import { Types } from '../types';

export function retriveCitiesOfStateReducerRequest(initalsOfState, authToken) {
  return {
    type: Types.RETRIEVE_CITIES_OF_STATE_REQUEST,
    payload: { initalsOfState, authToken },
  };
}

export function retriveCitiesOfStateReducerSuccess({ data }) {
  return {
    type: Types.RETRIEVE_CITIES_OF_STATE_SUCCESS,
    payload: { data },
  };
}

export function retriveCitiesOfStateReducerFailure(errorMessage) {
  return {
    type: Types.RETRIEVE_CITIES_OF_STATE_FAILURE,
    payload: errorMessage,
  };
}
