import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Table } from '../../components/Table';
import Button from '../../components/Button';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { ModalToRegisterUnits } from './ModalToRegisterUnits';
import { ModalDispatch } from '../../contexts/ModalContext';
import { LoadingDispatch } from '../../contexts/LodingContext';

import { AuthRetrieveUserSelector, AuthTokenSelector } from '../../Redux/Auth/selector';
import { createUnitsResetState } from '../../Redux/Units/Create/actions';
import { defineUnitsSearchParams, retrieveUnitssRowsTotalOffsetRequest } from '../../Redux/Units/Retrieve/actions';
import { updateUnitsReset } from '../../Redux/Units/Update/actions';
import { deleteUnitsRequest, deleteUnitsReset } from '../../Redux/Units/Delete/actions';
import {
  UnitssRetrieveAllSelectors,
  UnitssCreateHasErrorSelectors,
  UnitsSuccessSelector,
  UnitssCreateLoading,
  UnitssCreateErrorMessage,
  UnitsUpdateLoading,
  UnitsUpdateSelectors,
  UnitsDeleteLoading,
  UnitsDeleteSelectors,
  UnitsSearchSelectors,
} from '../../Redux/Units/selectors';

import { showNotify } from '../../utils/showNotify';
import { ModalToDeleteUnits } from './ModalToDeleteUnits';

function Unitss() {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const user = useSelector(AuthRetrieveUserSelector);
  const createUserLoading = useSelector(UnitssCreateLoading);
  const deleteUserLoading = useSelector(UnitsDeleteLoading);
  const [valuePagination, setValuePagination] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const modalDispatch = ModalDispatch();
  const loadingDispatch = LoadingDispatch();
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [dataToEdit, setDataToEdit] = useState();
  const [searchText, setSearchText] = useState('');

  const unitsSearch = useSelector(UnitsSearchSelectors);

  const abortUnitsRetrieveAllRequestController = new AbortController();

  const Titles = ['Data', 'Modificado em', 'Nome', 'Estado', 'Cidade'];

  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'name' },
    { key: 'state' },
    { key: 'city' },
  ];

  useEffect(() => {
    if (dataToEdit)
      modalDispatch({ type: 'open', payload: <ModalToRegisterUnits useForEdit idUnit={dataToEdit.id} /> });
  }, [dataToEdit]);

  const limit = itemsPerPage;
  const search = unitsSearch;

  const dispatchDataForTable = offset =>
    dispatch(retrieveUnitssRowsTotalOffsetRequest(offset, authToken, search, limit, abortUnitsRetrieveAllRequestController.signal));

  useEffect(() => {
    setComponentLoaded(true);
    return () => {
      dispatch(defineUnitsSearchParams({ search: '' }));
      abortUnitsRetrieveAllRequestController.abort();
    }
  }, []);

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  const dataOfTable = useSelector(UnitssRetrieveAllSelectors);
  const unitsUpdateLoading = useSelector(UnitsUpdateLoading);
  createUserLoading || unitsUpdateLoading || deleteUserLoading
    ? loadingDispatch({ type: 'open' })
    : loadingDispatch({ type: '' });

  const units = useSelector(UnitsSuccessSelector);
  const createUnitError = useSelector(UnitssCreateHasErrorSelectors);
  const updateUnits = useSelector(UnitsUpdateSelectors);
  const deleteUnits = useSelector(UnitsDeleteSelectors);
  const errorMsg = useSelector(UnitssCreateErrorMessage);

  function openModalToRegisterUnits() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterUnits /> });
  }

  function handleSearch(event) {
    event.preventDefault();
    let search = searchText ? `keyword=${searchText}` : '';
    dispatch(defineUnitsSearchParams({ search }));
  }

  const getItemById = id => dataOfTable.rows.find(item => item.id === id);

  function deleteUnitsById(id) {
    dispatch(deleteUnitsRequest({ id, authToken }));
    modalDispatch({ type: '' });
  }

  async function _setDataToEdit(item) {
    if (dataToEdit?.id === item.id) {
      modalDispatch({ type: 'open', payload: <ModalToRegisterUnits useForEdit idUnit={item.id} /> });
    }
    else setDataToEdit(item);
  }

  async function openModalToDelete(id) {
    const { id: idUnit, name } = getItemById(id);
    const payload = (
      <ModalToDeleteUnits
        id={id}
        nameUnits={name}
        actionIfYes={() => deleteUnitsById(idUnit)}
      />
    );
    modalDispatch({ type: 'open', payload });
  }

  function createUnitsNotify() {
    let contentNotify;
    let typeNotify;
    if (createUnitError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao cadastrar a Unidade.';

      if (errorMsg && errorMsg === 'Network Error') {
        contentNotify += ' Erro de rede! A sua conexão está com problemas ou o servidor pode estar fora do ar!';
      } else contentNotify += ' Tente novamente mais tarde ou contate um administrador';
    } else if (units) {
      typeNotify = 'success';
      contentNotify = 'Unidade cadastrada';
      dispatchDataForTable(valuePagination);
    }

    dispatch(createUnitsResetState());
    showNotify(typeNotify, contentNotify);
  }

  function updateUnitsNotify() {
    let contentNotify;
    let typeNotify;
    if (updateUnits.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao atualizar cadastro da unidade.';

      if (updateUnits.errorMsg === 'Network Error') {
        contentNotify += ' Erro de rede! A sua conexão está com problemas ou o servidor pode estar fora do ar!';
      } else contentNotify += ' Tente novamente mais tarde ou contate um administrador';
    } else if (updateUnits.success) {
      typeNotify = 'success';
      contentNotify = 'Unidade atualizada';
      dispatchDataForTable(valuePagination);
    }

    dispatch(updateUnitsReset());
    showNotify(typeNotify, contentNotify);
  }

  function deleteUnitsNotify() {
    let contentNotify;
    let typeNotify;
    if (deleteUnits.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao excluir a Unidade.';

      if (deleteUnits.errorMsg === 'Network Error') {
        contentNotify += ' Erro de rede! A sua conexão está com problemas ou o servidor pode estar fora do ar!';
      } else if (deleteUnits.errorMsg && deleteUnits.errorMsg.includes('foreign key constraint fails')) {
        if (deleteUnits.errorMsg.includes('subjects_site_id')) {
          contentNotify += ' Existem oficinas cadastradas nessa unidade!';
        } else if (deleteUnits.errorMsg.includes('teachers_ibfk_1')) {
          contentNotify += ' Existem professores cadastrados nessa unidade!';
        } else if (deleteUnits.errorMsg.includes('students_ibfk_1')) {
          contentNotify += ' Existem estudantes cadastrados nessa unidade!';
        }
      } else contentNotify += ' Tente novamente mais tarde ou contate um administrador';
    } else if (deleteUnits.deleteSuccess) {
      typeNotify = 'success';
      contentNotify = 'Unidade excluída';
      dispatchDataForTable(valuePagination);
    }

    dispatch(deleteUnitsReset());
    showNotify(typeNotify, contentNotify);
  }

  return (
    <>
      {(units || createUnitError) && createUnitsNotify()}
      {(updateUnits.data || updateUnits.hasError) && updateUnitsNotify()}
      {(deleteUnits.deleteSuccess || deleteUnits.hasError) && deleteUnitsNotify()}

      <div className="row mb-2">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Unidades</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center mb-2">
          <SearchAndFilter
            haveFilter={false}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            inputPlaceholder="Pesquisar por nome..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        {user.role === 'admin' && (
          <div className="col-auto ml-auto mb-1">
            <Button inputFilled handleClick={openModalToRegisterUnits}>
              + Adicionar unidade
            </Button>
          </div>
        )}
      </div>

      <Table
        titles={Titles}
        dataOfTable={dataOfTable}
        keysOfObjectsData={listOfKeys}
        dataKey="id"
        notColapse
        notColapseFunction={openModalToDelete}
        editButton={item => _setDataToEdit(item)}
        functionForPagination={setValuePagination}
        itemsPerPage={itemsPerPage}
        shouldResetPagination={shouldResetPagination}
      />
    </>
  );
}

export default withRouter(Unitss);
