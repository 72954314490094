import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Modal, { DeleteModalStyles as Styles } from '../../../components/Modal';
import Button from '../../../components/Button';

import { AuthTokenSelector } from '../../../Redux/Auth/selector';
import { deleteTeacherFailure } from '../../../Redux/Teacher/Delete/actions';
import { ModalDispatch } from '../../../contexts/ModalContext';
import icAttetion from '../../../assets/images/ic_attetion.svg';
import icLoader from '../../../assets/images/ic_loading.svg';
import { BUTTON_CLASSES } from '../../../utils/enums';
import { getTeachersReferences } from '../../../client/teachers';

function ModalToDeleteTeacher({ idTeacher, nameTeacher, actionIfYes }) {
  const modalDispatch = ModalDispatch();
  const [relations, setRelations] = useState([]);
  const [countRelations, setCountRelations] = useState(0);
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getInfo = async () => {
      const {
        data: { references },
      } = await getTeachersReferences(authToken, idTeacher);

      setRelations(references);
      setCountRelations(Object.keys(references).length);
      setIsLoading(false);
    };

    getInfo()
      .then(() => {})
      .catch(err => {
        setIsLoading(false);
        err.response && err.response.data
          ? dispatch(deleteTeacherFailure(err.response.data))
          : dispatch(deleteTeacherFailure(err.message));

        modalDispatch({ type: '' });
      });
  }, []);

  useEffect(() => {
    if (relations) {
      setCountRelations(relations.classes + relations.classesComments);
    }
  }, [relations]);

  return (
    <Modal size="md" alignHorizontal="center" centralizeVertical>
      {isLoading ? (
        <img
          src={icLoader}
          alt="circulos rotacionando em 360 graus infinitamente"
          height="60px"
          className="d-flex justify-content-center w-100"
        />
      ) : (
        <div style={Styles.Container}>
          <div style={Styles.Header}>
            <img style={Styles.Image} src={icAttetion} alt="attention" />
            Cuidado!
          </div>

          {countRelations > 0 ? (
            <>
              <div style={Styles.Text}>
                {'Tem certeza que deseja excluir o(a) professor(a) '} <span style={Styles.TextBold}>{nameTeacher}</span>{' '}
                e as seguintes informações relacionadas?
              </div>

              <div style={Styles.List}>
                {relations.classes > 0 && (
                  <div style={Styles.Item}>
                    {relations.classes} {relations.classes > 1 ? 'Aulas' : 'Aula'}
                  </div>
                )}

                {relations.classesComments > 0 && (
                  <div style={Styles.Item}>
                    {relations.classesComments} {relations.classesComments > 1 ? 'Comentários' : 'Comentário'}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div>
              <div style={Styles.Text}>
                Tem certeza que deseja excluir o(a) professor(a) <span style={Styles.TextBold}>{nameTeacher}</span> ?
              </div>
            </div>
          )}

          <div className="mt-5 mb-4 d-flex justify-content-center">
            <div className="col-md-4">
              <Button
                inputFilled
                type="button"
                handleClick={() => modalDispatch({ type: '' })}
                classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
              >
                Não
              </Button>
            </div>
            <div className="col-md-4">
              <Button inputFilled type="button" handleClick={actionIfYes}>
                Sim
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

ModalToDeleteTeacher.propTypes = {
  idTeacher: PropTypes.number.isRequired,
  nameTeacher: PropTypes.string.isRequired,
  actionIfYes: PropTypes.func.isRequired,
};

export default ModalToDeleteTeacher;
