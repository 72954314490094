import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './ModalContentClass.scss';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

import { AvatarAndName } from './AvatarAndName';
import { LoadingContent } from './LoadingContent';
import { ContentClass } from './ContentClass';

import icTrashWhite from '../../../assets/images/ic_trash_white.svg';

import { AuthTokenSelector } from '../../../Redux/Auth/selector';

import { ClassContentRetrieveSelectors } from '../../../Redux/Classes/selectors';
import {
  retrieveClassContentRequest,
  retrieveClassContentReset,
} from '../../../Redux/Classes/RetrieveClassContent/actions';

function ModalContentClass({ idClass, classTitle, subject, subjectColor, teacherName }) {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);

  useEffect(() => {
    dispatch(retrieveClassContentRequest(idClass, authToken));
    return () => {
      dispatch(retrieveClassContentReset());
    };
  }, []);

  const { isLoading, data, hasError } = useSelector(ClassContentRetrieveSelectors);

  return (
    <Modal size="md" alignHorizontal="center">
      <div className="contentClass">
        <h3 className="contentClass__title">{classTitle}</h3>
        <div className="mt-3">
          <span className="contentClass__subject" style={{ backgroundColor: subjectColor }}>
            {subject}
          </span>
        </div>

        <div className="contentClass__contents mt-3">
          <AvatarAndName name={teacherName} />
          <div>
            {isLoading ? (
              <LoadingContent />
            ) : (
              data.map((item, index) => (
                <ContentClass
                  key={item.description}
                  thumbnail={item.thumbnail}
                  description={item.description}
                  url={item.url}
                  type={item.type}
                  index={index}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalContentClass.propTypes = {
  idClass: PropTypes.number.isRequired,
  classTitle: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  subjectColor: PropTypes.string.isRequired,
  teacherName: PropTypes.string.isRequired,
  deleteFunction: PropTypes.func.isRequired,
};

export default ModalContentClass;
