import { combineReducers } from 'redux';

import { createTeacherReducer } from './Create/reducer';
import { retrieveTeachersRowsTotalOffsetReducer } from './Retrieve/reducer';
import { updateTeacherReducer } from './Update/reducer';
import { deleteTeacherReducer } from './Delete/reducer';

const reducer = combineReducers({
  createTeacherReducer,
  retrieveTeachersRowsTotalOffsetReducer,
  updateTeacherReducer,
  deleteTeacherReducer,
});

export { reducer as crudTeachersReducers };
