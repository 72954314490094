export const Styles = {
  Container: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 80,
    paddingLeft: 80,
  },
  Header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 15,
  },
  Image: {
    width: 55,
    height: 55,
    marginBottom: 10,
  },
  Text: {
    fontSize: 14,
  },
  TextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  List: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  Item: {
    fontWeight: 600,
    marginTop: 10,
    fontSize: 14,
  },
};
