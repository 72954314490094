import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const route = '/teachers';

function getTeachers(offset = 0, authToken, search, limit = 10, signal) {
  const finalRoute = search ? `${route}?${search}` : route;
  return Api.get(finalRoute, {
    signal,
    params: {
      limit,
      offset,
    },
    headers: commonHeaders(authToken),
  });
}

function createTeachers(data, authToken) {
  return Api.post(`${route}`, data, {
    headers: commonHeaders(authToken),
  });
}

function updateTeacher(id, data, authToken) {
  return Api.patch(`${route}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function getTeachersReferences(authToken, id) {
  const finalRoute = `${route}/${id}/references`;
  return Api.get(finalRoute, {
    headers: commonHeaders(authToken),
  });
}

function deleteTeacher(id, authToken) {
  return Api.delete(`${route}/${id}`, {
    headers: commonHeaders(authToken),
  });
}

export { getTeachers, createTeachers, updateTeacher, getTeachersReferences, deleteTeacher };
