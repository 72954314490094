import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

const classTypeRoute = '/classTypes';

function getMacrothemess(offset = 0, authToken, search, limit = 10, signal) {
  const finalRoute = search ? `${classTypeRoute}?${search}` : classTypeRoute;
  return Api.get(finalRoute, {
    params: {
      limit,
      offset: offset,
    },
    headers: commonHeaders(authToken),
    signal,
  });
}

function getMacrothemessReferences (authToken, id) {
  const finalRoute = `${classTypeRoute}/${id}/references`
  return Api.get(finalRoute, {
    headers: commonHeaders(authToken),
  });
}

async function getClassTypeSubjects(classTypeId, authToken) {
  return Api.get(`/classTypes/${classTypeId}/subjects`, {
    headers: commonHeaders(authToken),
    params: {
      onlyWithClassesQtyGreaterThanZero: false
    }
  });
}

function createMacrothemess(data, authToken) {
  return Api.post(
    classTypeRoute,
    { ...data, color: '#191919' },
    {
      headers: commonHeaders(authToken),
    }
  );
}

function updateMacrothemes(id, data, authToken) {
  return Api.patch(`${classTypeRoute}/${id}`, data, {
    headers: commonHeaders(authToken),
  });
}

function deleteMacrothemes(id, authToken) {
  return Api.delete(`${classTypeRoute}/${id}`, {
    headers: commonHeaders(authToken),
  });
}

export { getMacrothemess, getMacrothemessReferences, getClassTypeSubjects, createMacrothemess, updateMacrothemes, deleteMacrothemes };