import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Table } from '../../components/Table';
import Button from '../../components/Button';
import { SelectItemsPerPage } from '../../components/SelectItemsPerPage';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { ModalToRegisterAdmin } from './ModalToRegisterAdmin';
import { ModalDispatch } from '../../contexts/ModalContext';
import { LoadingDispatch } from '../../contexts/LodingContext';

import { AuthTokenSelector } from '../../Redux/Auth/selector';
import { createAdminResetState } from '../../Redux/Admin/Create/actions';
import { defineAdminSearchParams, retrieveAdminsRowsTotalOffsetRequest } from '../../Redux/Admin/Retrieve/actions';
import { updateAdminReset } from '../../Redux/Admin/Update/actions';
import {
  AdminsRetrieveAllSelectors,
  AdminsCreateHasErrorSelectors,
  AdminCreateSelectors,
  AdminsCreateGetUserSelectors,
  AdminsCreateLoading,
  AdminsCreateErrorMessage,
  AdminUpdateLoading,
  AdminUpdateSelectors,
  AdminSearchSelectors,
} from '../../Redux/Admin/selectors';

import { showNotify } from '../../utils/showNotify';

function Admins() {
  const dispatch = useDispatch();
  const authToken = useSelector(AuthTokenSelector);
  const createUserLoading = useSelector(AdminsCreateLoading);
  const [valuePagination, setValuePagination] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const modalDispatch = ModalDispatch();
  const loadingDispatch = LoadingDispatch();
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const adminSearch = useSelector(AdminSearchSelectors);
  const [dataToEdit, setDataToEdit] = useState();
  const [searchText, setSearchText] = useState('');

  const abortAdminsRetrieveAllRequestController = new AbortController();

  const Titles = ['Data', 'Modificado em', 'Nome', 'Email'];

  const listOfKeys = [
    { key: 'createdAt', isDate: true },
    { key: 'modifiedAt', isDate: true },
    { key: 'name' },
    { key: 'username' },
  ];

  const limit = itemsPerPage;
  const search = adminSearch;

  const dispatchDataForTable = offset => {
    dispatch(retrieveAdminsRowsTotalOffsetRequest(offset, authToken, search, limit, abortAdminsRetrieveAllRequestController.signal));
  };

  useEffect(() => {
    if (dataToEdit)
      modalDispatch({ type: 'open', payload: <ModalToRegisterAdmin useForEdit idAdmin={dataToEdit.userId} /> });
  }, [dataToEdit]);

  useEffect(() => {
    setComponentLoaded(true);
    return () => {
      dispatch(defineAdminSearchParams({ search: '' }));
      abortAdminsRetrieveAllRequestController.abort();
    };
  }, []);

  useEffect(() => {
    setShouldResetPagination(false);
    dispatchDataForTable(valuePagination);
  }, [valuePagination]);

  useEffect(() => {
    if (valuePagination === 0 && componentLoaded) {
      dispatchDataForTable(valuePagination);
    }
    setValuePagination(0);
    setShouldResetPagination(true);
  }, [itemsPerPage, search]);

  const dataOfTable = useSelector(AdminsRetrieveAllSelectors);
  const adminUpdateLoading = useSelector(AdminUpdateLoading);

  createUserLoading || adminUpdateLoading ? loadingDispatch({ type: 'open' }) : loadingDispatch({ type: '' });

  const createAdmin = useSelector(AdminCreateSelectors);
  const createAdminError = useSelector(AdminsCreateHasErrorSelectors);
  const updateAdmin = useSelector(AdminUpdateSelectors);
  const errorMsg = useSelector(AdminsCreateErrorMessage);

  function openModalToRegisterAdmin() {
    modalDispatch({ type: 'open', payload: <ModalToRegisterAdmin /> });
  }

  function handleSearch(event) {
    event.preventDefault();
    let search = searchText ? `query=${searchText}` : '';
    dispatch(defineAdminSearchParams({ search }));
  }

  async function _setDataToEdit(item) {
    console.log('dataToEdit', item)
    if (dataToEdit?.userId === item.userId) {
      console.log("item.userid", item.userId)
      modalDispatch({ type: 'open', payload: <ModalToRegisterAdmin useForEdit idAdmin={item.userId} /> });
    }
    else setDataToEdit(item);
  }

  function createAdminNotify() {
    let contentNotify;
    let typeNotify;
    if (createAdminError) {
      typeNotify = 'error';
      contentNotify = `Ocorreu um erro ao cadastrar o admin. ${errorMsg}. Tente novamente :)`;
    } else {
      typeNotify = 'success';
      contentNotify = `Administrador(a) cadastrado(a) | Senha: 12345678`;
      dispatchDataForTable(valuePagination);
    }
    dispatch(createAdminResetState());
    showNotify(typeNotify, contentNotify);
  }

  function updateAdminNotify() {
    let contentNotify;
    let typeNotify;
    if (updateAdmin.hasError) {
      typeNotify = 'error';
      contentNotify = 'Ocorreu um erro ao atualizar cadastro do admin. Tente novamente :)';
    } else {
      typeNotify = 'success';
      contentNotify = `Administrador(a) atualizado(a)`;
      dispatchDataForTable(valuePagination);
    }
    dispatch(updateAdminReset());
    showNotify(typeNotify, contentNotify);
  }

  return (
    <>
      {(createAdmin.success || createAdmin.hasError) && createAdminNotify()}
      {(updateAdmin.success || updateAdmin.hasError) && updateAdminNotify()}

      <div className="row mb-2">
        <div className="col-xl my-auto mr-auto">
          <h3 className="titlePages">Admins</h3>
        </div>

        <div className="col-md-auto d-flex flex-row flex-wrap align-center mb-2">
          <SearchAndFilter
            haveFilter={false}
            selectedSearchValue={searchText}
            setSearch={setSearchText}
            inputPlaceholder="Pesquisar por nome..."
            onSubmit={handleSearch}
          />

          <SelectItemsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>

        <div className="col-auto ml-auto mb-1">
          <Button inputFilled handleClick={openModalToRegisterAdmin}>
            + Adicionar administrador
          </Button>
        </div>
      </div>

      <Table
        titles={Titles}
        dataOfTable={dataOfTable}
        keysOfObjectsData={listOfKeys}
        dataKey="userId"
        notColapse
        notColapseFunction={() => {}}
        editButton={item => _setDataToEdit(item)}
        functionForPagination={setValuePagination}
        itemsPerPage={itemsPerPage}
        shouldResetPagination={shouldResetPagination}
        canDelete={false}
      />
    </>
  );
}

export default withRouter(Admins);
