import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './FormLogin.scss';

import icPerson from '../../../assets/images/ic_person.svg';
import icLocked from '../../../assets/images/ic_locked.svg';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { AuthCredentialsSelector } from '../../../Redux/Auth/selector';

function FormLogin({ onClick, hasError, errorMsg, errorType }) {
  const { username: usernameFromRedux, password: passwordFromRedux } = useSelector(AuthCredentialsSelector);

  const [username, setUsername] = useState(usernameFromRedux);
  const [password, setPassword] = useState(passwordFromRedux);
  const [rememberLogin, setRememberLogin] = useState(usernameFromRedux && passwordFromRedux);

  const [inputFilled, setInputFilled] = useState('button--Await');

  const validateInputFields = () => {
    if (username && password) setInputFilled(true);
    else setInputFilled(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    onClick(username, password, rememberLogin);
  }

  useEffect(() => validateInputFields());

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <Input
          id="login"
          icon={icPerson}
          alt="ícone de pessoa, mostrando cabeça e ombros."
          title="ícone de pessoa."
          placeholder="E-mail"
          setInputValue={setUsername}
          inputValue={username}
          typeInput="text"
          dataTest="inputEmail"
        />
        {hasError && errorType === 'email' && <div className="small text-danger text-center mb-2">{errorMsg}</div>}

        <div className="mt-1">
          <Input
            icon={icLocked}
            alt="ícone de um cadeado."
            title="ícone de cadeado."
            placeholder="Senha"
            setInputValue={setPassword}
            inputValue={password}
            typeInput="password"
            dataTest="inputSenha"
          />
        </div>

        {hasError && errorType === 'password' && <div className="small text-danger text-center">{errorMsg}</div>}

        <div className="mt-3 rememberLogin">
          <input
            type="checkbox"
            id="rememberLogin"
            name="rememberLogin"
            defaultChecked={rememberLogin}
            onClick={() => setRememberLogin(!rememberLogin)}
          />
          <label className="ml-1" htmlFor="rememberLogin">
            Lembrar senha?
          </label>
        </div>
      </div>

      <div className="col-12 mt-3 mb-3">
        <Button dataTest="buttonLoggin" inputFilled={inputFilled} type="submit">
          Entrar
        </Button>
      </div>

      <div className="col-12 text-center">
        <Link to="/forgetpassword" className="forgotPasswordText" dataTest="forgotPassword">
          Esqueceu a senha?
        </Link>
      </div>
    </form>
  );
}

FormLogin.propTypes = {
  onClick: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  errorType: PropTypes.string,
  hasError: PropTypes.bool,
};

FormLogin.defaultProps = {
  errorMsg: '',
  errorType: '',
  hasError: false,
};

export default FormLogin;
