import React from 'react';
import PropTypes from 'prop-types';
import logoGE from '../../assets/images/logo_ge.svg';

const Logo = props => {
  const { alt, title, height } = props;

  return <img src={logoGE} alt={alt} title={title} height={height} />;
};

Logo.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.number,
};

Logo.defaultProps = {
  height: null,
};

export default Logo;
