import { call, put } from 'redux-saga/effects';

import { getStudents, updateStudent, deleteStudent, resetStudentPasswordByMentor, } from '../../client/students';

import {
  retrieveStudentsRowsTotalOffsetSuccess,
  retrieveStudentsRowsTotalOffsetFailure,
} from '../../Redux/Students/Retrieve/actions';

import { deleteStudentSuccess, deleteStudentFailure } from '../../Redux/Students/Delete/actions';
import { updateStudentSucess, updateStudentFailure } from '../../Redux/Students/Update/actions';
import { resetPasswordFailure, resetPasswordSucess } from '../../Redux/Students/ResetPassword/actions';

export function* retrieveStudentsRowsTotalOffsetSaga(action) {
  try {
    const { offset: offParam, authToken, search, limit, signal } = action.payload;
    const response = yield call(getStudents, offParam, authToken, search, limit, signal);
    yield put(retrieveStudentsRowsTotalOffsetSuccess(response.data));
  } catch (err) {
    yield put(retrieveStudentsRowsTotalOffsetFailure(err.message));
  }
}

export function* updateStudentSaga(action) {
  try {
    const { id, data, authToken } = action.payload;
    const response = yield call(updateStudent, id, data, authToken);
    yield put(updateStudentSucess(response));
  } catch (error) {
    yield put(updateStudentFailure(error.message));
  }
}

export function* deleteStudentByidSaga(action) {
  try {
    const { id, authToken } = action.payload;
    yield call(deleteStudent, id, authToken);
    yield put(deleteStudentSuccess());
  } catch (err) {
    yield put(deleteStudentFailure(err.message));
  }
}

export function* resetPasswordSaga({ payload }) {

  try {
    const { authToken, id, password, mentorPassword } = payload;
    yield call(resetStudentPasswordByMentor, { id, password, mentorPassword, authToken });
    yield put(resetPasswordSucess());
  } catch (error) {
    error.response && error.response.data ?
      yield put(resetPasswordFailure(error.response.data)) :
      yield put(resetPasswordFailure(error.message));
  }
}
