import { Api } from '../services/api';
import { commonHeaders } from '../utils/commonHeaders';

export function changePasswordRequest({ username, currentPassword, newPassword, authToken }) {
  return Api.post(
    `/user/changepassword`,
    { username, currentPassword, newPassword },
    {
      headers: commonHeaders(authToken),
    }
  );
}
