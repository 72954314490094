import { Types } from '../types';

export function defineClassesSearchParams({ search }) {
  return {
    type: Types.DEFINE_CLASSES_SEARCH_PARAMS,
    payload: { search },
  };
}

export function retrieveClassesRowsTotalOffsetRequest(offset = 0, authToken, search, limit, signal) {
  return {
    type: Types.RETRIEVE_ALL_CLASSES_REQUEST,
    payload: { offset, authToken, search, limit, signal },
  };
}

export function retrieveClassesRowsTotalOffsetSuccess({ rows, total, offset }) {
  return {
    type: Types.RETRIEVE_ALL_CLASSES_SUCCESS,
    payload: { rows, total, offset },
  };
}

export function retrieveClassesRowsTotalOffsetFailure(errorMessage) {
  return {
    type: Types.RETRIEVE_ALL_CLASSES_FAILURE,
    payload: errorMessage,
  };
}
