import { Types } from '../types';

const initialState = {
  data: null,
  isLoading: false,
  hasError: false,
  errorMsg: '',
};

export function updateStudentReducer(state = initialState, action) {
  const { data } = action.payload || state;
  switch (action.type) {
    case Types.UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Types.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data,
      };
    case Types.UPDATE_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg: action.payload,
      };
    case Types.UPDATE_STUDENT_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
