import React, { useState } from 'react';
import PropTypes from 'prop-types';

import icAttetion from '../../../assets/images/ic_attetion.svg';
import { DeleteModalStyles as Styles } from '../../../components/Modal';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

import { ModalDispatch } from '../../../contexts/ModalContext';
import { BUTTON_CLASSES } from '../../../utils/enums';

function ModalToDeleteEvent({ eventName, requirePassword, actionIfYes }) {
  const modalDispatch = ModalDispatch();
  const [moderatorPassword, setModeratorPassword] = useState();

  return (
    <Modal size="md" alignHorizontal="center" centralizeVertical>
      <div style={Styles.Container}>
        <div style={Styles.Header}>
          <img style={Styles.Image} src={icAttetion} alt="attention" />
          Cuidado!
        </div>
        <div>
          <div style={Styles.Text}>
            {'Tem certeza que deseja excluir o evento '} <span style={Styles.TextBold}>{eventName}</span> {'?'}
          </div>
          {requirePassword &&
          <div className="mt-2">
            <label htmlFor="name">Digite a senha de moderador:</label>
            <Input
              id="moderatorPassword"
              inputValue={moderatorPassword}
              typeInput="password"
              setInputValue={setModeratorPassword}
              required
            />
          </div>
}
        </div>

        <div className="mt-5 mb-4 d-flex justify-content-center">
          <div className="col-md-4">
            <Button
              inputFilled
              type="button"
              handleClick={() => modalDispatch({ type: '' })}
              classes={BUTTON_CLASSES.NO_AWAIT_WHITE}
            >
              Não
            </Button>
          </div>
          <div className="col-md-4">
            <Button 
              inputFilled 
              type="button" 
              disabled={requirePassword && !moderatorPassword} 
              classes={(requirePassword && moderatorPassword) || !requirePassword ? BUTTON_CLASSES.NO_AWAIT : BUTTON_CLASSES.AWAIT}
              handleClick={() => actionIfYes(moderatorPassword)}
            >
              Sim
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalToDeleteEvent.propTypes = {
  eventName: PropTypes.string.isRequired,
  actionIfYes: PropTypes.string.isRequired,
};

export default ModalToDeleteEvent;
